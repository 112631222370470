import React from 'react';

import PropTypes from 'prop-types';

import Comment from './comment';
import CommentsAdd from './comments-add';

const Comments = ({ comments, onSubmit, onDelete, disabled, canAdd, canDelete }) => {
    return (
        <div className="flex flex-col justify-center gap-2">
            {comments.map((comment) => (
                <Comment
                    key={comment.id}
                    comment={comment}
                    disabled={disabled}
                    canDelete={canDelete}
                    onDelete={onDelete.bind(null, comment.id)}
                />
            ))}
            <CommentsAdd onSubmit={onSubmit} disabled={disabled} canRender={canAdd} />
        </div>
    );
};

Comments.propTypes = {
    comments: PropTypes.array,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
    canAdd: PropTypes.bool,
    canDelete: PropTypes.bool,
};

Comments.defaultProps = {
    comments: [],
    onSubmit: () => {},
    onDelete: () => {},
    disabled: false,
    canAdd: true,
    canDelete: true,
};

export default Comments;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CircularProgress } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectManagement } from 'routes';
import API from 'utils/axios';

export default function AddUser() {
    const { t } = useTranslation();
    const [allUsers, setAllUsers] = useState([]);
    const [allOptions, setAllOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loadingUsers, setLoadingUsers] = useState(true);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            let users = [];
            let completedUsers = [];

            completedUsers = (
                await API.get('/PmUsers', {
                    params: {
                        currentPage: 0,
                        pagesToLoad: 1,
                        perPage: 99999,
                    },
                })
            ).data.pmUsers.content;

            users = (
                await API.get('/tenants', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                    },
                })
            ).data.users;

            users = users.filter((u) => !u.isAffiliate && !completedUsers.map((U) => U.id).includes(u.id));

            setAllUsers(users);
            setAllOptions(users.map((user) => `${user.profile?.name ?? t('User has no profile')}`));
            setLoadingUsers(false);
        })();
    }, []);

    function addUsers() {
        if (!selected.length) {
            enqueueSnackbar(t('You must select at least one user!'), {
                variant: 'error',
            });
            return;
        }

        API.post(`/PmUsers`, {
            ids: selected.map((i) => ({ id: allUsers[i].id })),
        }).then(() => {
            enqueueSnackbar(t('User/Users has been added successfully!'), { variant: 'success' });
            const rootUrl = projectManagement.base + projectManagement.projectManagementConfigurator.base;
            history.push(`${rootUrl}?tab=0`);
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Add users on PM')}
                </title>
            </Helmet>

            <Header pageTitle={t('Add users on Project Management Module')} />

            <div className="page-container">
                {!loadingUsers ? (
                    <React.Fragment>
                        <div className="mb-10 w-full max-w-md">
                            <LabelWrapper label={t('Choose the users you would like to add to Project Management')}>
                                <MultiDropdown
                                    options={allOptions}
                                    selectedOptions={selected}
                                    setSelectedOptions={(i) => {
                                        if (selected.indexOf(i) > -1) {
                                            setSelected(selected.filter((opt) => opt !== i));
                                        } else {
                                            setSelected([...selected, i]);
                                        }
                                    }}
                                />
                            </LabelWrapper>
                        </div>
                        <Button color="primary" onClick={addUsers}>
                            {t('Add users in Project Management')}
                        </Button>
                    </React.Fragment>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </React.Fragment>
    );
}

AddUser.propTypes = {
    affiliate: PropTypes.boolean,
    pmId: PropTypes.string,
};

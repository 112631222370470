import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProjectsPipeline from 'pages/all-pm-module/projects-pipeline';
import { affiliate } from 'routes';

import AffiliateProjectRoutes from './affiliate-project-routes';
import AffiliateProjects from './affiliate-projects';
import AffiliateProjectsOverview from './affiliate-projects-overview';

const AffiliateProjectManager = () => {
    return (
        <Switch>
            {/* PM */}
            <Route path={affiliate.base + affiliate.projectManagement.projects}>
                <AffiliateProjects />
            </Route>
            <Route path={affiliate.base + affiliate.projectManagement.overview}>
                <AffiliateProjectsOverview />
            </Route>
            <Route path={affiliate.base + affiliate.projectManagement.pipeline}>
                <ProjectsPipeline />
            </Route>

            {/* PM PROJECT */}
            <Route path={affiliate.base + affiliate.projectManagement.projectFull}>
                <AffiliateProjectRoutes />
            </Route>
        </Switch>
    );
};

export default AffiliateProjectManager;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import Loading from 'components/shared/loading';
import SidebarLayout from 'components/shared/sidebar-layout/sidebar-layout';
// Contexts
import GlobalContext from 'contexts/GlobalContext';
import PermissionContext from 'contexts/PermissionContext';
import UserContext from 'contexts/UserContext';
import { ConfirmProvider } from 'hooks/useConfirm';
import { NavPropsProvider } from 'hooks/useNavProps';
import _ from 'lodash';
import { SnackbarProvider } from 'notistack';
import Accountancy from 'pages/accountancy/accountancy';
import AffiliateProjectManager from 'pages/affiliate-pages/affiliate-routes';
import ProjectManagement from 'pages/all-pm-module';
import BusinessIntelligence from 'pages/business-intelligence/business-intelligence';
import Login from 'pages/common/login';
import NotFound from 'pages/common/not-found';
import RecoverPassword from 'pages/common/recover-password';
import VerifyPassword from 'pages/common/verify-password';
import ConfiguratorRouter from 'pages/configurator/configurator';
import CRM from 'pages/crm/crm';
import Home from 'pages/home/home';
import Versions from 'pages/home/versions';
import InitializeCompany from 'pages/initialize-company/initialize-company';
import InternalActivity from 'pages/internal-activity/internal-activity';
import Modules from 'pages/modules/modules';
import Puppeteer from 'pages/puppeteer/puppeteer';
import Quotes from 'pages/quotes/quotes';
import ResourcesModuleRouting from 'pages/resources-module/resources-module-routing';
import Projects from 'pages/single-pm-module';
import { MemoizedAppWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import {
    accountancy,
    affiliate,
    businessIntelligence,
    configurator,
    crm,
    initializeCompany,
    internalActivity,
    modules,
    notFound,
    projectInManagement,
    projectManagement,
    puppeteer,
    quotes,
    recoverPassword,
    resourceModule,
    verifyPassword,
    versions,
} from 'routes';
import { checkDomain, defaultTheme, whoAmI } from 'utils';
import API from 'utils/axios';
import { getPermissions } from 'utils/getterFunctions';

const App = () => {
    const history = useHistory();
    const { i18n } = useTranslation();

    const isLoginOut = useRef(false);
    const [domainOk, setDomainOk] = useState(false);
    const [initializationCompleted, setInitializationCompleted] = useState(false);
    const [user, setUser] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [globalModalOpen, setGlobalModalOpen] = useState(false);
    const [globalModalChildren, setGlobalModalChildren] = useState(null);
    const [globalLoading, setGlobalLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [permissionMap, setPermissionMap] = useState({});
    const [currencyTypes, setCurrencyTypes] = useState([]);
    const [currencyObj, setCurrencyObj] = useState(null);
    const [referenceCurrencyObj, setReferenceCurrencyObj] = useState(null);

    /**
     * Theme logic
     */

    const [theme, setTheme] = useState(JSON.parse(defaultTheme.theme));
    const [themes, setThemes] = useState([]);
    const memoizedTheme = useMemo(() => theme, [theme]);

    const handleUpdateTheme = (newTheme) => {
        const root = document.documentElement;
        const reconciledTheme = _.merge({}, JSON.parse(defaultTheme.theme), newTheme);

        for (const themeSection of Object.values(reconciledTheme)) {
            for (const themeColor of Object.values(themeSection)) {
                root.style.setProperty(themeColor.varName, themeColor.color);
            }
        }

        setTheme(reconciledTheme);
    };

    /**
     * Function used to logout the current user
     */
    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('accessToken');
        API.defaults.headers.common.Authorization = null;
        history.push('/login');
        isLoginOut.current = true;
    };

    /**
     * Function that checks if user has a permission type ('none', 'view', 'all') on a specific permissions
     * checkPerm([{ permissionId: 1, permissionType: 'VIEW' }]) --> true/false
     */
    const checkPerm = useCallback(
        (neededPermissions = []) => {
            if (!user || user === -1) return false;
            if (user.isAdmin) return true;

            let can = true;
            const PL = ['VIEW', 'ALL'];
            const IOF = (e) => PL.indexOf(e);

            neededPermissions.every((neededPermission) => {
                const permissionIndex = user.permissions.findIndex(
                    (userPermission) =>
                        String(userPermission.permissionId) === String(neededPermission.permissionId) &&
                        IOF(neededPermission.permissionType) <= IOF(userPermission.permissionType),
                );

                if (permissionIndex < 0) can = false;

                return can;
            });

            return can;
        },
        [user],
    );

    /**
     * Handle check domain, user and get permissions, currencies types and the tenant theme
     */
    useEffect(() => {
        (async () => {
            try {
                if (isLoginOut.current) return;

                setGlobalLoading(true);

                const isDomainOk = await checkDomain();
                setDomainOk(isDomainOk);

                if (!isDomainOk) {
                    history.push(notFound);
                    return;
                }

                const token = localStorage.getItem('accessToken');
                if (!token) {
                    handleLogout();
                    return;
                }

                API.defaults.headers.common.Authorization = token;

                let userData = user;
                if (!user) userData = await whoAmI();
                if (!userData) {
                    handleLogout();
                    return;
                }
                setUser(userData);

                const permissions = await getPermissions();
                const permissionMap = {};
                permissions.forEach((permissionGroup) => {
                    permissionGroup.Permissions.forEach((permission) => {
                        permissionMap[permission.name] = 0;

                        if (userData.isAdmin) {
                            permissionMap[permission.name] = 2;
                        } else {
                            const permissionIndex = userData.permissions.findIndex(
                                (up) => up.permissionId == permission.id,
                            );
                            if (permissionIndex > -1) {
                                permissionMap[permission.name] =
                                    userData.permissions[permissionIndex].permissionType === 'ALL' ? 2 : 1;
                            }
                        }
                    });
                });

                setPermissionMap(permissionMap);
                setPermissions(permissions);

                const rowTenant = await API.get('/tenant');
                const tenant = rowTenant.data.data;

                setThemes(tenant.ThemeConfigurator);

                if (tenant.ThemeConfigurator.length > 0)
                    handleUpdateTheme(
                        JSON.parse(tenant.ThemeConfigurator.find((theme) => theme.id === tenant.activeThemeId).theme),
                    );

                const rowCT = await API.get('/currencyType');
                const CT = rowCT.data.currencies;

                setCurrencyTypes(CT);
                setInitializationCompleted(tenant.initializationStep === 'COMPLETED');
                setTenant(tenant);

                if (tenant.CustomerCurrency.length > 0) {
                    const currencyId = tenant.CustomerCurrency.at(-1).currencyId;
                    const currencyIndex = CT.findIndex((c) => c.id === currencyId);

                    const secondCurrencyId = tenant.CustomerCurrency.at(-1).secondCurrencyId;
                    const secondCurrencyIndex = CT.findIndex((c) => c.id === secondCurrencyId);

                    setCurrencyObj(CT[currencyIndex]);
                    setReferenceCurrencyObj(CT[secondCurrencyIndex]);
                } else {
                    setCurrencyObj(CT[0]);
                    setReferenceCurrencyObj(CT[0]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                if (isLoginOut.current) isLoginOut.current = false;
                setGlobalLoading(false);
            }
        })();
    }, [user]);

    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {globalLoading ? (
                <Loading style={{ height: '100svh' }} />
            ) : (
                <MemoizedAppWrapper tenantTheme={memoizedTheme}>
                    <UserContext.Provider
                        value={{
                            user,
                            setUser,
                            checkPerm,
                            permissionMap,
                        }}
                    >
                        {user !== null ? (
                            <GlobalContext.Provider
                                value={{
                                    globalModalOpen,
                                    setGlobalModalOpen,
                                    globalModalChildren,
                                    setGlobalModalChildren,
                                    currencyTypes,
                                    currencyObj, // currency used by the company
                                    setCurrencyObj, // needed only when updating company details
                                    referenceCurrencyObj, // reference currency used by the company
                                    setReferenceCurrencyObj, // needed only when updating company details
                                    language: i18n.resolvedLanguage,
                                    theme,
                                    themes,
                                    onUpdateTheme: handleUpdateTheme,
                                    tenant,
                                    setTenant,
                                }}
                            >
                                <ConfirmProvider>
                                    <PermissionContext.Provider value={permissions}>
                                        {/* Modal Suport Tehnic */}
                                        <div
                                            className="fixed left-0 top-0 flex h-svh w-full items-center justify-center overflow-hidden transition-all duration-300"
                                            style={{
                                                pointerEvents: globalModalOpen ? 'all' : 'none',
                                                opacity: globalModalOpen ? '1' : '0',
                                                background: `rgb(${memoizedTheme['LAYOUT-COLORS']['MAIN-LAYOUT'].color} / 80%)`,
                                                backdropFilter: 'blur(5px)',
                                                zIndex: 9999,
                                            }}
                                        >
                                            {globalModalChildren}
                                        </div>

                                        {permissions.length > 0 && (
                                            <NavPropsProvider>
                                                <SidebarLayout
                                                    disabledSidebar={!initializationCompleted}
                                                    handleLogout={handleLogout}
                                                >
                                                    {initializationCompleted ? (
                                                        <Switch>
                                                            <Route key="home" exact path="/">
                                                                <Home />
                                                            </Route>
                                                            <Route
                                                                key={internalActivity.base}
                                                                path={internalActivity.base}
                                                            >
                                                                <InternalActivity />
                                                            </Route>
                                                            <Route key={crm.base} path={crm.base}>
                                                                <CRM />
                                                            </Route>
                                                            <Route key={quotes.base} path={quotes.base}>
                                                                <Quotes />
                                                            </Route>
                                                            <Route key={modules.base} path={modules.base}>
                                                                <Modules />
                                                            </Route>
                                                            <Route
                                                                key={businessIntelligence.base}
                                                                path={businessIntelligence.base}
                                                            >
                                                                <BusinessIntelligence />
                                                            </Route>
                                                            <Route key={configurator.base} path={configurator.base}>
                                                                <ConfiguratorRouter />
                                                            </Route>
                                                            <Route key={resourceModule.base} path={resourceModule.base}>
                                                                <ResourcesModuleRouting />
                                                            </Route>
                                                            {user && !user.isAffiliate && (
                                                                <Route
                                                                    key={projectManagement.base}
                                                                    path={projectManagement.base}
                                                                >
                                                                    <ProjectManagement />
                                                                </Route>
                                                            )}
                                                            {user && user.isAffiliate && (
                                                                <Route key={affiliate.base} path={affiliate.base}>
                                                                    <AffiliateProjectManager />
                                                                </Route>
                                                            )}
                                                            <Route
                                                                key={projectInManagement.base + '/:projectId'}
                                                                path={projectInManagement.base + '/:projectId'}
                                                            >
                                                                <Projects />
                                                            </Route>

                                                            <Route key={accountancy.base} path={accountancy.base}>
                                                                <Accountancy />
                                                            </Route>
                                                            <Route key={versions.base} path={versions.base}>
                                                                <Versions />
                                                            </Route>

                                                            <Route key={puppeteer.base} path={puppeteer.base}>
                                                                <Puppeteer />
                                                            </Route>

                                                            <Route path="*" component={NotFound} />
                                                        </Switch>
                                                    ) : (
                                                        <Switch>
                                                            <Route key="home" exact path="/">
                                                                <Redirect to={initializeCompany} />
                                                            </Route>

                                                            <Route key={initializeCompany} path={initializeCompany}>
                                                                <InitializeCompany
                                                                    onLogout={handleLogout}
                                                                    onSuccess={() => {
                                                                        setInitializationCompleted(true);
                                                                        history.replace('/');
                                                                    }}
                                                                />
                                                            </Route>

                                                            <Route path="*">
                                                                <Redirect to={initializeCompany} />
                                                            </Route>
                                                        </Switch>
                                                    )}
                                                </SidebarLayout>
                                            </NavPropsProvider>
                                        )}
                                    </PermissionContext.Provider>
                                </ConfirmProvider>
                            </GlobalContext.Provider>
                        ) : domainOk ? (
                            <Switch>
                                <Route exact path={recoverPassword}>
                                    <RecoverPassword />
                                </Route>

                                <Route exact path={verifyPassword}>
                                    <VerifyPassword />
                                </Route>

                                <Route exact path="/login">
                                    <Login />
                                </Route>

                                <Route path="*" component={NotFound} />
                            </Switch>
                        ) : (
                            <NotFound />
                        )}
                    </UserContext.Provider>
                </MemoizedAppWrapper>
            )}
        </SnackbarProvider>
    );
};

export default App;

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, Checkbox, CircularProgress, FormControlLabel, makeStyles, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import FileUploadContainer from 'components/shared/file-upload-container';
import ValueDisplay from 'components/shared/value-display';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { accountancy } from 'routes';
import API from 'utils/axios';
import { getContacts, getPartners, getCompanyVAT as handleGetCompanyVAT } from 'utils/getterFunctions';
import {
    calculateSumWithVAT,
    errorHandling,
    formatExchangeRate,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
    toRoundedSignificantDigits,
    uploadSingleFile,
} from 'utils/index';
import * as yup from 'yup';

import InvoiceExpenseBudget from './invoice-expense-budget';
import InvoiceRevenueBudget from './invoice-revenue-budget';

const useStyles = makeStyles(() => {
    return {
        expense: {
            backgroundColor: `#05668D`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#05668D`,
            },
        },
        income: {
            backgroundColor: `#00A896`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#00A896`,
            },
        },
        advance: {
            backgroundColor: `#9B5094`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#9B5094`,
            },
        },
        reverse: {
            backgroundColor: `#F5A65B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#F5A65B`,
            },
        },
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const InvoiceClientDropdown = (props) => {
    const { t } = useTranslation();
    const {
        clients,
        clientOptions,
        invoiceClient,
        setInvoiceClient,
        resetMiddleFields,
        getNewCrmData,
        getNewProjects,
        disabled = false,
        selectedPartnerId,
    } = props;

    return (
        <>
            {/* Dropdown for the invoice client */}
            <div className="flex justify-start">
                <div className="inline-block">
                    <LabelWrapper label={t('Client invoice')}>
                        <Dropdown
                            variant="black"
                            options={clientOptions}
                            placeholder={t('Choose the client of the invoice')}
                            selectedOption={invoiceClient}
                            setSelectedOption={(e) => {
                                setInvoiceClient(e);
                                getNewCrmData(clients[e].id, selectedPartnerId);
                                getNewProjects(clients[e].id, selectedPartnerId);
                                resetMiddleFields();
                            }}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                </div>
            </div>
        </>
    );
};

InvoiceClientDropdown.propTypes = {
    clientOptions: PropTypes.arrayOf(PropTypes.string),
    invoiceClient: PropTypes.number,
    setInvoiceClient: PropTypes.func,
    resetMiddleFields: PropTypes.func,
    disabled: PropTypes.bool,
    getNewCrmData: PropTypes.func,
    getNewProjects: PropTypes.func,
    clients: PropTypes.array,
    selectedPartnerId: PropTypes.string,
};

const InvoicePartnerDropdown = (props) => {
    const { t } = useTranslation();
    const {
        partners,
        partnersOptions,
        selectedPartner,
        setSelectedPartner,
        resetMiddleFields,
        getNewCrmData,
        getNewProjects,
        disabled = false,
        selectedClientId,
    } = props;

    return (
        <>
            {/* Dropdown for the invoice client */}
            <div className="flex justify-start">
                <div className="inline-block">
                    <LabelWrapper label={t('Partner')}>
                        <Dropdown
                            variant="black"
                            options={partnersOptions}
                            placeholder={t('Choose the partner of the invoice')}
                            selectedOption={selectedPartner}
                            setSelectedOption={(e) => {
                                setSelectedPartner(e);
                                getNewCrmData(selectedClientId, partners[e].id);
                                getNewProjects(selectedClientId, partners[e].id);
                                resetMiddleFields();
                            }}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                </div>
            </div>
        </>
    );
};

InvoicePartnerDropdown.propTypes = {
    partnersOptions: PropTypes.array,
    selectedPartner: PropTypes.number,
    setSelectedPartner: PropTypes.func,
    resetMiddleFields: PropTypes.func,
    disabled: PropTypes.bool,
    getNewCrmData: PropTypes.func,
    getNewProjects: PropTypes.func,
    partners: PropTypes.array,
    selectedClientId: PropTypes.string,
};

const InvoiceReversalComponent = (props) => {
    const { t } = useTranslation();
    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);
    const { selectedAdvanceInvoices, setSelectedAdvanceInvoices, invoice, advanceInvoices, handleUpdateInvoiceValue } =
        props;

    return (
        <div className="mb-3 flex w-full max-w-max justify-start gap-3 rounded-md bg-layout-transparent p-2">
            <div className="inline-block">
                {advanceInvoices.map((i) => i.name).length > 0 ? (
                    <LabelWrapper label={t('Reverse invoices')}>
                        <MultiDropdown
                            variant="black"
                            options={advanceInvoices.map((i) => i.name)}
                            placeholder={t('Choose the advance invoices')}
                            selectedOptions={selectedAdvanceInvoices}
                            setSelectedOptions={(i) =>
                                setSelectedAdvanceInvoices((prev) => {
                                    const newSelectedAdvancedInvoices = structuredClone(prev);
                                    const selectionIndex = newSelectedAdvancedInvoices.indexOf(i);

                                    if (selectionIndex >= 0)
                                        return newSelectedAdvancedInvoices.filter((ac) => ac !== i);

                                    newSelectedAdvancedInvoices.push(i);

                                    if (invoice.type === 'REVERSAL') {
                                        handleUpdateInvoiceValue({
                                            target: {
                                                value: newSelectedAdvancedInvoices.reduce(
                                                    (acc, curr) => (acc += advanceInvoices[curr].value),
                                                    0,
                                                ),
                                            },
                                        });
                                    }

                                    return newSelectedAdvancedInvoices;
                                })
                            }
                        />
                    </LabelWrapper>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t('There are not advance invoices to be reversed! You must create an advance invoice!')}
                    </div>
                )}
            </div>

            {selectedAdvanceInvoices.length > 0 && (
                <div className="flex gap-3 sm:flex-col">
                    <div className="flex flex-col gap-3">
                        <ValueDisplay
                            label={t('Reversal value')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce((acc, curr) => (acc += advanceInvoices[curr].value), 0),
                                language,
                                2,
                            )}
                            alias={currencyObj.currency}
                        />
                        <ValueDisplay
                            label={t('Reversal value (with VAT)')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].valueWithVat),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={currencyObj.currency}
                        />
                    </div>

                    <div className="flex flex-col gap-3">
                        <ValueDisplay
                            label={t('Reversal value in the reference currency')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].secondValue),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={referenceCurrencyObj.currency}
                        />
                        <ValueDisplay
                            label={t('Reversal value in the reference currency (with VAT)')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].secondValueWithVat),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={referenceCurrencyObj.currency}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

InvoiceReversalComponent.propTypes = {
    selectedAdvanceInvoices: PropTypes.array,
    setSelectedAdvanceInvoices: PropTypes.func,
    advanceInvoices: PropTypes.arrayOf(PropTypes.object),
    invoice: PropTypes.object,
    editInvoiceInfo: PropTypes.func,
    handleUpdateInvoiceValue: PropTypes.func,
};

const InvoiceForm = (props) => {
    const styles = useStyles();

    const {
        invoiceToEdit,
        viewOnly,
        isValid,
        setInvoiceDetails,
        setInvoiceFile,
        displayCrmProjects,
        displayPmProjects,
    } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { invoiceId } = useParams();
    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    const defaultInvoiceInfo = {
        name: '',
        isValidated: true,
        billingNumber: '',
        issueDate: null,
        type: null,
        incomeType: null,
        contractId: null,
        contractPMId: null,
        partnerId: null,
        expenses: [],
        expenseNames: [],
        revenues: [],
        pmProjectOverviewPlanId: null,
        invoiceClientId: null,
        reversalValue: 0,
        value: 0,
        secondValue: 0,
        manualExchange: false,
        exchangeDate: null,
        exchangeRate: 1,
        VAT: 0,
        valueWithVat: 0,
        secondValueWithVat: 0,
    };

    const [invoice, setInvoice] = useState({ ...defaultInvoiceInfo });

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [invoiceClient, setInvoiceClient] = useState(null);

    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partnersOptions, setPartnersOptions] = useState([]);

    // States for PDF preview
    const [invoicePDF, setInvoicePDF] = useState(null);
    const [numPagesPDF, setNumPagesPDF] = useState(null);
    const [pageNumberPDF, setPageNumberPDF] = useState(1);

    // States for the CRM contracts
    const [invoiceOnAContract, setInvoiceOnAContract] = useState(false);

    // CRM & PM Contracts
    const [contractsOptions, setContractsOptions] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [crmProjects, setCrmProjects] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedCrmProject, setSelectedCrmProject] = useState(null);

    // States for the Projects
    const [invoiceOnAProject, setInvoiceOnAProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    // States for the reverse invoice
    const [advanceInvoices, setAdvanceInvoices] = useState([]);
    const [selectedAdvanceInvoices, setSelectedAdvanceInvoices] = useState([]);

    /**
     * Function for PDF total number of pages
     */
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPagesPDF(nextNumPages);
    }

    /**
     * PDF Upload Code
     */
    const handlePDFUpload = useCallback(async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                setInvoicePDF(blob);
            },
            'file',
        );
    }, []);

    /**
     * Function for editing the invoice info
     * @param {String} key - the key in the object to be modified
     * @param {String} value - the new value for the key
     */
    const editInvoiceInfo = (key, value) => {
        setInvoice((prev) => ({ ...prev, [key]: value }));
    };

    /**
     *
     * @param {*} resetAllInvoice - can be all to reset 'all' invoices and the pdfTemplates, and
     * if it is empty, will reset all invoice Info, but no pdf
     */
    const resetInvoiceInfo = useCallback(
        (resetAllInvoice) => {
            setInvoice(defaultInvoiceInfo);
            setInvoiceClient(null);
            setSelectedPartner(null);

            setSelectedContract(null);
            setSelectedProject(null);

            setInvoiceOnAContract(false);
            setInvoiceOnAProject(false);

            setSelectedAdvanceInvoices([]);

            setCheckedExpenses(defaultRevenuesAndExpensesValues);
            setCheckedRevenues(defaultRevenuesAndExpensesValues);

            if (resetAllInvoice === 'all') {
                setInvoicePDF(null);
                setNumPagesPDF(null);
                setPageNumberPDF(1);
            }

            getReferenceCompanyVAT();
            getReferenceExchangeRate(new Date(), true);
        },
        [defaultInvoiceInfo],
    );

    const resetMiddleFields = () => {
        setSelectedContract(null);
        setSelectedProject(null);
        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);
        setCheckedExpenses(defaultRevenuesAndExpensesValues);
        setCheckedRevenues(defaultRevenuesAndExpensesValues);
    };

    // ------------------ EXCHANGE RATES AND VAT LOGIC --------------------------------
    const [apiExchangeRate, setApiExchangeRate] = useState(0);
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);
    const [loadingVAT, setLoadingVAT] = useState(false);

    const disableInputs = loadingExchangeRate || loadingVAT;

    const handleUpdateExchangeRate = (e) => {
        const value = formatExchangeRate(e.target.value);
        const secondValue = invoice.value / value;
        const secondValueWithVat = calculateSumWithVAT(secondValue, invoice.VAT);

        setCheckedRevenues((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({ ...r, secondCurrencyValue: r.value / value })),
        }));
        setCheckedExpenses((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({ ...r, secondCurrencyValue: r.value / value })),
        }));

        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('secondValueWithVat', secondValueWithVat);
        editInvoiceInfo('exchangeRate', value);
    };

    const handleUpdateVAT = (e) => {
        const value = formatVATnumber(e.target.value);
        const invoiceValueWithVat = calculateSumWithVAT(invoice.value, value);
        const invoiceSecondValueWithVat = calculateSumWithVAT(invoice.value / invoice.exchangeRate, value);

        editInvoiceInfo('VAT', value);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    const getExchangeRate = async (e) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(t("The selected date can't be greater then the current date!"), {
                    variant: 'error',
                });
                selectedDate = new Date();
            }

            editInvoiceInfo('exchangeDate', selectedDate);
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: selectedDate,
                },
            });

            const { rates } = res.data.data;

            handleUpdateExchangeRate({ target: { value: +rates } });
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getReferenceExchangeRate = async (e, canSetInitial = false) => {
        try {
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: new Date(e),
                },
            });

            const { rates } = res.data.data;

            if (canSetInitial) {
                editInvoiceInfo('exchangeDate', e);
                editInvoiceInfo('exchangeRate', +rates);
            }
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getReferenceCompanyVAT = async () => {
        try {
            setLoadingVAT(true);
            const resVat = await handleGetCompanyVAT();
            editInvoiceInfo('VAT', resVat);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingVAT(false);
        }
    };

    /**
     * Logic for updating the invoice value
     */

    const handleUpdateInvoiceValue = (e) => {
        const value = formatPositiveNumberWithDigits(e.target.value);
        const secondValue = value / invoice.exchangeRate;
        const invoiceValueWithVat = calculateSumWithVAT(value, invoice.VAT);
        const invoiceSecondValueWithVat = calculateSumWithVAT(value / invoice.exchangeRate, invoice.VAT);

        editInvoiceInfo('value', value);
        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    /**
     * Get clients for the invoice
     */
    const getAllClients = async () => {
        try {
            const res = await getContacts();

            setClients(res);

            setClientOptions(
                res.map((c) => {
                    const data = JSON.parse(c.data);
                    return 'standard' in data && 'name' in data['standard'] ? data['standard'].name : 'Nume inexistent';
                }),
            );

            return res;
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Get all partners
     */
    const getAllPartners = async () => {
        try {
            const partnersAccounts = await getPartners();

            setPartners(partnersAccounts);
            setPartnersOptions(
                partnersAccounts.map((partner) => {
                    const data = typeof partner.data === 'string' ? JSON.parse(partner.data) : partner.data;
                    return data['standard'].name;
                }),
            );

            return partnersAccounts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        if (invoiceToEdit) return;

        const getInitialData = async () => {
            try {
                await Promise.all([
                    getReferenceCompanyVAT(),
                    getReferenceExchangeRate(new Date(), true),
                    getAllClients(),
                    getAllPartners(),
                ]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getInitialData();
    }, []);

    const getSlicedContracts = async (isCase, id) => {
        let contracts = [];

        try {
            let res;

            if (isCase === 'client') res = await API.get('/contactsContracts', { params: { contactId: id } });
            else if (isCase === 'partner') res = await API.get('/contactsContracts', { params: { partnerId: id } });

            contracts = res.data.contactsContracts.contracts;
        } catch (error) {
            console.error(error);
        }

        return contracts;
    };

    const getNewContracts = async (client, partner) => {
        let newDisplayedContracts = [];

        if (client !== null) {
            newDisplayedContracts = await getSlicedContracts('client', client);
        } else if (client === null && partner !== null) {
            newDisplayedContracts = await getSlicedContracts('partner', partner);
        } else {
            newDisplayedContracts = [];
        }

        setContracts(newDisplayedContracts);

        if (newDisplayedContracts.length !== 0) {
            const contractsOptions = newDisplayedContracts.map((c) => {
                const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });

            setContractsOptions(contractsOptions);
        } else {
            setContractsOptions([]);
        }

        return newDisplayedContracts;
    };

    const getNewCrmProjects = async (clientId) => {
        try {
            if (!clientId) {
                setCrmProjects([]);
                return [];
            }

            const rowProjects = await API.get('/client_crmProjects', {
                params: {
                    id: clientId,
                },
            });

            setCrmProjects(rowProjects.data.contactsCrmProjects.projects);

            return rowProjects.data.contactsCrmProjects.projects;
        } catch (error) {
            console.error(error);
        }
    };

    const getNewCrmData = async (clientId, partnerId) => {
        try {
            await getNewContracts(clientId, partnerId);
            await getNewCrmProjects(clientId);
        } catch (error) {
            console.error(error);
        }
    };

    const getSlicedProjects = async (isCase, id) => {
        let projects = [];

        try {
            let res;

            if (isCase === 'client')
                res = await API.get('/contactsPm_projects', {
                    params: { perPage: 99999, currentPage: 0, pagesToLoad: 1, contactId: id },
                });
            else if (isCase === 'partner')
                res = await API.get('/partnersPm_projects', {
                    params: { perPage: 99999, currentPage: 0, pagesToLoad: 1, partnerId: id },
                });

            projects = res.data.projects;
        } catch (error) {
            console.error(error);
        }

        return projects;
    };

    const getNewProjects = async (client, partner) => {
        let newDisplayedProjects = [];

        if (client !== null) {
            newDisplayedProjects = await getSlicedProjects('client', client);
        } else if (client === null && partner !== null) {
            newDisplayedProjects = await getSlicedProjects('partner', partner);
        } else {
            newDisplayedProjects = [];
        }

        setProjects(newDisplayedProjects);

        return newDisplayedProjects;
    };

    const getPMContracts = async (pmId) => {
        try {
            const response = await API.get('project_contracts_pm', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                    projectId: pmId,
                },
            });

            const resContracts = response.data.contracts;
            setContracts(resContracts);

            const contractsOptions = resContracts.map((c) => {
                const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });
            setContractsOptions(contractsOptions);

            return resContracts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    /**
     * With this function we check when to display the client dropdown
     */
    const whenToDisplayClientOnIncome = () => {
        let display = false;

        if (invoice.type === 'INCOME') {
            if (invoice.incomeType === 'SIMPLE' && invoice.value !== 0) {
                display = true;
            } else if (
                invoice.incomeType === 'REVERSE' &&
                selectedAdvanceInvoices.length > 0 &&
                !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false)
            ) {
                display = true;
            }
        }

        return display;
    };

    /**
     * Function for getting the advance invoices
     */
    const getAdvanceInvoices = useCallback(async () => {
        try {
            const res = await API.get('/invoices', {
                params: {
                    type: 'ADVANCE',
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    isUsed: invoiceToEdit ? undefined : false,
                },
            });

            const newInvoices = res.data.invoices.filter(
                (invoice) => invoice.isValidated === true && invoice.payment === 'PAID',
            );

            setAdvanceInvoices(newInvoices);

            return newInvoices;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    }, [invoiceToEdit]);

    /**
     * With this function we reset al the info from an income invoice when the user change the income invoice type
     */
    const whenChangeIncomeInvoiceType = () => {
        setInvoiceClient(null);
        setSelectedPartner(null);

        setSelectedContract(null);
        setSelectedProject(null);
        setSelectedCrmProject(null);

        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);

        setSelectedAdvanceInvoices([]);

        getReferenceCompanyVAT();
        getReferenceExchangeRate(new Date(), true);
    };

    /**
     * Function for the changing the invoice configurator title depending on the invoice type
     * @returns - the title
     */
    const changeTheTitle = () => {
        let title = '';

        switch (invoice.type) {
            case 'EXPENSE':
                title = t('Configure the expense invoice');
                break;
            case 'ADVANCE':
                title = t('Configure the advance invoice');
                break;
            case 'REVERSAL':
                title = t('Configure the reversal invoice');
                break;
            case 'INCOME':
                if (invoice.incomeType === 'SIMPLE') {
                    title = t('Configure the simple income invoice');
                } else if (invoice.incomeType === 'REVERSE') {
                    title = t('Configure the income invoice with advance reversal');
                }
                break;
        }

        return title;
    };

    /**
     *  This function check when to display the invoice last info (name, billing number and issue date)
     * @returns - a boolean value indicating if the section will displayed
     */
    const whenToDisplayLastInfoOfInvoice = () => {
        let display = false;

        if (
            (invoiceClient !== null || selectedPartner !== null) &&
            invoice.type === 'EXPENSE' &&
            checkedExpenses.list.length > 0 &&
            validExpenseValue
        ) {
            display = true;
        } else if (
            invoice.type === 'ADVANCE' &&
            (invoiceClient !== null || selectedPartner !== null) &&
            checkedRevenues.list.length > 0 &&
            validRevenueValue
        ) {
            display = true;
        } else if (
            invoice.type === 'REVERSAL' &&
            selectedAdvanceInvoices.length > 0 &&
            !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false) &&
            checkedRevenues.list.length > 0 &&
            validRevenueValue
        ) {
            display = true;
        } else if (invoice.type === 'INCOME' && checkedRevenues.list.length > 0 && validRevenueValue) {
            if (invoice.incomeType === 'SIMPLE' && (invoiceClient !== null || selectedPartner !== null)) {
                display = true;
            } else if (
                invoice.incomeType === 'REVERSE' &&
                selectedAdvanceInvoices.length > 0 &&
                !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false) &&
                (invoiceClient !== null || selectedPartner !== null)
            ) {
                display = true;
            }
        }

        return display;
    };

    const invoiceInfoSchema = yup.object().shape({
        invoiceName: yup
            .string()
            .trim()
            .typeError(t('The invoice name is required'))
            .required(t('The invoice name is required')),
        invoiceNumber: yup
            .string()
            .trim()
            .typeError(t('The invoice number is required'))
            .required(t('The invoice number is required'))
            .min(3, t('The invoice number must be at least 3 characters long!')),
    });

    const creatingInvoiceBodyForPOSTRequest = () => {
        let body = {
            name: invoice.name,
            isValidated: isValid,
            billingNumber: invoice.billingNumber,
            issueDate: invoice.issueDate,
            type: invoice.type,
        };

        const expenses = () => {
            if (checkedExpenses.list.length > 0) {
                return [...checkedExpenses.list];
            } else {
                return [];
            }
        };

        const revenue = () => {
            if (checkedRevenues.list.length > 0) {
                return [...checkedRevenues.list];
            } else {
                return [];
            }
        };

        switch (invoice.type) {
            case 'EXPENSE':
                body.contactId = clients[invoiceClient]?.id ?? null;
                body.partnerId = partners[selectedPartner]?.id ?? null;
                body.value = Number(invoice.value);
                body.secondValue = Number(invoice.secondValue);
                body.exchangeDate = invoice.exchangeDate;
                body.exchangeRate = invoice.exchangeRate;
                body.manualExchange = invoice.exchangeRate !== apiExchangeRate;
                body.VAT = invoice.VAT;
                body.valueWithVat = Number(invoice.valueWithVat);
                body.secondValueWithVat = Number(invoice.secondValueWithVat);

                if (isBudgetCase) {
                    body.expenses = expenses();
                    body.expenseNames = [];
                } else {
                    body.expenses = [];
                    body.expenseNames = expenses();
                }

                if (invoiceOnAContract) {
                    body.contractId = contracts[selectedContract]?.id ?? null;
                    body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                    body.contractPMId = null;
                    body.pmProjectOverviewPlanId = null;
                }

                if (invoiceOnAProject) {
                    body.contractId = null;
                    body.crmProjectId = null;
                    body.contractPMId = contracts[selectedContract]?.id ?? null;
                    body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                }

                break;
            case 'ADVANCE':
                body.contactId = clients[invoiceClient]?.id;
                body.partnerId = partners[selectedPartner]?.id;
                body.value = Number(invoice.value);
                body.secondValue = Number(invoice.secondValue);
                body.exchangeDate = invoice.exchangeDate;
                body.exchangeRate = invoice.exchangeRate;
                body.manualExchange = invoice.exchangeRate !== apiExchangeRate;
                body.revenues = revenue();
                body.VAT = invoice.VAT;
                body.valueWithVat = Number(invoice.valueWithVat);
                body.secondValueWithVat = Number(invoice.secondValueWithVat);

                if (invoiceOnAContract) {
                    body.contractId = contracts[selectedContract]?.id ?? null;
                    body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                    body.contractPMId = null;
                    body.pmProjectOverviewPlanId = null;
                }

                if (invoiceOnAProject) {
                    body.contractId = null;
                    body.crmProjectId = null;
                    body.contractPMId = contracts[selectedContract]?.id ?? null;
                    body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                }

                break;
            case 'REVERSAL':
                body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                    id: advanceInvoices[i]?.id,
                }));
                body.value = Number(invoice.value);
                body.secondValue = Number(invoice.secondValue);
                body.exchangeDate = invoice.exchangeDate;
                body.exchangeRate = invoice.exchangeRate;
                body.manualExchange = invoice.exchangeRate !== apiExchangeRate;
                body.revenues = revenue();
                body.VAT = invoice.VAT;
                body.valueWithVat = Number(invoice.valueWithVat);
                body.secondValueWithVat = Number(invoice.secondValueWithVat);

                break;
            case 'INCOME':
                if (invoice.incomeType === 'SIMPLE') {
                    body.contactId = clients[invoiceClient]?.id ?? null;
                    body.partnerId = partners[selectedPartner]?.id ?? null;
                    body.value = Number(invoice.value);
                    body.secondValue = Number(invoice.secondValue);
                    body.exchangeDate = invoice.exchangeDate;
                    body.exchangeRate = invoice.exchangeRate;
                    body.manualExchange = invoice.exchangeRate !== apiExchangeRate;
                    body.incomeType = 'SIMPLE';
                    body.revenues = revenue();
                    body.VAT = invoice.VAT;
                    body.valueWithVat = Number(invoice.valueWithVat);
                    body.secondValueWithVat = Number(invoice.secondValueWithVat);

                    if (invoiceOnAContract) {
                        body.contractId = contracts[selectedContract]?.id ?? null;
                        body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                        body.contractPMId = null;
                        body.pmProjectOverviewPlanId = null;
                    }

                    if (invoiceOnAProject) {
                        body.contractId = null;
                        body.crmProjectId = null;
                        body.contractPMId = contracts[selectedContract]?.id ?? null;
                        body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                    }
                } else if (invoice.incomeType === 'REVERSE') {
                    body.contactId = clients[invoiceClient]?.id ?? null;
                    body.partnerId = partners[selectedPartner]?.id ?? null;
                    body.value = Number(invoice.value);
                    body.secondValue = Number(invoice.secondValue);
                    body.exchangeDate = invoice.exchangeDate;
                    body.exchangeRate = invoice.exchangeRate;
                    body.manualExchange = invoice.exchangeRate !== apiExchangeRate;
                    body.incomeType = 'REVERSE';
                    body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                        id: advanceInvoices[i]?.id,
                    }));
                    body.revenues = revenue();
                    body.VAT = invoice.VAT;
                    body.valueWithVat = Number(invoice.valueWithVat);
                    body.secondValueWithVat = Number(invoice.secondValueWithVat);

                    if (invoiceOnAContract) {
                        body.contractId = contracts[selectedContract]?.id ?? null;
                        body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                        body.contractPMId = null;
                        body.pmProjectOverviewPlanId = null;
                    }

                    if (invoiceOnAProject) {
                        body.contractId = null;
                        body.crmProjectId = null;
                        body.contractPMId = contracts[selectedContract]?.id ?? null;
                        body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
                    }
                }
                break;
        }

        return body;
    };

    const handleInvoiceCreationOrUpdate = async () => {
        try {
            await invoiceInfoSchema.validate({
                invoiceName: invoice.name,
                invoiceNumber: invoice.billingNumber,
            });

            try {
                setLoading(true);
                const reqBody = new FormData();

                const data = creatingInvoiceBodyForPOSTRequest();

                if (invoice.type === 'EXPENSE' && data.expenses.length === 0 && data.expenseNames.length === 0) {
                    enqueueSnackbar(t('The invoice must have one expense selected!'), {
                        variant: 'error',
                    });

                    return;
                }

                if (invoice.type === 'INCOME' && data.revenues.length === 0) {
                    enqueueSnackbar(t('The invoice must have one revenue selected!'), {
                        variant: 'error',
                    });

                    return;
                }

                if (invoiceId) {
                    await API.put('/invoice', {
                        id: invoiceId,
                        data,
                    });

                    enqueueSnackbar(t('The invoice has been updated successfully!'), {
                        variant: 'success',
                    });
                } else {
                    if (isValid === false) {
                        setInvoiceDetails(data);
                        setInvoiceFile(invoicePDF);
                    } else {
                        reqBody.append('data', JSON.stringify(data));
                        reqBody.append('files', invoicePDF);

                        await API.post('/invoice', reqBody, { 'Content-Type': 'multipart/form-data' });
                    }

                    enqueueSnackbar(t('The invoice has been added successfully!'), {
                        variant: 'success',
                    });

                    if (
                        invoice.type === 'REVERSAL' ||
                        (invoice.type === 'INCOME' && invoice.incomeType === 'REVERSE')
                    ) {
                        await Promise.all(
                            data.invoiceIds.map(
                                async (invoice) =>
                                    await API.put('/invoice', {
                                        id: invoice.id,
                                        data: {
                                            isUsed: true,
                                        },
                                    }),
                            ),
                        );
                    }
                }

                if (isValid === true) {
                    history.push(accountancy.base + accountancy.invoice.base);
                }
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], {
                variant: 'error',
            });
        }
    };

    const whenToDisplaySubmitButton = () => {
        if (
            invoice.name !== '' &&
            invoice.billingNumber !== '' &&
            invoice.issueDate !== null &&
            whenToDisplayLastInfoOfInvoice()
        ) {
            return true;
        } else {
            return false;
        }
    };

    const canRenderContractAndProjectSelection = () => {
        return (
            (invoiceClient !== null || selectedPartner !== null) &&
            (invoice.type !== 'EXPENSE' || invoice.type !== 'REVERSAL')
        );
    };

    /**
     * This function transform invoiceToEdit object in an object like defaultInvoiceInfo
     */
    const transformInvoiceToEdit = async () => {
        try {
            const newInvoice = {
                name: invoiceToEdit.name,
                isValidated: invoiceToEdit.isValidated,
                billingNumber: invoiceToEdit.billingNumber,
                issueDate: invoiceToEdit.issueDate,
                type: invoiceToEdit.type,
                incomeType: invoiceToEdit.incomeType,
                projectStructureActivityId: invoiceToEdit.projectStructureActivityId,
                contractId: invoiceToEdit.contractId,
                contractPMId: invoiceToEdit.contractPMId,
                partnerId: invoiceToEdit.partnerId,
                expenses: [],
                revenues: [],
                expenseNames: [],
                invoiceIds: [],
                crmProjectId: invoiceToEdit.crmProjectId,
                pmProjectOverviewPlanId: invoiceToEdit.pmProjectOverviewPlanId,
                invoiceClientId: invoiceToEdit.contactId,
                value: invoiceToEdit.value,
                reversalValue: invoiceToEdit.reversalValue,
                secondValue: invoiceToEdit.secondValue,
                manualExchange: invoiceToEdit.manualExchange,
                exchangeDate: invoiceToEdit.exchangeDate,
                exchangeRate: invoiceToEdit.exchangeRate,
                VAT: invoiceToEdit.VAT,
                valueWithVat: invoiceToEdit.valueWithVat,
                secondValueWithVat: invoiceToEdit.secondValueWithVat,
            };

            const contractPromise = { promise: null, args: [] };
            const projectPromise = { promise: null, args: [] };

            if (invoiceToEdit.contractId !== null || invoiceToEdit.crmProjectId !== null) {
                contractPromise.promise = getNewContracts;
                contractPromise.args = [invoiceToEdit.contactId, invoiceToEdit.partnerId];

                projectPromise.promise = getNewCrmProjects;
                projectPromise.args = [invoiceToEdit.contactId];
            }

            if (invoiceToEdit.contractPMId !== null) {
                contractPromise.promise = getPMContracts;
                contractPromise.args = [invoiceToEdit.pmProjectOverviewPlanId];
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                projectPromise.promise = getNewProjects;
                projectPromise.args = [invoiceToEdit.contactId, invoiceToEdit.partnerId];
            }

            const [
                returnedClients,
                returnedPartners,
                returnedAdvanceInvoices,
                returnedContracts = [],
                returnedProjects,
            ] = await Promise.all([
                getAllClients(),
                getAllPartners(),
                getAdvanceInvoices(),
                contractPromise.promise ? contractPromise.promise(...contractPromise.args) : undefined,
                projectPromise.promise ? projectPromise.promise(...projectPromise.args) : undefined,
                getReferenceExchangeRate(invoiceToEdit.exchangeDate),
            ]);

            setInvoicePDF({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: invoiceToEdit.fileArray[0]?.signedUrl,
            });

            // We set the invoice client dropdown with the selected invoice client
            if (invoiceToEdit.contactId !== null) {
                const clientIndex = returnedClients.findIndex((c) => c.id === invoiceToEdit.contactId);
                setInvoiceClient(clientIndex);
            }

            if (invoiceToEdit.partnerId !== null) {
                const partnerIndex = returnedPartners.findIndex((p) => p.id === invoiceToEdit.partnerId);
                setSelectedPartner(partnerIndex);
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                setInvoiceOnAProject(true);
                setSelectedProject(returnedProjects.findIndex((p) => p.id === invoiceToEdit.pmProjectOverviewPlanId));
                if (invoiceToEdit.contractPMId)
                    setSelectedContract(returnedContracts.findIndex((c) => c.id === invoiceToEdit.contractPMId));
            } else {
                setInvoiceOnAContract(true);
                if (invoiceToEdit.contractId)
                    setSelectedContract(returnedContracts.findIndex((c) => c.id === invoiceToEdit.contractId));

                if (invoiceToEdit.crmProjectId)
                    setSelectedCrmProject(returnedProjects.findIndex((p) => p.id === invoiceToEdit.crmProjectId));
            }

            // For advance invoices dropdown
            if (invoiceToEdit.invoiceIds.length > 0) {
                const selectedAdvanceInvoicesIndexes = invoiceToEdit.invoiceIds.reduce((acc, curr) => {
                    const revenueIdx = returnedAdvanceInvoices.findIndex((invoice) => invoice.id === curr.id);
                    if (revenueIdx >= 0) return [...acc, revenueIdx];

                    return acc;
                }, []);

                setSelectedAdvanceInvoices(selectedAdvanceInvoicesIndexes);
            }

            setInvoice(newInvoice);

            if (invoiceToEdit.type === 'EXPENSE') {
                let checkedExpenses = {};

                const isBudgetaryCase =
                    invoiceToEdit.InvoiceToExpense.length > 0
                        ? true
                        : invoiceToEdit.InvoiceToExpenseName.length > 0
                          ? false
                          : false;

                setIsBudgetCase(isBudgetaryCase);

                if (isBudgetaryCase) {
                    const grandParentId =
                        invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.subExpenseName === null
                            ? invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.id
                            : invoiceToEdit.InvoiceToExpense[0].expense.expenseName.subExpenseName.subExpenseName.id;

                    checkedExpenses = {
                        grandParentId,
                        list: invoiceToEdit.InvoiceToExpense.map((item) => ({
                            id: item.expenseId,
                            value: item.value,
                            secondCurrencyValue: item.secondCurrencyValue,
                        })),
                    };
                } else {
                    const grandParentId =
                        invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.subExpenseName === null
                            ? invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.id
                            : invoiceToEdit.InvoiceToExpenseName[0].expenseName.subExpenseName.subExpenseName.id;

                    checkedExpenses = {
                        grandParentId,
                        list: invoiceToEdit.InvoiceToExpenseName.map((expense) => ({
                            id: expense.expenseNameId,
                            value: expense.value,
                            secondCurrencyValue: expense.secondCurrencyValue,
                        })),
                    };
                }

                setCheckedExpenses(checkedExpenses);
            }

            if (
                invoiceToEdit.type === 'INCOME' ||
                invoiceToEdit.type === 'REVERSAL' ||
                invoiceToEdit.type === 'ADVANCE'
            ) {
                const grandParentId =
                    invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.subRevenue === null
                        ? invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.id
                        : invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue.subRevenue.id;

                const checkedRevenues = {
                    grandParentId,
                    list: invoiceToEdit.InvoiceToRevenue.map((revenue) => ({
                        id: revenue.revenueId,
                        value: revenue.value,
                        secondCurrencyValue: revenue.secondCurrencyValue,
                    })),
                };

                setCheckedRevenues(checkedRevenues);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!invoiceToEdit) return;

        transformInvoiceToEdit();
    }, [invoiceToEdit]);

    // ------------------------------ Logic for the expenses ----------------------------------
    const defaultRevenuesAndExpensesValues = {
        grandParentId: null,
        list: [],
    };

    const [checkedExpenses, setCheckedExpenses] = useState(defaultRevenuesAndExpensesValues);
    const [isBudgetCase, setIsBudgetCase] = useState(false);

    const canRenderExpenses = () => {
        if (
            invoice.type === 'EXPENSE' &&
            (((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
                (invoiceOnAProject && selectedProject !== null))
        )
            return true;
        else return false;
    };

    const checkedExpensesSum = toRoundedSignificantDigits(
        checkedExpenses.list.reduce((sum, current) => (sum += current.value), 0),
    );
    const validExpenseValue = checkedExpensesSum === invoice.value;

    // ------------------------------ Logic for the revenue ----------------------------------

    const [checkedRevenues, setCheckedRevenues] = useState(defaultRevenuesAndExpensesValues);

    const canRenderRevenue = () => {
        if (
            (invoice.type === 'INCOME' || invoice.type === 'ADVANCE') &&
            (((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
                (invoiceOnAProject && selectedProject !== null))
        )
            return true;
        if (invoice.type === 'REVERSAL' && invoice.value !== 0) return true;
        else return false;
    };

    const checkedRevenuesSum = toRoundedSignificantDigits(
        checkedRevenues.list.reduce((sum, current) => (sum += current.value), 0),
    );
    const validRevenueValue = checkedRevenuesSum === invoice.value;

    return (
        <>
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className={`flex ${isValid ? '' : 'flex-col'} gap-24 lg:flex-col lg:items-center`}>
                    {/**
                     * First section with PDF preview and invoice type
                     *  */}
                    {/* deleted the class that had the width set to 1/2 */}

                    <div className="flex flex-col items-center">
                        {/* Button for pdf upload - this will disappear after the upload of the invoice PDF */}
                        {!invoicePDF && (
                            <FileUploadContainer onUpload={(e) => handlePDFUpload(e)}>
                                <Button color="secondary" startIcon={<i className="fa-solid fa-file-invoice" />}>
                                    {t('Upload invoice PDF')}
                                </Button>
                            </FileUploadContainer>
                        )}

                        {/* PDF preview */}
                        <div className="group mb-20">
                            <div className="Example__container">
                                <div className="Example__container__load"></div>
                                <div className="Example__container__document relative">
                                    {invoicePDF && (
                                        <Document
                                            file={invoicePDF}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            options={options}
                                            noData={''}
                                            error={
                                                <div className="text-error">{t('Error on loading invoice PDF')}</div>
                                            }
                                            loading={
                                                <div className="text-primary-main">
                                                    {t('Invoice PDF is loading, please wait!')}
                                                </div>
                                            }
                                        >
                                            <Page pageNumber={pageNumberPDF} />
                                        </Document>
                                    )}
                                    {invoicePDF && (
                                        <div
                                            className="absolute flex -translate-x-2/4 -translate-y-20 transform select-none rounded-md bg-layout-transparent opacity-0  transition-all duration-200 group-hover:translate-y-0 group-hover:opacity-100 group-hover:shadow-xl"
                                            style={{
                                                zIndex: 999,
                                                bottom: '5%',
                                                left: '50%',
                                            }}
                                        >
                                            <div
                                                className={` rounded-md p-3 transition-all ${
                                                    pageNumberPDF === 1
                                                        ? 'text-gray-200'
                                                        : 'text-primary-light hover:bg-blue-100 hover:text-primary-dark'
                                                }`}
                                                onClick={() => {
                                                    if (pageNumberPDF === 1) return;
                                                    else setPageNumberPDF(pageNumberPDF - 1);
                                                }}
                                            >
                                                <ArrowBackIosIcon />
                                            </div>
                                            <div className="p-3 font-semibold text-black">
                                                <span className="font-bold text-blue-600">{pageNumberPDF}</span> of{' '}
                                                {numPagesPDF}
                                            </div>
                                            <div
                                                className={`rounded-md p-3 transition-all ${
                                                    pageNumberPDF === numPagesPDF
                                                        ? 'text-gray-200'
                                                        : 'text-primary-light hover:bg-blue-100 hover:text-primary-dark'
                                                }
                                        `}
                                                onClick={() => {
                                                    if (pageNumberPDF === numPagesPDF) return;
                                                    else setPageNumberPDF(pageNumberPDF + 1);
                                                }}
                                            >
                                                <ArrowForwardIosIcon />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Buttons to select the invoice type */}
                        {invoicePDF && invoice.type === null && (
                            <div className="align-center mb-10 flex flex-col justify-center">
                                <h4 className="mb-10 text-center text-main-text">{t('Pick the invoice type')}</h4>
                                <div className="m-auto flex flex-wrap justify-start gap-5">
                                    <Button
                                        className={styles.expense}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => editInvoiceInfo('type', 'EXPENSE')}
                                    >
                                        {t('Expense')}
                                    </Button>

                                    <Button
                                        className={styles.income}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => {
                                            editInvoiceInfo('type', 'INCOME');

                                            getAdvanceInvoices();
                                        }}
                                    >
                                        {t('Income')}
                                    </Button>

                                    <Button
                                        className={styles.advance}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => editInvoiceInfo('type', 'ADVANCE')}
                                    >
                                        {t('Advance')}
                                    </Button>

                                    <Button
                                        className={styles.reverse}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => {
                                            editInvoiceInfo('type', 'REVERSAL');

                                            getAdvanceInvoices();
                                        }}
                                    >
                                        {t('Reverse')}
                                    </Button>
                                </div>
                            </div>
                        )}

                        {/* This button will change the invoice type to null and will reset the invoice info */}
                        {invoicePDF && invoice.type !== null && !invoiceId && !viewOnly && (
                            <div className="flex justify-center">
                                <Button
                                    color="primary"
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={() => {
                                        editInvoiceInfo('type', null);
                                        resetInvoiceInfo();
                                    }}
                                >
                                    {t('Change the invoice type')}
                                </Button>
                            </div>
                        )}
                    </div>

                    {/* Invoice configuration */}
                    <div className={`${viewOnly ? 'pointer-events-none' : ''}`}>
                        {/* Invoice configurator title */}
                        {invoice.type !== null && (
                            <h4 className="mb-20 text-left text-2xl text-main-text">{changeTheTitle()}</h4>
                        )}

                        {/* Invoice value for expense, advance */}
                        {(invoice.type === 'EXPENSE' || invoice.type === 'ADVANCE' || invoice.type === 'REVERSAL') && (
                            <div className="mb-10 flex max-w-2xl flex-col gap-3">
                                <div className="inline-block">
                                    <LocaleTextField
                                        disabled={disableInputs}
                                        name="vat"
                                        label={`${t('Enter VAT')} %`}
                                        placeholder={`${t('Enter VAT')} %`}
                                        value={invoice.VAT}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        onChange={handleUpdateVAT}
                                    />
                                </div>

                                <div className="flex gap-3">
                                    <LabelWrapper label={t('Select the date for the exchange rate')}>
                                        <DatePicker
                                            disabled={disableInputs}
                                            date={invoice.exchangeDate}
                                            setDate={getExchangeRate}
                                        />
                                    </LabelWrapper>
                                    <LabelWrapper label={t('Exchange rate')}>
                                        <LocaleTextField
                                            disabled={disableInputs}
                                            placeholder={`${toLocaleNumber(0, language, 4, 4)}`}
                                            value={invoice.exchangeRate}
                                            onChange={handleUpdateExchangeRate}
                                            minDecimals={4}
                                            maxDecimals={4}
                                        />
                                    </LabelWrapper>
                                </div>

                                {invoice.type !== 'REVERSAL' && (
                                    <div className="flex max-w-max gap-3 rounded-md bg-layout-transparent p-2">
                                        <div className="flex flex-col gap-3">
                                            <LabelWrapper label={t('Invoice value')}>
                                                <LocaleTextField
                                                    disabled={disableInputs}
                                                    placeholder={`${toLocaleNumber(100, language, 2)} ${currencyObj.currency}`}
                                                    value={invoice.value}
                                                    onChange={handleUpdateInvoiceValue}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                {currencyObj.currency}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </LabelWrapper>
                                            <ValueDisplay
                                                label={t('Invoice value (with VAT)')}
                                                value={toLocaleNumber(invoice.valueWithVat, language, 2)}
                                                alias={currencyObj.currency}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-3">
                                            <ValueDisplay
                                                label={t('Invoice value in the reference currency')}
                                                value={toLocaleNumber(invoice.secondValue, language, 2)}
                                                alias={referenceCurrencyObj.currency}
                                            />

                                            <ValueDisplay
                                                label={t('Invoice value in the reference currency (with VAT)')}
                                                value={toLocaleNumber(invoice.secondValueWithVat, language, 2)}
                                                alias={referenceCurrencyObj.currency}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Invoice client dropdown component - displayed only in expense type invoice  */}
                        {invoice.type === 'EXPENSE' && invoice.value !== 0 && (
                            <div className="mb-6 flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <InvoiceClientDropdown
                                        clients={clients}
                                        clientOptions={clientOptions}
                                        invoiceClient={invoiceClient}
                                        setInvoiceClient={setInvoiceClient}
                                        resetMiddleFields={resetMiddleFields}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        selectedPartnerId={partners[selectedPartner]?.id ?? null}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={invoiceClient !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={invoiceClient !== null ? false : true}
                                            onClick={() => {
                                                setInvoiceClient(null);
                                                getNewCrmData(null, partners[selectedPartner]?.id ?? null);
                                                getNewProjects(null, partners[selectedPartner]?.id ?? null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-3">
                                    <InvoicePartnerDropdown
                                        partnersOptions={partnersOptions}
                                        selectedPartner={selectedPartner}
                                        setSelectedPartner={setSelectedPartner}
                                        resetMiddleFields={resetMiddleFields}
                                        partners={partners}
                                        selectedClientId={clients[invoiceClient]?.id ?? null}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={selectedPartner !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={selectedPartner !== null ? false : true}
                                            onClick={() => {
                                                setSelectedPartner(null);
                                                getNewCrmData(clients[invoiceClient]?.id ?? null, null);
                                                getNewProjects(clients[invoiceClient]?.id ?? null, null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Invoice client dropdown component - displayed only in advance type invoice  */}
                        {invoice.type === 'ADVANCE' && invoice.value !== 0 && (
                            <div className="mb-6 flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <InvoiceClientDropdown
                                        clients={clients}
                                        clientOptions={clientOptions}
                                        invoiceClient={invoiceClient}
                                        setInvoiceClient={setInvoiceClient}
                                        resetMiddleFields={resetMiddleFields}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        selectedPartnerId={partners[selectedPartner]?.id ?? null}
                                        disabled={selectedPartner !== null}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={invoiceClient !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={invoiceClient !== null ? false : true}
                                            onClick={() => {
                                                setInvoiceClient(null);
                                                getNewCrmData(null, partners[selectedPartner]?.id ?? null);
                                                getNewProjects(null, partners[selectedPartner]?.id ?? null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-3">
                                    <InvoicePartnerDropdown
                                        partnersOptions={partnersOptions}
                                        selectedPartner={selectedPartner}
                                        setSelectedPartner={setSelectedPartner}
                                        resetMiddleFields={resetMiddleFields}
                                        partners={partners}
                                        selectedClientId={clients[invoiceClient]?.id ?? null}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        disabled={invoiceClient !== null}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={selectedPartner !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={selectedPartner !== null ? false : true}
                                            onClick={() => {
                                                setSelectedPartner(null);
                                                getNewCrmData(clients[invoiceClient]?.id ?? null, null);
                                                getNewProjects(clients[invoiceClient]?.id ?? null, null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Income type invoice */}
                        {invoice.type === 'INCOME' && !invoice.incomeType && (
                            <div>
                                {/* Selecting area for the income invoice type */}
                                <div className="mb-10 flex flex-wrap justify-start gap-5">
                                    <Button
                                        className={styles.income}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => editInvoiceInfo('incomeType', 'SIMPLE')}
                                    >
                                        {t('Simple income invoice')}
                                    </Button>

                                    <Button
                                        className={styles.income}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => editInvoiceInfo('incomeType', 'REVERSE')}
                                    >
                                        {t('Income invoice with advance reversal')}
                                    </Button>
                                </div>
                            </div>
                        )}

                        {/* Button for changing the income type */}
                        {invoice.type === 'INCOME' && invoice.incomeType && !viewOnly && (
                            <div className="mb-20 flex justify-start">
                                <Button
                                    color="primary"
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={() => {
                                        editInvoiceInfo('incomeType', null);
                                        whenChangeIncomeInvoiceType();
                                    }}
                                >
                                    {t('Change the income invoice type')}
                                </Button>
                            </div>
                        )}

                        {invoice.type === 'INCOME' &&
                            (invoice.incomeType === 'SIMPLE' || invoice.incomeType === 'REVERSE') && (
                                <div className="mb-10 flex max-w-2xl flex-col gap-3">
                                    <div className="inline-block">
                                        <LocaleTextField
                                            disabled={disableInputs}
                                            name="vat"
                                            label={`${t('Enter VAT')} %`}
                                            placeholder={`${t('Enter VAT')} %`}
                                            value={invoice.VAT}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            onChange={handleUpdateVAT}
                                        />
                                    </div>

                                    <div className="flex gap-3">
                                        <LabelWrapper label={t('Select the date for the exchange rate')}>
                                            <DatePicker
                                                disabled={disableInputs}
                                                date={invoice.exchangeDate}
                                                setDate={getExchangeRate}
                                            />
                                        </LabelWrapper>
                                        <LabelWrapper label={t('Exchange rate')}>
                                            <LocaleTextField
                                                disabled={disableInputs}
                                                placeholder={`${toLocaleNumber(0, language, 4, 4)}`}
                                                value={invoice.exchangeRate}
                                                onChange={handleUpdateExchangeRate}
                                                minDecimals={4}
                                                maxDecimals={4}
                                            />
                                        </LabelWrapper>
                                    </div>

                                    <div className="flex max-w-max gap-3 rounded-md bg-layout-transparent p-2">
                                        <div className="flex flex-col gap-3">
                                            <LabelWrapper label={t('Invoice value')}>
                                                <LocaleTextField
                                                    disabled={disableInputs}
                                                    placeholder={`${toLocaleNumber(100, language, 2)} ${currencyObj.currency}`}
                                                    value={invoice.value}
                                                    onChange={handleUpdateInvoiceValue}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                {currencyObj.currency}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </LabelWrapper>
                                            <ValueDisplay
                                                label={t('Invoice value (with VAT)')}
                                                value={toLocaleNumber(invoice.valueWithVat, language, 2)}
                                                alias={currencyObj.currency}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-3">
                                            <ValueDisplay
                                                label={t('Invoice value in the reference currency')}
                                                value={toLocaleNumber(invoice.secondValue, language, 2)}
                                                alias={referenceCurrencyObj.currency}
                                            />

                                            <ValueDisplay
                                                label={t('Invoice value in the reference currency (with VAT)')}
                                                value={toLocaleNumber(invoice.secondValueWithVat, language, 2)}
                                                alias={referenceCurrencyObj.currency}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                        {/* 
                            Reversal type invoice - displayed only on reverse invoice  
                        */}
                        {invoice.type === 'INCOME' && invoice.incomeType === 'REVERSE' && invoice.value > 0 && (
                            <>
                                <InvoiceReversalComponent
                                    invoice={invoice}
                                    advanceInvoices={advanceInvoices}
                                    selectedAdvanceInvoices={selectedAdvanceInvoices}
                                    setSelectedAdvanceInvoices={setSelectedAdvanceInvoices}
                                    handleUpdateInvoiceValue={handleUpdateInvoiceValue}
                                />
                            </>
                        )}

                        {/* Invoice client dropdown component - displayed only on  income type invoice */}
                        {whenToDisplayClientOnIncome() && (
                            <div className="mb-6 flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <InvoiceClientDropdown
                                        clients={clients}
                                        clientOptions={clientOptions}
                                        invoiceClient={invoiceClient}
                                        setInvoiceClient={setInvoiceClient}
                                        resetMiddleFields={resetMiddleFields}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        selectedPartnerId={partners[selectedPartner]?.id ?? null}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={invoiceClient !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={invoiceClient !== null ? false : true}
                                            onClick={() => {
                                                setInvoiceClient(null);
                                                getNewCrmData(null, partners[selectedPartner]?.id ?? null);
                                                getNewProjects(null, partners[selectedPartner]?.id ?? null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-3">
                                    <InvoicePartnerDropdown
                                        partnersOptions={partnersOptions}
                                        selectedPartner={selectedPartner}
                                        setSelectedPartner={setSelectedPartner}
                                        resetMiddleFields={resetMiddleFields}
                                        partners={partners}
                                        selectedClientId={clients[invoiceClient]?.id ?? null}
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={selectedPartner !== null ? 'var(--error)' : 'var(--disabled)'}
                                            disabled={selectedPartner !== null ? false : true}
                                            onClick={() => {
                                                setSelectedPartner(null);
                                                getNewCrmData(clients[invoiceClient]?.id ?? null, null);
                                                getNewProjects(clients[invoiceClient]?.id ?? null, null);
                                                resetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Section with contracts and projects for the invoice */}
                        {canRenderContractAndProjectSelection() && (
                            <div className="mb-10 flex flex-row justify-start gap-5 lg:flex-col">
                                {displayCrmProjects && (
                                    <div className="flex flex-col gap-4">
                                        {/* Checkbox for the contracts */}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={invoiceOnAContract}
                                                    onChange={() => {
                                                        if (invoiceOnAProject && invoiceOnAContract === false) {
                                                            enqueueSnackbar(
                                                                t(
                                                                    'The invoice can be based on a contract or on a project, but not both!',
                                                                ),
                                                                {
                                                                    variant: 'error',
                                                                },
                                                            );
                                                            return;
                                                        }

                                                        if (invoiceOnAContract === true) {
                                                            setSelectedContract(null);
                                                            setSelectedProject(null);
                                                            setCheckedExpenses(defaultRevenuesAndExpensesValues);
                                                            setCheckedRevenues(defaultRevenuesAndExpensesValues);
                                                            getNewProjects(
                                                                clients[invoiceClient]?.id ?? null,
                                                                partners[selectedPartner]?.id ?? null,
                                                            );
                                                        }

                                                        setInvoiceOnAContract(!invoiceOnAContract);
                                                    }}
                                                    name="CRM contracts"
                                                    style={{
                                                        color: 'var(--primary-main)',
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <p className="user-select-none">
                                                    {t('Invoice based on a CRM contract or project')}
                                                </p>
                                            }
                                        />

                                        {/* Dropdown for CRM contracts */}
                                        {invoiceOnAContract ? (
                                            contractsOptions.length > 0 ? (
                                                <LabelWrapper label={t('CRM contracts')}>
                                                    <div className="flex max-w-min items-center gap-3">
                                                        <Dropdown
                                                            disabled={selectedCrmProject !== null}
                                                            variant="black"
                                                            options={contractsOptions}
                                                            placeholder={t('Choose the contract for the invoice')}
                                                            selectedOption={selectedContract}
                                                            setSelectedOption={(e) => {
                                                                setSelectedContract(e);
                                                            }}
                                                        />

                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedContract !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedContract !== null ? false : true}
                                                            onClick={() => setSelectedContract(null)}
                                                        />
                                                    </div>
                                                </LabelWrapper>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client has no contract yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            )
                                        ) : null}

                                        {invoiceOnAContract && invoiceClient ? (
                                            crmProjects.length > 0 ? (
                                                <LabelWrapper label={t('CRM projects')}>
                                                    <div className="flex max-w-min items-center gap-3">
                                                        <Dropdown
                                                            disabled={selectedContract !== null}
                                                            variant="black"
                                                            options={crmProjects.map((p) => p.name)}
                                                            placeholder={t('Choose the project for the invoice')}
                                                            selectedOption={selectedCrmProject}
                                                            setSelectedOption={(e) => {
                                                                setSelectedCrmProject(e);
                                                            }}
                                                        />

                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedCrmProject !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedCrmProject !== null ? false : true}
                                                            onClick={() => setSelectedCrmProject(null)}
                                                        />
                                                    </div>
                                                </LabelWrapper>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client has no project yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                )}

                                {displayPmProjects && (
                                    <div className="flex flex-col gap-4">
                                        <div>
                                            {/* Checkbox for projects */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={invoiceOnAProject}
                                                        onChange={() => {
                                                            if (invoiceOnAContract && invoiceOnAProject === false) {
                                                                enqueueSnackbar(
                                                                    t(
                                                                        'The invoice can be based on a contract or on a project, but not both!',
                                                                    ),
                                                                    {
                                                                        variant: 'error',
                                                                    },
                                                                );
                                                                return;
                                                            }
                                                            if (invoiceOnAProject === true) {
                                                                setSelectedContract(null);
                                                                setSelectedProject(null);
                                                                setCheckedExpenses(defaultRevenuesAndExpensesValues);
                                                                setCheckedRevenues(defaultRevenuesAndExpensesValues);
                                                                getNewCrmData(
                                                                    clients[invoiceClient]?.id ?? null,
                                                                    partners[selectedPartner]?.id ?? null,
                                                                );
                                                            }
                                                            setInvoiceOnAProject(!invoiceOnAProject);
                                                        }}
                                                        name="CRM contracts"
                                                        style={{
                                                            color: 'primary-main',
                                                        }}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <p className="user-select-none">
                                                        {t('Invoice based on a PM project')}
                                                    </p>
                                                }
                                            />
                                        </div>

                                        {/* Dropdown for invoice project */}
                                        {invoiceOnAProject &&
                                            (projects.map((project) => project.name).length > 0 ? (
                                                <div className="inline-block max-w-min">
                                                    <LabelWrapper label={t('Projects')}>
                                                        <Dropdown
                                                            variant="black"
                                                            options={projects.map((project) => project.name)}
                                                            placeholder={t('Choose the project for the invoice')}
                                                            selectedOption={selectedProject}
                                                            setSelectedOption={(e) => {
                                                                setSelectedProject(e);
                                                                getPMContracts(projects[e].id);
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                </div>
                                            ) : (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client or partner has no project yet! Create a new one or pick another client for the invoice!',
                                                    )}
                                                </div>
                                            ))}

                                        {selectedProject > -1 &&
                                            selectedProject !== null &&
                                            (contractsOptions.length > 0 ? (
                                                <div className="flex items-center gap-3">
                                                    <div className="inline-block max-w-min">
                                                        <LabelWrapper label={t('PM contracts')}>
                                                            <Dropdown
                                                                variant="black"
                                                                options={contractsOptions}
                                                                placeholder={t('Choose the contract for the invoice')}
                                                                selectedOption={selectedContract}
                                                                setSelectedOption={(e) => {
                                                                    setSelectedContract(e);
                                                                }}
                                                            />
                                                        </LabelWrapper>
                                                    </div>

                                                    <div className="mt-5 flex-shrink-0">
                                                        <ActionButton
                                                            icon={<DeleteIcon />}
                                                            color={
                                                                selectedContract !== null
                                                                    ? 'var(--error)'
                                                                    : 'var(--disabled)'
                                                            }
                                                            disabled={selectedContract !== null ? false : true}
                                                            onClick={() => {
                                                                setSelectedContract(null);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ) : !viewOnly ? (
                                                <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                                                    {t(
                                                        'The selected client or partner has no contract yet! If you want the invoice to be on a contract you have to create one first!',
                                                    )}
                                                </div>
                                            ) : null)}
                                    </div>
                                )}
                            </div>
                        )}

                        {invoice.type === 'REVERSAL' && (
                            <div className="mb-10">
                                <InvoiceReversalComponent
                                    invoice={invoice}
                                    advanceInvoices={advanceInvoices}
                                    selectedAdvanceInvoices={selectedAdvanceInvoices}
                                    setSelectedAdvanceInvoices={setSelectedAdvanceInvoices}
                                    handleUpdateInvoiceValue={handleUpdateInvoiceValue}
                                />
                            </div>
                        )}

                        {canRenderExpenses() && (
                            <Fragment>
                                <InvoiceExpenseBudget
                                    checkedExpenses={checkedExpenses}
                                    setCheckedExpenses={setCheckedExpenses}
                                    isEdit={invoiceToEdit ? true : false}
                                    contractData={{
                                        id: contracts[selectedContract]?.id ?? null,
                                        canFetchContract: invoiceOnAProject
                                            ? false
                                            : invoiceOnAContract && selectedContract !== null
                                              ? true
                                              : false,
                                    }}
                                    viewOnly={viewOnly}
                                    isBudgetCase={isBudgetCase}
                                    setIsBudgetCase={setIsBudgetCase}
                                    exchangeRate={invoice.exchangeRate}
                                    disableInputs={disableInputs}
                                />
                                {checkedExpenses.list.length > 0 && (
                                    <h4
                                        className={`mb-10 max-w-2xl ${
                                            validExpenseValue ? 'text-success' : 'text-error'
                                        }`}
                                    >
                                        {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                            currencyObj.currency
                                        } ${t(
                                            'must be equal to the sum of values of the checked expenses',
                                        )}: ${toLocaleNumber(checkedExpensesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                    </h4>
                                )}
                            </Fragment>
                        )}

                        {canRenderRevenue() && (
                            <Fragment>
                                <InvoiceRevenueBudget
                                    checkedRevenues={checkedRevenues}
                                    setCheckedRevenues={setCheckedRevenues}
                                    isEdit={invoiceToEdit ? true : false}
                                    viewOnly={viewOnly}
                                    exchangeRate={invoice.exchangeRate}
                                    disableInputs={disableInputs}
                                />
                                {checkedRevenues.list.length > 0 && (
                                    <h4
                                        className={`mb-10 max-w-2xl ${
                                            validRevenueValue ? 'text-success' : 'text-error'
                                        }`}
                                    >
                                        {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                            currencyObj.currency
                                        } ${t(
                                            'must be equal to the sum of values of the checked revenues',
                                        )}: ${toLocaleNumber(checkedRevenuesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                    </h4>
                                )}
                            </Fragment>
                        )}

                        {/* 
                    Reversal type invoice - displayed only on reverse invoice  
                */}

                        {/* The final steps with the info of the invoice */}
                        {whenToDisplayLastInfoOfInvoice() && (
                            <div className="mb-28">
                                <div className="mb-10 flex max-w-4xl gap-10 sm:flex-col">
                                    <LabelWrapper label={t('Invoice name')}>
                                        <TextField
                                            placeholder={t('Invoice name')}
                                            value={invoice.name}
                                            onChange={(e) => editInvoiceInfo('name', e.target.value)}
                                        />
                                    </LabelWrapper>

                                    <LabelWrapper label={t('Invoice number')}>
                                        <TextField
                                            placeholder={'F1234'}
                                            value={invoice.billingNumber}
                                            onChange={(e) => editInvoiceInfo('billingNumber', e.target.value)}
                                        />
                                    </LabelWrapper>
                                </div>

                                <div className="flex justify-start">
                                    <div className="inline-block">
                                        <LabelWrapper label={t('Issue date of the invoice')}>
                                            <DatePicker
                                                date={invoice.issueDate}
                                                setDate={(e) => editInvoiceInfo('issueDate', e)}
                                            />
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Button for the add of the invoice and button for the reset of the invoice*/}
                        {whenToDisplaySubmitButton() && !viewOnly && (
                            <div className="mt-10 flex justify-start gap-5">
                                <Button
                                    className={styles.success}
                                    startIcon={<i className="fa-solid fa-file-invoice" />}
                                    onClick={handleInvoiceCreationOrUpdate}
                                    disabled={disableInputs}
                                >
                                    {invoiceId ? t('Update the invoice') : t('Add the invoice')}
                                </Button>

                                {!invoiceId && (
                                    <Button
                                        className={styles.error}
                                        startIcon={<i className="fa-solid fa-file-invoice" />}
                                        onClick={() => resetInvoiceInfo('all')}
                                    >
                                        {t('Reset the invoice')}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceForm;

InvoiceForm.propTypes = {
    invoiceToEdit: PropTypes.object,
    viewOnly: PropTypes.bool,
    isValid: PropTypes.bool,
    setInvoiceDetails: PropTypes.func,
    setInvoiceFile: PropTypes.func,
    displayCrmProjects: PropTypes.bool,
    displayPmProjects: PropTypes.bool,
};

InvoiceForm.defaultProps = {
    displayCrmProjects: true,
    displayPmProjects: true,
};

/*
    REASON: Small gallery that appears on the estate row when expanded / when creating an estate
*/

import React, { useContext, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { generateUUID } from 'utils';
import API from 'utils/axios';

const GalleryEditable = (props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { gallery, setGallery, setOpen, photoClickCallback, view, type, onEstate } = props;
    const [deleting, setDeleting] = useState(false);

    const inputRef = useRef(null);

    const globalContext = useContext(GlobalContext);

    const handleFileUpload = async (e) => {
        if (!inputRef.current) return;

        const typeValidation = ['image/jpeg', 'image/png'];

        if (type === 'Uni-select') {
            const file = e.target.files[0];

            if (typeValidation.find((type) => type === file.type)) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = (event) => {
                    try {
                        setGallery([
                            ...gallery,
                            { id: generateUUID(), url: event.target.result, blob: file, name: file.name },
                        ]);
                    } catch (err) {
                        console.error(err);
                        enqueueSnackbar(t('Could not upload file! Try again!'), { variant: 'error' });
                    }
                };
            } else {
                enqueueSnackbar(t('Wrong file type! Only JPEG, PNG formats are supported!'), { variant: 'error' });
            }
        } else {
            const { files } = e.target;
            const len = files.length;
            const addedFiles = [];

            if (Array.from(files).every((f) => typeValidation.find((type) => type === f.type))) {
                for (let i = 0; i < len; i++) {
                    const file = files[i];
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = (event) => {
                        try {
                            addedFiles.push({
                                id: generateUUID(),
                                url: event.target.result,
                                blob: file,
                                name: file.name,
                            });

                            if (addedFiles.length === len) {
                                setGallery([...gallery, ...addedFiles]);
                            }
                        } catch {
                            enqueueSnackbar(t('Could not upload file/s! Try again!'), { variant: 'error' });
                        }
                    };
                }
            } else {
                enqueueSnackbar(t('Wrong file type! Only JPEG, PNG formats are supported!'), { variant: 'error' });
            }
        }
    };

    const deleteImageOnFrontEnd = (id) => {
        setGallery(gallery.filter((p) => p.id !== id));
    };

    const deleteImage = async (id) => {
        console.log('sterge imagine pe o entitate din configurator', id);
        setDeleting(true);
        try {
            // const canDeleteOnBack = !gallery.find((image) => image.id === id).blob;

            // if (canDeleteOnBack)
            // await API.delete('/some_image_route', {
            //     data: { id },
            // });

            deleteImageOnFrontEnd(id);
        } catch (err) {
            console.error(err);
        } finally {
            setDeleting(false);
        }
    };

    const deleteImageOnEstate = async (id) => {
        console.log('sterge imagine pe estate', id);
        setDeleting(true);
        try {
            const canDeleteOnBack = !gallery.find((image) => image.id === id).blob;

            if (canDeleteOnBack)
                await API.delete('/deleteImmobileImage', {
                    data: { id },
                });

            deleteImageOnFrontEnd(id);
        } catch (err) {
            console.error(err);
        } finally {
            setDeleting(false);
        }
    };

    const deletePhoto = (id) => {
        if (view) return;

        if (deleting) {
            enqueueSnackbar(t('A photo is already being deleted! Wait until it finishes!'), { variant: 'error' });
            return;
        }

        if (onEstate) {
            deleteImageOnEstate(id);
        } else {
            deleteImage(id);
        }
    };

    return view && gallery.length === 0 ? (
        <div>
            <p className="text-base text-dark-text">{t('No photos uploaded at this moment!')}</p>
        </div>
    ) : (
        <div className="h-full w-full overflow-auto">
            <div
                className={`grid h-auto w-full gap-2 rounded-md bg-layout-transparent p-2 2xl:gap-1 2xl:p-1 xl:gap-1.5 xl:p-1.5 ${
                    !view ? 'md:gap-y-8' : ''
                }`}
                style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(7rem, max-content))' }}
            >
                {/* 
                    All Photos
                */}
                {gallery.map((photo, index) => (
                    <div className="h-full w-full md:flex md:flex-col md:gap-2" key={photo.id}>
                        {/* Image display */}

                        <div
                            className="group relative h-full w-full flex-grow cursor-pointer"
                            style={{ aspectRatio: '1 / 1' }}
                            onClick={() => {
                                photoClickCallback(index);
                                setOpen(true);
                            }}
                        >
                            <img
                                src={photo.url}
                                alt="Gallery image"
                                className="max-h-full w-full rounded-sm object-cover"
                            />

                            {/*
                                Overlay and square shape div
                            */}

                            <div
                                className="absolute left-0 top-0 h-full w-full rounded-sm opacity-0 transition-opacity group-hover:opacity-100"
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, .5)',
                                }}
                            />

                            {/* 
                                Photo inner content
                            */}
                            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-sm opacity-0 transition-opacity group-hover:opacity-100 md:hidden">
                                {!view && (
                                    <BasicTooltip tip={t('Delete')}>
                                        <ActionButton
                                            icon={<DeleteIcon className="text-main-text" />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                globalContext.setGlobalModalChildren(
                                                    <div>
                                                        <div className="flex-col">
                                                            <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                {t('Are you sure you want to')}
                                                                <br />
                                                                {t('delete this photo?')}
                                                            </p>
                                                            <div className="flex items-center justify-center">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        globalContext.setGlobalModalOpen(false);
                                                                        deletePhoto(photo.id);
                                                                    }}
                                                                >
                                                                    {t('Yes')}
                                                                </Button>
                                                                <div className="w-4" />
                                                                <Button
                                                                    onClick={() =>
                                                                        globalContext.setGlobalModalOpen(false)
                                                                    }
                                                                >
                                                                    {t('No')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>,
                                                );
                                                globalContext.setGlobalModalOpen(true);
                                            }}
                                        />
                                    </BasicTooltip>
                                )}
                            </div>
                        </div>

                        {!view && (
                            <div className="hidden w-full justify-center md:flex">
                                <Button
                                    color="primary"
                                    style={{ minWidth: '50px' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        globalContext.setGlobalModalChildren(
                                            <div>
                                                <div className="flex-col">
                                                    <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                        {t('Are you sure you want to')}
                                                        <br />
                                                        {t('delete this photo?')}
                                                    </p>
                                                    <div className="flex items-center justify-center">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                globalContext.setGlobalModalOpen(false);
                                                                deletePhoto(photo.id);
                                                            }}
                                                        >
                                                            {t('Yes')}
                                                        </Button>
                                                        <div className="w-4" />
                                                        <Button onClick={() => globalContext.setGlobalModalOpen(false)}>
                                                            {t('No')}
                                                        </Button>
                                                    </div>
                                                </div>
                                                ,
                                            </div>,
                                        );
                                        globalContext.setGlobalModalOpen(true);
                                    }}
                                >
                                    {t('Delete')}
                                </Button>
                            </div>
                        )}
                    </div>
                ))}

                {/* Add Photo Button */}
                {!view && (
                    <div
                        className="flex h-full w-full cursor-pointer items-center justify-center rounded-sm bg-layout-transparent transition-colors hover:bg-layout-transparent-dark"
                        style={{ aspectRatio: '1 / 1' }}
                        onClick={() => (inputRef.current ? inputRef.current.click() : null)}
                    >
                        <AddIcon className="text-buttons-text" style={{ fontSize: '2rem' }} />
                    </div>
                )}
            </div>

            <input
                id="gallery-photo-upload"
                name="gallery-photo-upload"
                type="file"
                onChange={handleFileUpload}
                ref={inputRef}
                multiple
                style={{
                    display: 'none',
                    visibility: 'hidden',
                    opacity: 0,
                    pointerEvents: 'none',
                }}
            />
        </div>
    );
};

GalleryEditable.propTypes = {
    entity: PropTypes.string,
    gallery: PropTypes.array,
    setGallery: PropTypes.func,
    setOpen: PropTypes.func,
    photoClickCallback: PropTypes.func,
    view: PropTypes.bool,
    type: PropTypes.string,
    onEstate: PropTypes.bool,
};

GalleryEditable.defaultProps = {
    entity: 'estate',
    gallery: [],
    setGallery: () => null,
    setOpen: () => null,
    photoClickCallback: () => null,
    view: false,
    type: 'Uni-select',
    onEstate: false,
};

export default GalleryEditable;

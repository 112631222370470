import React from 'react';

import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { generateUUID } from 'utils';

const RiemLegend = ({ riem, id }) => {
    return (
        <div
            id={id ?? generateUUID()}
            className="flex w-max flex-col gap-2 rounded-sm bg-white p-4"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(riem.legend.content) }}
        />
    );
};

RiemLegend.propTypes = {
    riem: PropTypes.object,
    id: PropTypes.string,
};

RiemLegend.defaultProps = {
    riem: {},
    id: null,
};

export default RiemLegend;

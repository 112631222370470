import React, { cloneElement } from 'react';

import { Box } from 'lucide-react';

import PropTypes from 'prop-types';

const PipelineTag = ({ tag }) => {
    const { icon, color, backgroundColor, borderColor } = tag;

    return (
        <div
            className="flex h-14 w-14 flex-shrink-0 items-center justify-center gap-2 rounded-xl border-2"
            style={{
                backgroundColor,
                borderColor,
            }}
        >
            {cloneElement(icon, {
                style: {
                    color,
                },
            })}
        </div>
    );
};

PipelineTag.propTypes = {
    tag: PropTypes.object,
};

PipelineTag.defaultProps = {
    tag: {
        icon: <Box />,
        label: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineTag;

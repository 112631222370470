import React, { useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import { Button, TextField } from '@material-ui/core';

import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as yup from 'yup';

const PMTaskManagementComment = (props) => {
    const { departmentId, partnerId, tenantUsers, closeModal } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();

    const { user } = useContext(UserContext);
    const taskManagementContext = useContext(TaskManagementContext);

    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    const [responsible, setResponsible] = useState([]);

    /**
     *
     * @param {*} usersList The list of the users index from an dropdown
     * @returns An array with users ids
     */
    const getUsersIds = (usersList) => {
        return usersList.map((user) => tenantUsers[user].id);
    };

    let commentSchema = yup.object().shape({
        responsible: yup
            .array()
            .typeError(t('The comment must have at least one responsible!'))
            .min(1, t('The comment must have at least one responsible!'))
            .required(t('The comment must have at least one responsible!')),
        comment: yup.string().trim().typeError(t('The comment is required!')).required(t('The comment is required!')),
        title: yup
            .string()
            .trim()
            .typeError(t('Title of the comment is required!'))
            .required(t('Title of the comment is required!')),
    });

    const createPMTaskManagementComment = async () => {
        if (taskManagementContext.isCreating) return;

        const reqBody = { title, comment, responsible };

        try {
            await commentSchema.validate(reqBody);

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description: comment,
                    note: '-',
                    actionType: 'COMMENT',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible),
                    supervisor: [],
                    watcher: [],
                    pmDepartmentId: departmentId,
                    pmProjectPlanId: projectId,
                    partnerId: partnerId !== null ? partnerId : undefined,
                };

                formData.append('data', JSON.stringify(reqBody));
                taskManagementContext.submitTaskManagementActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    return departmentId ? (
        <form>
            <div className="mb-3">
                <TextField placeholder={t('Comment title')} value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>

            <div className="mb-5">
                <TextField
                    placeholder={t('Write a comment...')}
                    multiline
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                />
            </div>

            <div className="mb-10 w-1/3 sm:w-full">
                <LabelWrapper label={t('Responsible')}>
                    <MultiDropdown
                        variant="black"
                        options={tenantUsers.map((user) => user.profile?.name)}
                        placeholder={t('Choose user')}
                        selectedOptions={responsible}
                        setSelectedOptions={(i) => {
                            if (responsible?.indexOf(i) > -1) {
                                setResponsible(responsible.filter((opt) => opt !== i));
                            } else {
                                setResponsible([...responsible, i]);
                            }
                        }}
                    />
                </LabelWrapper>
            </div>

            <div className="flex gap-3">
                <Button color="secondary" startIcon={<AddIcon />} onClick={createPMTaskManagementComment}>
                    {t('Add comment')}
                </Button>

                <Button
                    color="secondary"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    {t('Cancel')}
                </Button>
            </div>
        </form>
    ) : (
        <></>
    );
};

PMTaskManagementComment.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    closeModal: PropTypes.func,
    partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    beneficiaryCheck: PropTypes.bool,
};

PMTaskManagementComment.defaultProps = {
    departmentId: null,
    tenantUsers: [],
    activity: null,
    closeModal: () => null,
    partnerId: null,
    beneficiaryCheck: false,
};

export default PMTaskManagementComment;

import React, { cloneElement } from 'react';

import PropTypes from 'prop-types';

const PipelineItemTitle = ({ itemProps, item }) => {
    const { icon, render } = itemProps.title;

    return (
        <div className="flex justify-between gap-4">
            <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-xl border border-layout-transparent bg-gradient-to-tr from-gradient-primary-dark to-gradient-primary-light">
                {cloneElement(icon, { className: 'w-12 h-12' })}
            </div>
            <p className="my-auto w-full break-all text-left text-2xl font-semibold leading-6">{render(item)}</p>
        </div>
    );
};

PipelineItemTitle.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    column: PropTypes.object,
};

PipelineItemTitle.defaultProps = {
    itemProps: {},
    item: {},
    column: {
        key: 'DEFAULT',
        title: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineItemTitle;

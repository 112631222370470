import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { calculateTotal, calculateVATValue, formatPositiveNumber, toLocaleNumber } from 'utils/index';

const VariableRow = ({ variable, deleteVar, editVariable, unitList, disabled }) => {
    const { t } = useTranslation();
    const { currencyObj, language } = useContext(GlobalContext);

    const [variableRow, setVariableRow] = useState(false);

    useEffect(() => {
        if (variable.amount === undefined)
            editVariable(variable.id, 'amount', variable.quantity === undefined ? 1 : variable.quantity);
    }, []);

    return (
        <div>
            <div className="relative inline-flex w-full justify-between gap-4 border-t border-layout-transparent-dark bg-layout-transparent p-5 hover:bg-layout-transparent-dark sm:flex-col sm:gap-6">
                <div className="flex gap-4 sm:justify-center">
                    <div className="w-24">
                        {variable.price.type !== 'BASE_PROCENT_AND_VARIABLE' && (
                            <TextField
                                name="cantitate"
                                label={t('Amount')}
                                placeholder={1}
                                inputProps={{ min: 1 }}
                                value={variable.amount}
                                onChange={(e) =>
                                    editVariable(variable.id, 'amount', formatPositiveNumber(e.target.value))
                                }
                                disabled={disabled}
                            />
                        )}
                        {variable.custom && (
                            <div className="pt-1 text-center text-sm text-yellow-400">{t('CUSTOM')}</div>
                        )}
                    </div>

                    <div className="flex flex-col sm:flex-grow">
                        <h4 className="break-all pt-3 text-xl">{variable.name}</h4>
                        <h3 className="break-all text-lg text-dark-text" style={{ hyphens: 'auto' }}>
                            {variable.description}
                        </h3>
                    </div>
                </div>

                <div className="mr-10 flex gap-2 sm:mr-0 sm:justify-center">
                    <div className="flex gap-2 sm:flex-grow">
                        {variable.price.type !== 'SIMPLE' && (
                            <LabelWrapper label={t('Percent')} noPadding>
                                <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                    {toLocaleNumber(variable.price.priceProcent, language, 2)} %
                                </div>
                            </LabelWrapper>
                        )}
                        <LabelWrapper label={t('Unit price')} noPadding>
                            <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                {toLocaleNumber(variable.price.pricePerUnit, language, 2)} {currencyObj.currency}
                            </div>
                        </LabelWrapper>
                        <LabelWrapper label={t('Price with VAT')} noPadding>
                            <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                {toLocaleNumber(
                                    (variable.amount ? variable.amount : variable.quantity) *
                                        calculateTotal(
                                            calculateVATValue(
                                                variable.price.pricePerUnit,
                                                variable.price.procentualVAT,
                                            ),
                                            variable.price.pricePerUnit,
                                        ),
                                    language,
                                    2,
                                )}{' '}
                                {currencyObj.currency}
                            </div>
                        </LabelWrapper>
                    </div>
                </div>

                <div
                    className="absolute right-4 top-2/4 -translate-y-2/4 transform cursor-pointer"
                    onClick={() => setVariableRow(!variableRow)}
                >
                    <KeyboardArrowDownIcon
                        fontSize="medium"
                        style={{
                            color: 'var(--main-text)',
                            transition: 'transform .2s ease',
                        }}
                        className={`transform ${variableRow ? 'rotate-180' : 'rotate-0'}`}
                    />
                </div>
            </div>
            {variableRow && (
                <>
                    <div className="inline-flex w-full gap-4 bg-layout-transparent p-5 sm:flex-col">
                        <LabelWrapper label={t('Amount/Percent')} noPadding>
                            <div className="pointer-events-none rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                {variable.price.type === 'SIMPLE'
                                    ? t('Amount')
                                    : variable.price.type === 'BASE_PROCENT'
                                      ? t('Percentage of basic item')
                                      : variable.price.type === 'BASE_PROCENT_AND_VARIABLE'
                                        ? t('Percentage of the entire article(article + all variables)')
                                        : null}
                            </div>
                        </LabelWrapper>

                        <LabelWrapper label={'UM'} noPadding>
                            <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                {isNaN(variable.price.unit) === true
                                    ? variable.price.unit
                                    : unitList[variable.price.unit]}
                            </div>
                        </LabelWrapper>

                        <LabelWrapper label={t('VAT rate')} noPadding>
                            <div className="pointer-events-none rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                {variable.price.procentualVAT === null ? 0 : variable.price.procentualVAT} %
                            </div>
                        </LabelWrapper>

                        <LabelWrapper label={t('VAT value')} noPadding>
                            <div className="pointer-events-none rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                {(
                                    (variable.amount ? variable.amount : variable.quantity) *
                                    calculateVATValue(variable.price.pricePerUnit, variable.price.procentualVAT)
                                ).toFixed(2)}{' '}
                                {currencyObj.currency}
                            </div>
                        </LabelWrapper>
                    </div>
                    <div className="inline-flex w-full bg-layout-transparent pb-5 pl-5 pr-5">
                        <h3 className="text-base text-dark-text">{t('Value added above the base price')}</h3>
                    </div>
                </>
            )}

            {variableRow && !disabled && (
                <div className="left-0 top-full flex w-full justify-end rounded-bl-md rounded-br-md border-t border-layout-transparent-dark bg-layout-transparent">
                    <div className="text-main-text">
                        <p
                            className="ml-2 flex cursor-pointer items-center p-4 text-main-text transition-colors hover:text-secondary-main"
                            style={{ userSelect: 'none' }}
                            onClick={deleteVar}
                        >
                            <CloseIcon className="mr-2 text-base" />
                            {t('Delete variable')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

VariableRow.propTypes = {
    variable: PropTypes.array,
    deleteVar: PropTypes.func,
    editVariable: PropTypes.func,
    unitList: PropTypes.array,
    disabled: PropTypes.bool,
};

VariableRow.defaultProps = {
    variable: null,
    deleteVar: () => null,
    editVariable: () => null,
    unitList: [],
    disabled: false,
};

export default VariableRow;

import React from 'react';

import PropTypes from 'prop-types';

const TagSmall = ({ children, style }) => {
    return (
        <div
            className="flex h-10 items-center justify-center rounded-xl border border-layout-transparent bg-layout-transparent px-3"
            style={{ ...style }}
        >
            <p className="text-lg font-light leading-4 opacity-60 transition-colors">{children}</p>
        </div>
    );
};

TagSmall.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
};

TagSmall.defaultProps = {
    children: null,
    style: {},
};

export default TagSmall;

import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BasicTooltip from 'components/shared/basic-tooltip';
import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import { RiemContext } from '../riem';
import RiemRowForm from './riem-row-form';

const DEFAULT_ITEM = {
    id: null,
    stage: '',
    identifiedDescription: '',
    identifiedScore: 0,
    identifiedProbability: 0,
    identifiedTotal: 0,
    measures: '',
    residualDescription: '',
    residualScore: 0,
    residualProbability: 0,
    residualTotal: 0,
    identificationDate: new Date().toDateString(),
    lastUpdate: new Date().toDateString(),
};

const totalCellsBackgroundClasses = (value) => {
    if (value < 12) return 'bg-success';
    if (value >= 12 && value <= 20) return 'bg-warning';
    if (value > 20) return 'bg-error';
};

const RiemItem = ({ index, item, riem, renderType, height }) => {
    const { t } = useTranslation();
    const {
        canEdit,
        isSaving,
        displayedRiem,
        isOperatingOnRowAt,
        setIsOperatingOnRowAt,
        onRemoveItem,
        onAddItem,
        onMoveItem,
    } = useContext(RiemContext);

    const [isMounted, setIsMounted] = useState(false);
    const [width] = useScreenSizes();

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isOperatingOnRowAt === index) return <RiemRowForm index={index} item={item} riem={riem} />;

    return (
        <div
            role="row"
            className={`col-span-full grid ${index % 2 === 0 ? 'bg-white' : 'bg-disabled'}`}
            style={{
                gridTemplateColumns: 'subgrid',
                height: height ?? '',
            }}
        >
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {index + 1}
                </p>
            </div>
            <div role="cell" className="flex border border-black">
                <p className="w-full break-words px-0.5 py-1 text-xl font-bold text-pdf-text">{item.stage}</p>
            </div>
            <div role="cell" className="flex border border-black">
                <p className="w-full break-words px-0.5 py-1 text-xl font-bold text-pdf-text">
                    {item.identifiedDescription}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.identifiedScore}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.identifiedProbability}
                </p>
            </div>
            <div
                role="cell"
                className={`flex items-center justify-center border border-black text-center ${totalCellsBackgroundClasses(
                    item.identifiedTotal,
                )}`}
            >
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.identifiedTotal}
                </p>
            </div>
            <div role="cell" className="flex border border-black">
                <p className="w-full break-words px-0.5 py-1 text-xl font-bold text-pdf-text">{item.measures}</p>
            </div>
            <div role="cell" className="flex border border-black">
                <p className="w-full break-words px-0.5 py-1 text-xl font-bold text-pdf-text">
                    {item.residualDescription}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.residualScore}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.residualProbability}
                </p>
            </div>
            <div
                role="cell"
                className={`flex items-center justify-center border border-black ${totalCellsBackgroundClasses(
                    item.residualTotal,
                )}`}
            >
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {item.residualTotal}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {formatDate(item.identificationDate, true, true)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center border border-black">
                <p className="w-full break-words px-0.5 py-1 text-center text-xl font-bold text-pdf-text">
                    {formatDate(item.lastUpdate, true, true)}
                </p>
            </div>

            {!renderType &&
                isMounted &&
                createPortal(
                    <div
                        className="grid h-full w-max grid-cols-3 gap-2 sm:grid-cols-2"
                        style={{
                            height: 'max-content',
                        }}
                    >
                        <div>
                            <BasicTooltip
                                tip={
                                    isOperatingOnRowAt !== null
                                        ? t("This action can't be performed while editing another row")
                                        : t('Edit row')
                                }
                                disabled={!canEdit}
                            >
                                <ActionButton
                                    icon={<EditIcon />}
                                    color="var(--success)"
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => setIsOperatingOnRowAt(index)}
                                    disabled={!canEdit || isSaving || isOperatingOnRowAt !== null}
                                />
                            </BasicTooltip>
                        </div>
                        <div>
                            <BasicTooltip tip={t('Move row up')} disabled={!canEdit || index === 0}>
                                <ActionButton
                                    icon={<ArrowUpwardIcon />}
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => onMoveItem(index, index - 1)}
                                    disabled={!canEdit || isSaving || index === 0}
                                />
                            </BasicTooltip>
                        </div>
                        <div>
                            <BasicTooltip
                                tip={
                                    isOperatingOnRowAt !== null
                                        ? t("This action can't be performed while editing another row")
                                        : displayedRiem !== 0
                                          ? t('You can add new rows only on the main language table')
                                          : t('Add row above')
                                }
                                disabled={!canEdit}
                            >
                                <ActionButton
                                    icon={<ExpandLessIcon />}
                                    color="var(--success)"
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => {
                                        onAddItem(DEFAULT_ITEM, index);
                                        setIsOperatingOnRowAt(index);
                                    }}
                                    disabled={
                                        !canEdit || isSaving || isOperatingOnRowAt !== null || displayedRiem !== 0
                                    }
                                />
                            </BasicTooltip>
                        </div>
                        <div className="sm:col-start-2 sm:row-start-1">
                            <BasicTooltip tip={t('Delete row')} disabled={!canEdit}>
                                <ActionButton
                                    icon={<DeleteIcon />}
                                    color="var(--error)"
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => onRemoveItem(index)}
                                    disabled={!canEdit || isSaving}
                                />
                            </BasicTooltip>
                        </div>
                        <div className="sm:col-start-2 sm:row-start-2">
                            <BasicTooltip
                                tip={t('Move row down')}
                                disabled={!canEdit || index === riem.items.length - 1}
                            >
                                <ActionButton
                                    icon={<ArrowDownwardIcon />}
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => onMoveItem(index, index + 1)}
                                    disabled={!canEdit || isSaving || index === riem.items.length - 1}
                                />
                            </BasicTooltip>
                        </div>
                        <div>
                            <BasicTooltip
                                tip={
                                    isOperatingOnRowAt !== null
                                        ? t("This action can't be performed while editing another row")
                                        : displayedRiem !== 0
                                          ? t('You can add new rows only on the main language table')
                                          : t('Add row below')
                                }
                                disabled={!canEdit}
                            >
                                <ActionButton
                                    icon={<ExpandMoreIcon />}
                                    color="var(--success)"
                                    size={width > 600 ? 9 : 13}
                                    onClick={() => {
                                        onAddItem(DEFAULT_ITEM, index + 1);
                                        setIsOperatingOnRowAt(index + 1);
                                    }}
                                    disabled={
                                        !canEdit || isSaving || isOperatingOnRowAt !== null || displayedRiem !== 0
                                    }
                                />
                            </BasicTooltip>
                        </div>
                    </div>,
                    document.getElementById(`riem-actions-container-${index}`),
                )}
        </div>
    );
};

RiemItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    riem: PropTypes.object,
    renderType: PropTypes.string,
    height: PropTypes.string,
};

RiemItem.defaultProps = {
    item: null,
    index: 0,
    riem: {},
    renderType: null,
    height: null,
};

export default RiemItem;

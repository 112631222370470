import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';

import { Revenues } from 'api/Expenses_Revenues';
import BasicTooltip from 'components/shared/basic-tooltip';
import DocumentTemplate from 'components/shared/document-template/document-template';
import Loading from 'components/shared/loading';
import OfferContext from 'contexts/OfferContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, Tabs } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import API from 'utils/axios';
import { getCompanyVAT } from 'utils/getterFunctions';
import { errorHandling } from 'utils/index';
import * as yup from 'yup';

import PDFPreview from './offer-pdf-preview';
import { articlesInfoForPDF, sipInfoForPDF } from './pdInfo-functions';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--main-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const OfferPDF = ({ viewOnly }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [milestones, setMilestones] = useState([]);

    const { offerContext, editOfferContext } = useContext(OfferContext);

    const [pageNumber, setPageNumber] = useState(1);

    const [file, setFile] = useState(null);

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isStandard, setIsStandard] = useState(true);

    const [docTemplates, setDocTemplates] = useState([]);

    const [activeTemplateTab, setActiveTemplateTab] = useState(offerContext.offer.isPersonalized ? 1 : 0);
    const [templateData, setTemplateData] = useState(null);
    const templateTabs = [t('Classic offer'), t('Personalized offer')];

    const [previewPDFOffer, setPreviewPDFOffer] = useState(false);

    const handleTemplateChange = (e) => {
        setSelectedTemplate(e);
    };

    const schema = yup.object().shape({
        elements: yup.array().of(
            yup.object().shape({
                value: yup.mixed().test('validValue', t('Every element must have content'), (value, context) => {
                    const { itemType } = context.parent;

                    if (!value === true) return false;
                    else if (Array.isArray(value)) return value.length !== 0;
                    else if (typeof value === 'object') {
                        try {
                            if (itemType === 'table') {
                                return yup
                                    .object()
                                    .shape({
                                        columns: yup.array().min(1).required(),
                                        rows: yup.array().min(1).required(),
                                    })
                                    .validateSync(value);
                            }

                            if (itemType === 'image') {
                                return yup
                                    .object()
                                    .shape({
                                        url: yup.string().typeError().required(),
                                    })
                                    .validateSync(value);
                            }

                            return true;
                        } catch (error) {
                            console.error(error);
                            return false;
                        }
                    }

                    return true;
                }),
                entity: yup.array().of(
                    yup.object().shape({
                        entityId: yup
                            .string()
                            .nullable()
                            .test('validEntity', t("One element doesn't have the selected entity"), (id, context) => {
                                const { itemType, purpose } = context.parent;

                                try {
                                    if (purpose === 'milestone' || purpose === 'milestones' || itemType === 'offer') {
                                        return yup.string().typeError().validateSync(id);
                                    }

                                    return true;
                                } catch (error) {
                                    console.error(error);
                                    return false;
                                }
                            }),
                    }),
                ),
            }),
        ),
    });

    /**
     * The function `generateTemplateRequestData` processes template data by filtering elements and
     * columns, handling images and files, and validating the data structure.
     * @returns {undefined}
     */
    const generateTemplateRequestData = async () => {
        try {
            const newElements = structuredClone(templateData.elements);
            const newColumns = structuredClone(templateData.columns);

            for (const key of Object.keys(newColumns)) {
                const column = newColumns[key];
                const isMainColumn = key.includes('column-main');

                //? If the column is not active we delete all elements from it, except the elements from the first column that are not multi language
                if (!column.isActive) {
                    for (const elementId of column.elementsIds) {
                        const element = newElements[elementId];

                        if (isMainColumn && !element.isMultiLanguage && !element.flagDisabled) continue;

                        delete newElements[elementId];
                    }

                    delete newColumns[key];
                }
                //? Otherwise we only delete the deactivated elements
                else {
                    for (let i = 0; i < column.elementsIds.length; i++) {
                        const elementId = column.elementsIds[i];

                        if (newElements[elementId].flagDisabled) {
                            delete newElements[elementId];
                            column.elementsIds = column.elementsIds.filter((eI) => eI !== elementId);
                        }
                    }
                }
            }

            const newColumnOrder = structuredClone(templateData.columnOrder).filter((column) =>
                Object.keys(newColumns).includes(column),
            );

            await schema.validate({
                elements: Object.values(newElements).filter((element) => {
                    if (element.id.includes('column-main')) return true;
                    return element.isMultiLanguage;
                }),
            });

            //? The images array holds the images that will be processed by multer
            const images = [];
            //? The filesMetadata array holds the information about each image, such as the element id or the position in the gallery
            const filesMetadata = [];
            for (const key of Object.keys(newElements)) {
                const element = newElements[key];

                if (element.itemType === 'image') {
                    //? If we don't have a new image, we only assign the previews value
                    if (!element.value.blob) {
                        element.value = { blob: null, url: element.value.url };
                        element.entity = element.entity.map((entry) => ({
                            ...entry,
                            data: entry.data,
                        }));
                        continue;
                    }

                    //? If we have a new image, we create a new image and filesMetadata entry
                    const file = element.value.blob;
                    images.push(file);
                    filesMetadata.push({
                        id: element.id,
                        position: null,
                    });
                    //? We set the value to null, because we can't send images (base64) and blobs as JSON.
                    //? We will set the url back on the backend after the upload to S3Bucket.
                    element.value = { blob: null, url: null };
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                } else if (element.itemType === 'gallery') {
                    const newElementValue = [];

                    for (let i = 0; i < element.value.length; i++) {
                        const value = element.value[i];
                        //? If we don't have a new image, we only assign the previews value
                        if (!value.blob) {
                            newElementValue.push({ blob: null, url: value.url });
                            continue;
                        }

                        //? If we have a new image, we create a new image and filesMetadata entry
                        const file = value.blob;
                        images.push(file);
                        filesMetadata.push({
                            id: element.id,
                            position: i,
                        });
                        //? We set the value to null, because we can't send images (base64) and blobs as JSON.
                        //? We will set the url back on the backend after the upload to S3Bucket.
                        newElementValue.push({ blob: null, url: null });
                    }
                    element.value = newElementValue;
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                }
            }

            const reqTemplateData = {
                ...templateData,
                columns: newColumns,
                columnOrder: newColumnOrder,
                elements: newElements,
            };

            return { images, filesMetadata, reqTemplateData };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const generatePDF = async () => {
        try {
            setIsGeneratingPdf(true);
            setPreviewPDFOffer(false);
            setPageNumber(1);

            const reqForm = new FormData();
            reqForm.append(
                'templateId',
                (activeTemplateTab === 0 && templates[selectedTemplate].id) ||
                    (activeTemplateTab === 1 && docTemplates[selectedTemplate].id),
            );
            reqForm.append('items', JSON.stringify(offerContext.pdfInfo.items));

            const isPersonalized = Boolean(activeTemplateTab);
            if (isPersonalized) {
                const { images, filesMetadata, reqTemplateData } = await generateTemplateRequestData();
                reqForm.append('data', JSON.stringify(reqTemplateData));
                reqForm.append('filesMetadata', JSON.stringify(filesMetadata));
                for (const image of images) {
                    reqForm.append('files', image);
                }
            }

            const res = await API.post(`/offer_generate_pdf/${offerContext.offerId}`, reqForm, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    isStandard,
                    isPersonalized,
                },
            });

            setFile({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: res.data.getFullPdfSignedUrl,
            });

            enqueueSnackbar(t('The offer PDF is successfully created!'), {
                variant: 'success',
            });

            editOfferContext('offer', res.data.offer);
            setPreviewPDFOffer(true);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        } finally {
            setIsGeneratingPdf(false);
        }
    };

    const getMilestones = async () => {
        try {
            const res = await API.get('/internal_activities', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });
            let activities = res.data.activity;

            return activities.filter((activity) => activity.activitiesTasks.length > 0);
        } catch (error) {
            console.error(error);
            throw 'Error fetching milestones';
        }
    };

    const getAllRevenues = async () => {
        try {
            const res = await Revenues.get(999999, 0);
            if (res.ok) return res.data.revenuName;

            throw res.error;
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
            throw 'Error fetching revenues';
        }
    };

    const getTemplates = async () => {
        try {
            const templateFunc = API.get('offer_template_pages', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });

            const docTemplatesFunc = API.get('docTemplates', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });

            const [templatesRes, docTemplatesRes] = await Promise.all([templateFunc, docTemplatesFunc]);

            return {
                templates: templatesRes.data.data.data,
                docTemplates: docTemplatesRes.data.data,
            };
        } catch (error) {
            console.error(error);
            throw 'Error fetching milestones';
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const [companyVat, allTemplates, milestones, revenues] = await Promise.all([
                    getCompanyVAT(),
                    getTemplates(),
                    getMilestones(),
                    getAllRevenues(),
                ]);

                setTemplates(allTemplates.templates);
                setDocTemplates(allTemplates.docTemplates);

                const isPersonalized = offerContext.offer.isPersonalized;

                if (!isPersonalized && offerContext.offer.offerPdfGroupTemplatesId) {
                    const templateIdx = allTemplates.templates.findIndex(
                        (template) => template.id === offerContext.offer.offerPdfGroupTemplatesId,
                    );

                    if (templateIdx >= 0) setSelectedTemplate(templateIdx);
                }

                if (isPersonalized && offerContext.offer.OfferDocuments.at(-1)) {
                    const templateIdx = allTemplates.docTemplates.findIndex(
                        (template) => template.id === offerContext.offer.OfferDocuments.at(-1).templateId,
                    );

                    if (templateIdx >= 0) setSelectedTemplate(templateIdx);
                }

                setMilestones(milestones);

                let articleItems = [];
                if (offerContext.offer.offerArticles.length) {
                    // we add in articles an empty selectedAttributes and selectedVariations for attributes and variants dropdowns
                    const newSelectedArticlesForm = offerContext.offer.offerArticles.map((art) => {
                        const newArt = {
                            ...art,
                            selectedAttributes: [],
                            selectedVariations: [],
                            amount: art.amount || art.quantity,
                        };

                        delete newArt.quantity;
                        return newArt;
                    });

                    articleItems = articlesInfoForPDF(newSelectedArticlesForm, revenues, offerContext.offerId);
                }

                // We set the sip info in PDF information from context
                const sipArray = sipInfoForPDF(
                    offerContext.offer.OfferImmobile.map((sip) => sip.immobile),
                    articleItems,
                    companyVat,
                );

                editOfferContext('pdfInfo', { items: [...articleItems, ...sipArray] });

                if (offerContext.offer && offerContext.offer.offerPdfSignedUrl) {
                    setFile({
                        httpHeaders: {
                            'Access-Control-Allow-Origin': '*',
                        },
                        url: offerContext.offer.offerPdfSignedUrl,
                    });

                    setIsStandard(offerContext.offer.isStandard);
                }
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) return <Loading />;

    return (
        <>
            {templates.length > 0 && (
                <div className="flex flex-col items-start gap-10">
                    {!viewOnly && (
                        <>
                            <div className="flex flex-col gap-5">
                                <Tabs
                                    activeTab={activeTemplateTab}
                                    setActiveTab={(i) => {
                                        setSelectedTemplate(null);
                                        setActiveTemplateTab(i);
                                    }}
                                    tabs={templateTabs}
                                    disabled={isGeneratingPdf}
                                />
                                {activeTemplateTab === 0 && (
                                    <div className="flex flex-col gap-5">
                                        <div style={{ zIndex: 10 }}>
                                            <LabelWrapper label={t('Choose a PDF template to generate offer PDF')}>
                                                <Dropdown
                                                    disabled={isGeneratingPdf}
                                                    options={templates.map((t) => t.name)}
                                                    selectedOption={selectedTemplate}
                                                    setSelectedOption={handleTemplateChange}
                                                    placeholder={t('Choose a PDF template')}
                                                />
                                            </LabelWrapper>
                                        </div>
                                        <div style={{ zIndex: 9 }}>
                                            <LabelWrapper label={t('The complexity of PDF details')}>
                                                <Dropdown
                                                    disabled={isGeneratingPdf}
                                                    options={[t('Standard PDF details'), t('Complex PDF details')]}
                                                    selectedOption={Number(!isStandard)}
                                                    setSelectedOption={(e) => {
                                                        setIsStandard(!e);
                                                    }}
                                                />
                                            </LabelWrapper>
                                        </div>
                                    </div>
                                )}
                                {activeTemplateTab === 1 && (
                                    <div style={{ zIndex: 10 }}>
                                        <LabelWrapper label={t('Choose a document template to generate offer PDF')}>
                                            <Dropdown
                                                disabled={isGeneratingPdf}
                                                options={docTemplates.map((t) => t.name)}
                                                selectedOption={selectedTemplate}
                                                setSelectedOption={handleTemplateChange}
                                                placeholder={t('Choose a document template')}
                                            />
                                        </LabelWrapper>
                                    </div>
                                )}
                            </div>

                            {selectedTemplate !== null && activeTemplateTab === 1 && (
                                <div className="flex w-full flex-col gap-6">
                                    <h2 className="mb-6">{t('Document interface')}</h2>
                                    <DocumentTemplate
                                        disabled={isGeneratingPdf}
                                        templateData={templateData}
                                        setTemplateData={setTemplateData}
                                        rawTemplate={docTemplates[selectedTemplate]}
                                        usedTemplate={offerContext.offer.OfferDocuments.at(-1)}
                                        milestones={milestones}
                                        offers={[{ ...offerContext.offer, ...offerContext.pdfInfo }]}
                                        initialData={[
                                            {
                                                itemType: 'content',
                                                purpose: 'offer',
                                                id: offerContext.offerId,
                                                position: 0,
                                            },
                                            {
                                                itemType: 'signature',
                                                purpose: 'offer',
                                                id: offerContext.offerId,
                                                position: 0,
                                            },
                                        ]}
                                        pmProjectId={null}
                                        exportProps={{
                                            purpose: 'ia',
                                            params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {(selectedTemplate !== null || file) && (
                        <div className="flex flex-wrap gap-10">
                            {selectedTemplate !== null && !viewOnly && (
                                <BasicTooltip
                                    tip={t("You can't generate a PDF if you don't have at least one language selected")}
                                    disabled={
                                        activeTemplateTab === 0 ||
                                        (activeTemplateTab === 1 && !templateData) ||
                                        (activeTemplateTab === 1 &&
                                            templateData &&
                                            Object.values(templateData.columns).find((column) => column.isActive))
                                    }
                                >
                                    <Button
                                        color="secondary"
                                        startIcon={
                                            isGeneratingPdf ? <CircularProgress size={20} /> : <PictureAsPdfIcon />
                                        }
                                        onClick={() => generatePDF()}
                                        disabled={
                                            isGeneratingPdf ||
                                            (activeTemplateTab === 1 &&
                                                (!templateData ||
                                                    (templateData &&
                                                        Object.values(templateData.columns).every(
                                                            (column) => !column.isActive || !column.isCompleted,
                                                        ))))
                                        }
                                    >
                                        {t('Generate PDF')}
                                    </Button>
                                </BasicTooltip>
                            )}

                            {file && (
                                <>
                                    <Button
                                        disabled={isGeneratingPdf}
                                        color="secondary"
                                        startIcon={previewPDFOffer ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        onClick={() => setPreviewPDFOffer(!previewPDFOffer)}
                                    >
                                        {previewPDFOffer ? t('Hide PDF Preview') : t('Visualize PDF Preview')}
                                    </Button>
                                    <a
                                        className="flex items-center"
                                        href={file.url}
                                        download
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Button
                                            disabled={isGeneratingPdf}
                                            className={styles.error}
                                            startIcon={<GetAppIcon />}
                                        >
                                            {t('Download PDF')}
                                        </Button>
                                    </a>
                                </>
                            )}
                        </div>
                    )}

                    {!file && viewOnly && (
                        <div className="flex w-full max-w-3xl items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                            <p>{t('There is no PDF on this offer!')}</p>
                        </div>
                    )}

                    {previewPDFOffer && (
                        <Fragment>
                            <h2 className="mb-6">{t('PDF Preview')}</h2>
                            <PDFPreview file={file} pageNumber={pageNumber} setPageNumber={setPageNumber} />
                        </Fragment>
                    )}

                    {!viewOnly && (
                        <div className="flex gap-6">
                            <Button
                                disabled={isGeneratingPdf}
                                color="primary"
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => editOfferContext('step', 2)}
                            >
                                {t('Step')} 3
                            </Button>

                            {(file || offerContext.offer.offerPdfSignedUrl) && (
                                <Button
                                    disabled={isGeneratingPdf}
                                    color="primary"
                                    endIcon={<ArrowForwardIosIcon />}
                                    onClick={() => editOfferContext('step', 4)}
                                >
                                    {t('Step')} 5
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            )}

            {templates.length === 0 && !viewOnly && (
                <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                    <p className="mb-5">{t('There are no PDF templates yet, you have to add one first!')}</p>
                    <Button
                        color="primary"
                        onClick={() =>
                            history.push(
                                configurator.base + configurator.pdfTemplates.base + configurator.pdfTemplates.create,
                            )
                        }
                    >
                        {t('Add PDF template')}
                    </Button>
                </div>
            )}
        </>
    );
};

OfferPDF.propTypes = {
    viewOnly: PropTypes.bool,
};

OfferPDF.defaultProps = { viewOnly: false };

export default OfferPDF;

import React from 'react';

import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { Button, CircularProgress } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import DocumentTemplate from 'components/shared/document-template/document-template';
import FileUploadContainer from 'components/shared/file-upload-container';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { uploadSingleFile } from 'utils';

const CreateContractGenerateDocument = ({
    disabled,
    documentMode,
    setDocumentMode,
    pdf,
    setPdf,
    templates,
    selectedTemplate,
    setSelectedTemplate,
    templateData,
    setTemplateData,
    setStep,
    offers,
    milestones,
    initialData,
    pmProjectId,
    onSubmit,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleAddFiles = (e) => {
        uploadSingleFile(
            e,
            ({ message, blob, info }) => {
                if (message) {
                    enqueueSnackbar(t(message), {
                        variant: 'error',
                    });
                } else if (blob && info) {
                    setPdf({ info, blob });
                }
            },
            'file',
        );
    };

    return (
        <div className="flex flex-col gap-8">
            <div className="w-min">
                <Dropdown
                    selectedOption={documentMode}
                    setSelectedOption={setDocumentMode}
                    options={[t('Upload PDF document'), t('Customize PDF document')]}
                />
            </div>

            {documentMode === 0 && (
                <>
                    <div className="flex items-center gap-8">
                        <FileUploadContainer onUpload={handleAddFiles}>
                            <Button
                                startIcon={<NoteAddIcon />}
                                color={pdf.blob ? 'secondary' : 'primary'}
                                className="self-center"
                            >
                                {t(pdf.blob ? t('Change document') : 'Add document')}
                            </Button>
                        </FileUploadContainer>

                        {pdf.info && <h4>{`${t('Uploaded document')}: ${pdf.info.name}`}</h4>}
                    </div>
                </>
            )}

            {documentMode === 1 && (
                <>
                    <div className="w-min">
                        <LabelWrapper label={t('Choose a document template to generate offer PDF')}>
                            <Dropdown
                                disabled={disabled}
                                options={templates.map((t) => t.name)}
                                selectedOption={selectedTemplate}
                                setSelectedOption={(i) => setSelectedTemplate(i)}
                                placeholder={t('Choose a document template')}
                            />
                        </LabelWrapper>
                    </div>

                    {selectedTemplate !== null && (
                        <DocumentTemplate
                            disabled={disabled}
                            templateData={templateData}
                            setTemplateData={setTemplateData}
                            rawTemplate={templates[selectedTemplate]}
                            milestones={milestones}
                            offers={offers}
                            initialData={initialData}
                            pmProjectId={pmProjectId}
                        />
                    )}
                </>
            )}

            <div className="flex gap-2 pt-8">
                <Button disabled={disabled} color="primary" onClick={() => setStep((prev) => prev - 1)}>
                    {t('The previous step')}
                </Button>

                <BasicTooltip
                    tip={t(
                        "You can't create the contract and generate a PDF if you don't have at least one language selected",
                    )}
                    disabled={
                        pdf.blob ||
                        !templateData ||
                        (templateData && Object.values(templateData.columns).find((column) => column.isActive))
                    }
                >
                    <Button
                        disabled={
                            disabled ||
                            (documentMode === 0 && !pdf.blob) ||
                            (documentMode === 1 &&
                                (!templateData ||
                                    (templateData &&
                                        Object.values(templateData.columns).every(
                                            (column) => !column.isActive || !column.isCompleted,
                                        ))))
                        }
                        color="secondary"
                        onClick={onSubmit}
                    >
                        {disabled ? <CircularProgress size={20} /> : t('Create contract')}
                    </Button>
                </BasicTooltip>
            </div>
        </div>
    );
};

CreateContractGenerateDocument.propTypes = {
    disabled: PropTypes.bool,
    documentMode: PropTypes.number,
    setDocumentMode: PropTypes.func,
    pdf: PropTypes.object,
    setPdf: PropTypes.func,
    templates: PropTypes.array,
    selectedTemplate: PropTypes.object,
    setSelectedTemplate: PropTypes.func,
    templateData: PropTypes.object,
    setTemplateData: PropTypes.func,
    onSubmit: PropTypes.func,
    setStep: PropTypes.func,
    milestones: PropTypes.array,
    offers: PropTypes.array,
    pmProjectId: PropTypes.string,
    initialData: PropTypes.array,
};

CreateContractGenerateDocument.defaultProps = {
    disabled: false,
    documentMode: 0,
    setDocumentMode: () => {},
    pdf: {
        info: null,
        blob: null,
    },
    setPdf: () => {},
    templates: [],
    selectedTemplate: null,
    setSelectedTemplate: () => {},
    templateData: null,
    setTemplateData: () => {},
    setStep: () => {},
    onSubmit: () => {},
    milestones: [],
    offers: [],
    pmProjectId: null,
    initialData: [],
};

export default CreateContractGenerateDocument;

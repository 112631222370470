import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import GantContext from 'contexts/GantContext';
import PropTypes from 'prop-types';
import { internalActivity, projectManagement } from 'routes';

import { cellHeight } from './config';

const Employee = ({ employee, projectId, teamId }) => {
    const history = useHistory();
    const { type, viewOnly } = useContext(GantContext);

    const resourceUrl =
        type == 'internal'
            ? internalActivity.base +
              internalActivity.planning.base +
              internalActivity.planning.resource +
              '/' +
              employee.employee.id +
              '/' +
              projectId +
              '/' +
              teamId
            : projectManagement.base +
              projectManagement.projectsPlaningOverview.resource +
              '/' +
              employee.employee.id +
              '/' +
              projectId +
              '/' +
              teamId;

    return employee !== null ? (
        <div style={{ height: cellHeight }}>
            <div
                className="group relative flex items-center space-x-2 rounded-md bg-layout-transparent-dark px-4"
                style={{ height: cellHeight - 2 }}
            >
                <p
                    className="cursor-pointer font-semibold hover:text-primary-main"
                    onClick={viewOnly ? undefined : () => history.push(resourceUrl)}
                >
                    {employee.employee.firstName}
                </p>
                <p className="ml-1 mr-1 text-sm text-dark-text opacity-50">&#9679;</p>
                <p className="text-dark-text">{employee.employee.position}</p>
                {/* <p className="text-sm mr-1 ml-1 text-white opacity-0 group-hover:opacity-40">&#9679;</p> */}
                {/* <p className="font-semibold opacity-0 group-hover:opacity-40">90h</p> */}
            </div>
        </div>
    ) : null;
};

Employee.propTypes = {
    employee: PropTypes.object,
    projectId: PropTypes.string,
    teamId: PropTypes.string,
};

Employee.defaultProps = {
    employee: null,
    projectId: null,
    teamId: null,
};

export default Employee;

//eslint-disable-next-line

import { defaultTheme, rgbToHex } from 'utils';

import { handleElementExport, handleMultiPageExport } from './exportUtils';

/**
 * The `generateNotificationPDF` function generates notification data for an email and PDF document based on the
 * provided parameters and returns a Promise that resolves to the generated PDF.
 * @param {object} tenant - The object used to extract all the data needed about the company.
 * @param {String} viewMode - The view mode of the gantt chart. It determines how the chart is displayed, such
 * as day, week, month, etc.
 * @param {Array} selectedMilestones - An array of selected milestones.
 * @param {Array} allMilestones - An array of all milestones available.
 * @param {String} purpose - The purpose parameter is a string that
 * represents what type of info will be used for the email data (PM or CRM)
 * @param {object} info - The `info` parameter is an object that contains
 * information about the the Company or Project used for the export (CRM or PM)
 * @param {Function} callback - The `callback` parameter is a function that will be called with the generated
 * content HTML as its argument. It is used to handle the generated content and perform any necessary
 * actions, such as displaying it on the page or component.
 * @param {String} splitImage - The `splitImage` parameter determines how the exported PDF will
 * handle multiple pages. It can have two possible values: multi-page for a PDF with multiple pages and single-page for a one page PDF
 * @returns The function `generateNotificationPDF` returns a Promise that resolves to a PDF file.
 */
const generateNotificationPDF = async (
    ganttWrapper,
    ganttLegend,
    pageOrientation = 'landscape',
    tenant,
    splitImage = 'multi-page',
) => {
    try {
        // ? At the end we return a promise that we will be used to create the web worker where the notification data and PDF file will be created
        return new Promise((resolve, reject) => {
            const worker = new Worker('../workers/generateNotificationPDF.worker', {
                name: 'generateNotificationPDF',
                type: 'module',
            });

            // ? We create the array of tasks needed for the gantt

            let dataImages = [];

            // ? In the case of a multi page export we split the big image in smaller images in order to fit them in different PDF pages
            if (splitImage === 'multi-page') {
                const extractedImages = handleMultiPageExport(
                    ganttWrapper.canvas,
                    ganttWrapper.disabledCropHeightsIntervals,
                    pageOrientation,
                );
                const extractedImage = handleElementExport(ganttLegend.canvas, pageOrientation);
                dataImages = [...extractedImages, extractedImage];
            }

            // ? In the case of a single page export we get the images using the same function for both
            if (splitImage === 'single-page') {
                const extractedImage = handleElementExport(ganttWrapper.canvas, pageOrientation);
                const extractedSecondaryImage = handleElementExport(ganttLegend.canvas, pageOrientation);
                dataImages = [extractedImage, extractedSecondaryImage];
            }

            // ? We must delete the pageBreak property on the last item of the array in order to avoid creating an empty page in the PDF
            delete dataImages.at(-1).pageBreak;

            // ? Header object with the company details
            const headerDetails = {
                name: tenant.companyName,
                registerNo: tenant.companyRegisterNo,
                cui: tenant.cui,
                address: tenant.addressString,
                phone: tenant.phoneNo,
                email: tenant.email,
            };

            const colorsDetails = {};

            // ? If the company has at least one theme we will use that theme in order to get the PDF colors
            if (tenant.ThemeConfigurator.length > 0) {
                const tenantTheme = JSON.parse(
                    tenant.ThemeConfigurator.find((theme) => theme.id === tenant.activeThemeId).theme,
                );

                colorsDetails.mainText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
                colorsDetails.linkText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '));
                colorsDetails.footerText = rgbToHex(...tenantTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '));
                colorsDetails.footerBackground = rgbToHex(
                    ...tenantTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' '),
                );
            }
            // ? If the company doesn't have a theme yet we use the general default M2M theme
            else {
                const parsedDefaultTheme = JSON.parse(defaultTheme.theme);

                colorsDetails.mainText = rgbToHex(...parsedDefaultTheme['PDF-COLORS']['PDF-TEXT'].color.split(' '));
                colorsDetails.linkText = rgbToHex(
                    ...parsedDefaultTheme['PDF-COLORS']['PDF-LINK-TEXT'].color.split(' '),
                );
                colorsDetails.footerText = rgbToHex(
                    ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '),
                );
                colorsDetails.footerBackground = rgbToHex(
                    ...parsedDefaultTheme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(' '),
                );
            }

            // ? At the end we send a message to the worker with all the data received above in order to generate the PDF
            worker.postMessage({
                dataImages,
                headerDetails,
                colorsDetails,
                pageOrientation,
                companyLogo: tenant.logoUrl,
            });

            // ? We generate the pdf file for the email and finalize the process
            worker.onmessage = (e) => {
                worker.terminate();

                // const link = document.createElement('a');
                // link.href = e.data;
                // link.setAttribute('download', `${new Date().toISOString().slice(0, 10)}.pdf`);
                // document.body.appendChild(link);
                // link.click();
                // link.parentNode.removeChild(link);

                resolve(e.data);
            };

            // ? If the actions result in a error we call the callback, close the worker and reject the promise
            worker.onerror = (e) => {
                console.error(e);
                worker.terminate();
                reject(e);
            };
        });
    } catch (error) {
        console.error(error);
    }
};

export default generateNotificationPDF;

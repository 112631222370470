import React, { cloneElement } from 'react';

import PropTypes from 'prop-types';

import PipelineTag from './pipeline-tag';

const PipelineItemHeader = ({ itemProps, item, column }) => {
    const { header } = itemProps;
    const { status, tags } = header;
    const formattedTags = tags(item);
    const { icon: statusIcon, label } = status(item);
    const { color, backgroundColor, borderColor } = column;

    return (
        <div className="flex items-center justify-between gap-3">
            <div
                className="flex h-14 items-center justify-center gap-3 rounded-xl border pl-4 pr-5"
                style={{ backgroundColor, borderColor }}
            >
                {cloneElement(statusIcon, {
                    style: {
                        color,
                        flexShrink: 0,
                    },
                    className: 'h-7 w-7',
                })}
                <p
                    className="text-center text-xl font-medium leading-5"
                    style={{
                        color,
                    }}
                >
                    {label}
                </p>
            </div>
            {formattedTags.length > 0 && (
                <div className="flex h-12 items-center justify-end gap-3">
                    {formattedTags.map((tag, index) => {
                        return <PipelineTag key={index} tag={tag} />;
                    })}
                </div>
            )}
        </div>
    );
};

PipelineItemHeader.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
    column: PropTypes.object,
};

PipelineItemHeader.defaultProps = {
    itemProps: {},
    item: {},
    column: {
        key: 'DEFAULT',
        title: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default PipelineItemHeader;

import React from 'react';

import { User } from 'lucide-react';

import PropTypes from 'prop-types';

const Avatar = ({ url }) => {
    return (
        <div
            className={`flex h-full items-center justify-center rounded-full bg-cover bg-center ${!url ? 'border border-layout-transparent bg-layout-transparent' : ''}`}
            style={{
                aspectRatio: '1 / 1',
                backgroundImage: url ? `url('${url}')` : undefined,
            }}
        >
            {!url && <User className="h-4/5 w-4/5" />}
        </div>
    );
};

Avatar.propTypes = {
    url: PropTypes.string,
};

Avatar.defaultProps = {
    url: null,
};

export default Avatar;

import React, { useContext } from 'react';

import { Button, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import {
    calculateSumWithVAT,
    formatPositiveNumber,
    formatPositiveNumberWithDigits,
    toLocaleNumber,
    toRoundedSignificantDigits,
} from 'utils';

const CreateContractPaymentTypeSelection = ({ paymentType, setPaymentType, data, setData, setStep, VAT }) => {
    const { t } = useTranslation();

    const { currencyObj, language } = useContext(GlobalContext);

    const diffWithoutVat = data.withoutTva - data.advance;
    const diffWithVAT = toRoundedSignificantDigits(data.withTva - data.advanceWithTva);

    const handleAdvanceChange = (e) => {
        const value = formatPositiveNumberWithDigits(e.target.value);

        if (value > data.withoutTva) {
            setData({
                ...data,
                [e.target.name]: data.withoutTva,
                advanceWithTva: calculateSumWithVAT(data.withoutTva, VAT),
            });
        } else {
            setData({
                ...data,
                [e.target.name]: value,
                advanceWithTva: calculateSumWithVAT(value, VAT),
            });
        }
    };

    const handleChange = (e) => {
        if (e.target.name === 'advance') {
            handleAdvanceChange(e);
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handlePaymentChange = () => {
        if (data.numberOfPayments === data.payments.length) return;

        let newPayments = [...data.payments];
        if (data.numberOfPayments < newPayments.length) {
            /**
             * have to remove cells
             */
            newPayments = newPayments.slice(0, data.numberOfPayments);
        } else {
            /**
             * have to add new empty cells
             */
            const newCellCount = data.numberOfPayments - newPayments.length;
            let emptyCells = new Array(newCellCount).fill();

            emptyCells = emptyCells.map(() => ({
                date: null,
                amount: null,
                amountWithTva: null,
            }));
            newPayments = [...newPayments, ...emptyCells];
        }

        setData({
            ...data,
            payments: [...newPayments],
        });
    };

    const updatePayments = ({ paymentIndex, key, value }) => {
        const newData = { ...data };
        newData.payments[paymentIndex][key] = value;

        setData(newData);
    };

    const totalSumOfPaymentsWithoutVAT = data?.payments?.reduce((total, el) => total + el.amount, 0);

    const disableNextStep = () => {
        if (paymentType === 0 && data.date) {
            return true;
        } else if (paymentType === 1) {
            const baseValidation =
                data.numberOfPayments === data?.payments?.length &&
                !data?.payments
                    ?.map((p) => {
                        if (p.date !== null && p.amount !== null) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    .some((x) => x === false) &&
                totalSumOfPaymentsWithoutVAT === diffWithoutVat;

            if (baseValidation) return true;
        }

        return false;
    };

    return (
        <>
            <div className="mb-16 flex flex-wrap gap-16">
                <div className="flex w-full max-w-md flex-shrink-0 flex-col gap-5 sm:max-w-full">
                    <div className="relative z-50">
                        <LabelWrapper label={t('Type of the payment')}>
                            <Dropdown
                                selectedOption={paymentType}
                                setSelectedOption={setPaymentType}
                                options={[
                                    t('Direct sale'),
                                    t('Staggered payment'),
                                    t('Payment in installments'),
                                    t('Rented'),
                                ]}
                            />
                        </LabelWrapper>
                    </div>

                    <div className="relative z-40 flex flex-col gap-5">
                        {paymentType === 0 && (
                            <>
                                <div className="flex flex-col gap-4 rounded-xl border border-layout-lighter p-4">
                                    <LocaleTextField
                                        label={t('Contract price without VAT')}
                                        placeholder={t('Contract price without VAT')}
                                        name="withoutTva"
                                        value={data.withoutTva}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />

                                    <LocaleTextField
                                        label={t('The amount to be paid (with VAT)')}
                                        placeholder={t('The amount to be paid (with VAT)')}
                                        name="withTva"
                                        value={data.withTva}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                    <LabelWrapper label={t('Date of payment')}>
                                        <DatePicker
                                            date={data.date}
                                            setDate={(date) => {
                                                handleChange({ target: { name: 'date', value: date } });
                                            }}
                                        />
                                    </LabelWrapper>
                                </div>
                            </>
                        )}

                        {paymentType === 1 && (
                            <>
                                <div className="flex flex-col gap-4 rounded-xl border border-layout-lighter p-4">
                                    <LocaleTextField
                                        label={t('Contract price without VAT')}
                                        placeholder={t('Enter the amount')}
                                        name="withoutTva"
                                        value={data.withoutTva}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                    <LocaleTextField
                                        label={t('The amount to be paid (with VAT)')}
                                        placeholder={t('The amount to be paid (with VAT)')}
                                        name="withoutTva"
                                        value={data.withTva}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col gap-4 rounded-xl border border-layout-lighter p-4">
                                    <LocaleTextField
                                        label={t('Advance without VAT')}
                                        placeholder={t('Advance without VAT')}
                                        name="advance"
                                        value={data.advance}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                    />
                                    <LocaleTextField
                                        label={t('Advance with VAT')}
                                        placeholder={t('Advance with VAT')}
                                        value={data.advanceWithTva}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                    <LocaleTextField
                                        label={t('The difference to be collected without VAT')}
                                        placeholder={t('The difference to be collected without VAT')}
                                        value={diffWithoutVat}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                    <LocaleTextField
                                        label={t('The difference to be collected with VAT')}
                                        placeholder={t('The difference to be collected with VAT')}
                                        value={diffWithVAT}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                            ),
                                        }}
                                        disabled
                                    />
                                </div>
                            </>
                        )}

                        {paymentType === 2 && <p className="text-main-text">Work in progress!</p>}

                        {paymentType === 3 && <p className="text-main-text">Work in progress!</p>}
                    </div>
                </div>

                {/* Esantioane  */}
                {paymentType === 1 && (
                    <div className="flex w-full max-w-xl flex-shrink-0 flex-col gap-10 sm:max-w-full">
                        <div className="flex gap-2">
                            <div className="inline-block w-32 sm:w-1/3">
                                <TextField
                                    label={t('Number of payments')}
                                    placeholder={t('Number of payments')}
                                    value={data.numberOfPayments}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            numberOfPayments: formatPositiveNumber(e.target.value),
                                        })
                                    }
                                />
                            </div>
                            <div className="inline-block self-end">
                                <Button onClick={handlePaymentChange}>{t('Confirm the number of the payments')}</Button>
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 sm:gap-8">
                            {data?.payments?.map((payment, paymentIndex) => (
                                <div key={paymentIndex} className="flex max-w-2xl gap-4 sm:max-w-full">
                                    <p className="self-end sm:self-start">{paymentIndex + 1}.</p>
                                    <div className="flex gap-2 sm:flex-col">
                                        <div className="min-w-max">
                                            <LabelWrapper label={t('Date of payment')}>
                                                <DatePicker
                                                    date={payment.date}
                                                    setDate={(date) =>
                                                        updatePayments({
                                                            paymentIndex,
                                                            key: 'date',
                                                            value: date,
                                                        })
                                                    }
                                                />
                                            </LabelWrapper>
                                        </div>
                                        <LocaleTextField
                                            label={t('Value to be paid - without VAT')}
                                            placeholder={t('Value to be paid')}
                                            value={payment.amount}
                                            onChange={(e) => {
                                                const value = formatPositiveNumberWithDigits(e.target.value);

                                                updatePayments({
                                                    paymentIndex,
                                                    key: 'amount',
                                                    value: value,
                                                });
                                                updatePayments({
                                                    paymentIndex,
                                                    key: 'amountWithTva',
                                                    value: calculateSumWithVAT(value, VAT),
                                                });
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {currencyObj.currency}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <LocaleTextField
                                            label={t('Value to be paid - with VAT')}
                                            placeholder={t('Value to be paid')}
                                            value={payment.amountWithTva}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {currencyObj.currency}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(() => {
                            // Amount + all the payments

                            return (
                                <p
                                    className={`text-lg ${
                                        totalSumOfPaymentsWithoutVAT === diffWithoutVat ? 'text-success' : 'text-error'
                                    }`}
                                >
                                    {t('Total price')}:{' '}
                                    <span className="text-xl font-bold">
                                        {toLocaleNumber(totalSumOfPaymentsWithoutVAT || 0, language, 2, 2)}{' '}
                                        {currencyObj.currency}
                                    </span>{' '}
                                    {t('(staggered payments)')} {t('must be equal with the difference without VAT')}
                                </p>
                            );
                        })()}
                    </div>
                )}
            </div>

            <div className="flex gap-2 pt-8">
                <Button color="primary" onClick={() => setStep((prev) => prev - 1)}>
                    {t('The previous step')}
                </Button>

                <Button disabled={!disableNextStep()} color="primary" onClick={() => setStep((prev) => prev + 1)}>
                    {t('Next step')}
                </Button>
            </div>
        </>
    );
};

CreateContractPaymentTypeSelection.propTypes = {
    paymentType: PropTypes.number,
    setPaymentType: PropTypes.func,
    data: PropTypes.object,
    setData: PropTypes.func,
    setStep: PropTypes.func,
    VAT: PropTypes.number,
};

CreateContractPaymentTypeSelection.defaultProps = {
    paymentType: 0,
    setPaymentType: () => {},
    data: {
        withTva: 0,
        withoutTva: 0,
        date: null,
        advance: 0,
        advanceWithTva: 0,
        numberOfPayments: 0,
        payments: [],
    },
    setData: () => {},
    setStep: () => {},
    VAT: 0,
};

export default CreateContractPaymentTypeSelection;

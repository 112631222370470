import React, { useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
// import { getSpecificRate } from 'utils/getterFunctions';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Button, InputAdornment, TextField } from '@material-ui/core';

import ValueDisplay from 'components/shared/value-display';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { DatePicker, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { calculateSumWithVAT, formatDate, formatPositiveNumberWithDigits, toLocaleNumber } from 'utils';
import API from 'utils/axios';

const Cashflow = ({
    contractId,
    payments: initialPayments,
    fetchContract,
    paymentDataFromComponent,
    VAT,
    pmProjectId,
}) => {
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);
    const globalContext = useContext(GlobalContext);
    const { currencyObj, language } = globalContext;
    const { enqueueSnackbar } = useSnackbar();

    const [payments, setPayments] = useState(initialPayments);

    const [newPay, setNewPay] = useState(false);
    const [newPayDeadline, setNewPayDealine] = useState(new Date());
    const [newPaySum, setNewPaySum] = useState(0);
    const sumToBePayed = calculateSumWithVAT(newPaySum, VAT);
    const [newPayDesc, setNewPayDesc] = useState('');
    const [newPayObs, setNewPayObs] = useState('');
    const [newPayLoading, setNewPayLoading] = useState(false);

    const [editPaymentData, setEditPaymentData] = useState({});

    const handleChange = (e) => {
        setEditPaymentData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeSum = (e) => {
        setEditPaymentData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
            sum: calculateSumWithVAT(formatPositiveNumberWithDigits(e.target.value), VAT),
        }));
    };

    const canAll = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canAllPM = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const addPayment = async () => {
        if (newPayLoading) return;
        setNewPayLoading(true);
        try {
            //TODO: Probably need to change this in the future...
            const res = await API.post('/forecastedPayments', {
                date: newPayDeadline,
                sum: sumToBePayed,
                sumWithoutVAT: newPaySum,
                status: 'NOT_PAID',
                description: newPayDesc.length > 0 ? newPayDesc : '-',
                observation: newPayObs.length > 0 ? newPayObs : '-',
                contractId: pmProjectId === null ? contractId : undefined,
                contractPMId: pmProjectId !== null ? contractId : undefined,
            });

            setPayments([...payments, res.data.payment]);
            enqueueSnackbar(t('Payment added successfully!'), { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(t('Failed to add payment! Refresh the page and try again!'), { variant: 'error' });
        } finally {
            setNewPayLoading(false);
            setNewPay(false);
            setNewPaySum(0);
            setNewPayDesc('');
            setNewPayObs('');
            setNewPayDealine(new Date());
        }
    };

    const updatePayment = async (payment, updateData) => {
        const paymentData = {
            sum: payment.sum,
            sumWithoutVAT: payment.sumWithoutVAT,
            status: payment.status,
            description: payment.description.length ? payment.description : ' ',
            observation: payment.observation.length ? payment.observation : ' ',
            contractId: pmProjectId === null ? payment.contractId : undefined,
            contractPMId: pmProjectId !== null ? payment.contractPMId : undefined,
        };

        /**
         * When updating payment desc/obs
         */
        delete updateData.id;

        try {
            await API.put('/forecastedPayments', {
                id: payment.id,
                data: {
                    ...paymentData,
                    ...updateData,
                },
            });

            const newData = await fetchContract(contractId);

            if (Object.keys(paymentDataFromComponent).length > 0) {
                paymentDataFromComponent.payments = newData.forecastedPayments;
            }
            setPayments(newData.forecastedPayments);

            globalContext.setGlobalModalOpen(false);
            setEditPaymentData({
                id: '',
                observation: '',
                description: '',
                sum: '',
                sumWithoutVAT: '',
            });

            enqueueSnackbar(t('Payment was marked as payed!'), { variant: 'success' });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center gap-1 rounded-md bg-layout-transparent p-6">
                <ErrorOutlineIcon fontSize="large" className="text-warning" />
                <h3 className="text-warning">{t('This view of payments and rates only has informative purpose')}</h3>
            </div>

            <div className="overflow-auto">
                <table className="w-full border-collapse text-main-text">
                    {/* Header */}
                    <thead>
                        <tr className="bg-layout-transparent">
                            <th className="rounded-l-md p-4 font-bold">{t('Date of issue')}</th>
                            <th className="p-4 font-bold">{t('Due date')}</th>
                            <th className="p-4 font-bold">
                                {`${t('Sum')} (${t('Without VAT')})`} {currencyObj.currency}
                            </th>
                            <th className="p-4 font-bold">
                                {t('Sum')} {currencyObj.currency}
                            </th>
                            <th className="p-4 font-bold">Status</th>
                            <th className="p-4 font-bold">{t('Description')}</th>
                            <th className="p-4 font-bold">{t('Observations')}</th>
                            <th className="rounded-r-md p-4" />
                        </tr>
                    </thead>

                    <tbody>
                        {/* Add Button */}
                        {((canAll && !pmProjectId) || (canAllPM && pmProjectId)) && newPay ? (
                            <tr>
                                <td colSpan={2} className="p-4">
                                    <LabelWrapper label={t('Due date')}>
                                        <DatePicker date={newPayDeadline} setDate={setNewPayDealine} />
                                    </LabelWrapper>
                                </td>

                                <td className="p-4" colSpan={2}>
                                    <div className="flex gap-4">
                                        <LocaleTextField
                                            value={newPaySum}
                                            placeholder={`100 ${currencyObj.currency}`}
                                            label={t('Enter the amount')}
                                            onChange={(e) =>
                                                setNewPaySum(formatPositiveNumberWithDigits(e.target.value))
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {currencyObj.currency}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <ValueDisplay
                                            alias={currencyObj.currency}
                                            value={toLocaleNumber(sumToBePayed, language, 2, 2)}
                                            label={t('The amount to be paid (with VAT)')}
                                        />
                                        {/* <LocaleTextField
                                            value={sumToBePayed}
                                            placeholder={`100 ${currencyObj.currency}`}
                                            label={t('The amount to be paid (with VAT)')}
                                            disabled
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {currencyObj.currency}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        /> */}
                                    </div>
                                </td>
                                {/* <td className="p-4">
                                    <LocaleTextField
                                        value={sumToBePayed * (COMPANY_RATE / EUR_RATE)}
                                        placeholder="100 EUR"
                                        label={t('Equivalent') + ' EUR'}
                                        disabled
                                    />
                                </td> */}

                                <td className="p-4"></td>

                                <td className="p-4">
                                    <TextField
                                        value={newPayDesc}
                                        placeholder={t('Description')}
                                        label={t('Description')}
                                        onChange={(e) => setNewPayDesc(e.target.value)}
                                    />
                                </td>

                                <td className="p-4">
                                    <TextField
                                        value={newPayObs}
                                        placeholder={t('Observations')}
                                        label={t('Observations')}
                                        onChange={(e) => setNewPayObs(e.target.value)}
                                    />
                                </td>

                                <td className="p-4">
                                    <div className="flex flex-wrap justify-end gap-2">
                                        <Button color="primary" onClick={addPayment}>
                                            {t('Add payment')}
                                        </Button>
                                        <Button color="secondary" onClick={() => setNewPay(false)}>
                                            {t('Cancel')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td className="p-4">
                                    <Button
                                        onClick={() => setNewPay(true)}
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        style={{ borderRadius: '999px' }}
                                    >
                                        {t('Add new payment')}
                                    </Button>
                                </td>
                            </tr>
                        )}

                        {payments
                            .sort((a, b) => (a.createAt > b.createAt ? 1 : -1))
                            .map((payment) => (
                                <tr
                                    key={payment.id}
                                    className="transition duration-300 hover:bg-layout-transparent-dark"
                                >
                                    <td className="rounded-l-md p-4">{formatDate(payment.createAt)}</td>
                                    <td className="p-4">{formatDate(payment.date)}</td>
                                    {editPaymentData.id === payment.id ? (
                                        <td className="p-4 font-bold" colSpan={2}>
                                            <div className="flex gap-4">
                                                <LocaleTextField
                                                    name="sumWithoutVAT"
                                                    value={editPaymentData.sumWithoutVAT}
                                                    placeholder={`100 ${currencyObj.currency}`}
                                                    label={t('Enter the amount')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                {currencyObj.currency}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={handleChangeSum}
                                                />

                                                <ValueDisplay
                                                    alias={currencyObj.currency}
                                                    value={toLocaleNumber(editPaymentData.sum, language, 2, 2)}
                                                    label={t('The amount to be paid (with VAT)')}
                                                />
                                            </div>
                                        </td>
                                    ) : (
                                        <>
                                            <td className="p-4 font-bold">
                                                <span>{toLocaleNumber(payment.sumWithoutVAT, language, 2)}</span>
                                                &nbsp;
                                                <span className="text-xs text-primary-main">
                                                    {currencyObj.currency}
                                                </span>
                                            </td>
                                            <td className="p-4 font-bold">
                                                <span>{toLocaleNumber(payment.sum, language, 2)}</span>
                                                &nbsp;
                                                <span className="text-xs text-primary-main">
                                                    {currencyObj.currency}
                                                </span>
                                            </td>
                                        </>
                                    )}

                                    <td className="p-4">
                                        {(() => {
                                            const spanClassName = (colorClass) =>
                                                `text-sm font-bold uppercase ${colorClass} px-4 py-2 rounded-full bg-layout-transparent whitespace-nowrap`;
                                            const zileRestante = Math.floor(
                                                (Date.now() - new Date(payment.date).getTime()) / (1000 * 60 * 60 * 24),
                                            );

                                            if (payment.status === 'NOT_PAID') {
                                                if (zileRestante <= 0)
                                                    return (
                                                        <span className={spanClassName('text-warning')}>
                                                            {t('not paid')}
                                                        </span>
                                                    );
                                                return (
                                                    <span className={spanClassName('text-error')}>{`${t(
                                                        'outstanding',
                                                    )} ${zileRestante} ${t('day(s)')}`}</span>
                                                );
                                            }

                                            return <span className={spanClassName('text-success')}>{t('paid')}</span>;
                                        })()}
                                    </td>

                                    {/* Read / Edit payment information */}
                                    {editPaymentData.id === payment.id ? (
                                        <>
                                            <td className="p-4">
                                                <TextField
                                                    placeholder={t('Description')}
                                                    name="description"
                                                    value={editPaymentData.description}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td className="p-4">
                                                <TextField
                                                    placeholder={t('Observations')}
                                                    name="observation"
                                                    value={editPaymentData.observation}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td className="p-4">{payment.description}</td>
                                            <td className="p-4">{payment.observation}</td>
                                        </>
                                    )}

                                    {/* Buttons */}
                                    {((canAll && !pmProjectId) || (canAllPM && pmProjectId)) && (
                                        <td className="rounded-r-md p-4">
                                            <div className="flex flex-wrap justify-end gap-2">
                                                {payment.status !== 'PAID' && (
                                                    <Button
                                                        color="primary"
                                                        startIcon={<DoneIcon />}
                                                        onClick={() => {
                                                            globalContext.setGlobalModalOpen(true);
                                                            globalContext.setGlobalModalChildren(
                                                                <div className="flex-col">
                                                                    <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                        {t(
                                                                            'Would you like to mark this expense as paid?',
                                                                        )}
                                                                    </p>

                                                                    <div className="flex items-center justify-center">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                updatePayment(payment, {
                                                                                    status: 'PAID',
                                                                                })
                                                                            }
                                                                        >
                                                                            {t('Yes')}
                                                                        </Button>
                                                                        <div className="w-4" />
                                                                        <Button
                                                                            onClick={() =>
                                                                                globalContext.setGlobalModalOpen(false)
                                                                            }
                                                                        >
                                                                            {t('No')}
                                                                        </Button>
                                                                    </div>
                                                                </div>,
                                                            );
                                                        }}
                                                    >
                                                        {t('Mark as payed')}
                                                    </Button>
                                                )}

                                                {/* Toggle buttons: is editing this row show SAVE button else show EDIT button */}
                                                {editPaymentData.id === payment.id ? (
                                                    <div className="flex flex-wrap justify-end gap-2">
                                                        <Button
                                                            color="primary"
                                                            startIcon={<EditIcon />}
                                                            onClick={() => updatePayment(payment, editPaymentData)}
                                                        >
                                                            {t('Save changes')}
                                                        </Button>
                                                        <Button
                                                            color="secondary"
                                                            onClick={() => setEditPaymentData({})}
                                                        >
                                                            {t('Cancel')}
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        style={{ overflow: 'hidden' }}
                                                        color="secondary"
                                                        startIcon={<EditIcon />}
                                                        onClick={() => {
                                                            setEditPaymentData({
                                                                id: payment.id,
                                                                description: payment.description,
                                                                observation: payment.observation,
                                                                sum: payment.sum,
                                                                sumWithoutVAT: payment.sumWithoutVAT,
                                                            });
                                                        }}
                                                    >
                                                        {t('Edit payment info')}
                                                    </Button>
                                                )}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            {/* Summary */}
            <div className="flex w-full rounded-md bg-layout-transparent p-6">
                {(() => {
                    const TOTAL_SUM = payments.reduce((total, p) => total + p.sum, 0);
                    const PAID_SUM = payments.filter((p) => p.status === 'PAID').reduce((total, p) => total + p.sum, 0);

                    return (
                        <div className="flex gap-16 sm:flex-col">
                            <div className="text-2xl">
                                <p className="mb-2 font-bold">
                                    <span className="text-2xl">{toLocaleNumber(TOTAL_SUM, language, 2)}</span>
                                    &nbsp;
                                    <span className="text-primary-main">{currencyObj.currency}</span>
                                </p>

                                <p className="mb-2">*{t('unpaid invoices are not put in full')}</p>

                                <p className="text-lg">
                                    <span>
                                        {toLocaleNumber(TOTAL_SUM - PAID_SUM, language, 2)}
                                        &nbsp;
                                        {currencyObj.currency} - {t('remaining receivable')}
                                    </span>
                                </p>
                            </div>
                        </div>
                    );
                })()}
            </div>
        </div>
    );
};

Cashflow.propTypes = {
    contractId: PropTypes.string,
    payments: PropTypes,
    fetchContract: PropTypes.func,
    withReset: PropTypes.bool,
    paymentDataFromComponent: PropTypes.object,
    VAT: PropTypes.number,
    pmProjectId: PropTypes.string,
};

Cashflow.defaultProps = {
    contractId: '',
    payments: [],
    fetchContract: () => {},
    withReset: false,
    paymentDataFromComponent: {},
    VAT: 0,
    pmProjectId: null,
};

export default Cashflow;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import ResourcesAccessGroupsForm from 'components/resources-access-groups/resources-access-groups-form';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import { getTenantUsers } from 'utils/getterFunctions';

const ResourcesAccessGroupsCreate = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const getUsers = async () => {
        try {
            const usersRes = await getTenantUsers();
            setUsers(usersRes);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user.isAdmin) {
            history.push('/');
        }

        getUsers();
    }, [user]);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new group')}</title>
            </Helmet>

            <Header pageTitle={t('Add new group')} />

            <div className="page-container">
                {loading ? (
                    <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <ResourcesAccessGroupsForm users={users} />
                )}
            </div>
        </>
    );
};

ResourcesAccessGroupsCreate.propTypes = {};

ResourcesAccessGroupsCreate.defaultProps = {};

export default ResourcesAccessGroupsCreate;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const ResourcesAccessGroupsForm = ({ users, initialGroup }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const getUserType = (user) => {
        if (user.isAdmin) return t('Admin');
        if (user.isAffiliate) return t('Affiliate');
        if (user.isInvestor) return t('Investor');
        if (user.isAdviser) return t('Sales agent');
        return t('Normal');
    };

    const [isOperating, setIsOperating] = useState(false);
    const [formData, setFormData] = useState(() => {
        if (initialGroup) return { name: initialGroup.name, users: initialGroup.users.map((user) => user.id) };
        return { name: '', users: [] };
    });
    const handleFormDataChange = (e) => setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const schema = yup.object().shape({
        name: yup.string().required(t('Name is mandatory!')),
        users: yup.array().of(yup.string().required()),
    });

    const createGroup = async () => {
        try {
            setIsOperating(true);
            await schema.validate(formData);
            await API.post('group', formData);
            enqueueSnackbar(t('Group created successfully!'), { variant: 'success' });
            history.push(configurator.base + '?tab=Resources access groups');
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsOperating(false);
        }
    };

    const updateGroup = async () => {
        try {
            setIsOperating(true);
            await schema.validate(formData);
            await API.put('group', { id: initialGroup.id, ...formData });
            enqueueSnackbar(t('Group updated successfully!'), { variant: 'success' });
            history.push(configurator.base + '?tab=Resources access groups');
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsOperating(false);
        }
    };

    return (
        <div className="flex max-w-md flex-col gap-5">
            <LabelWrapper label={t('Name')}>
                <TextField
                    disabled={isOperating}
                    name="name"
                    placeholder={t('Name')}
                    value={formData.name}
                    onChange={handleFormDataChange}
                />
            </LabelWrapper>
            <LabelWrapper label={t('Users')}>
                <MultiDropdown
                    disabled={isOperating}
                    placeholder={t('Choose options')}
                    options={users.map((user) => `${user.email} - ${getUserType(user).toUpperCase()}`)}
                    selectedOptions={users.reduce(
                        (acc, curr, index) => (formData.users.includes(curr.id) ? [...acc, index] : acc),
                        [],
                    )}
                    setSelectedOptions={(i) => {
                        const selectedUserId = users[i].id;
                        const value = formData.users.includes(selectedUserId)
                            ? formData.users.filter((id) => id !== selectedUserId)
                            : [...formData.users, selectedUserId];

                        handleFormDataChange({ target: { name: 'users', value } });
                    }}
                />
            </LabelWrapper>

            <div className="mt-20">
                {initialGroup ? (
                    <Button
                        disabled={isOperating}
                        type="submit"
                        startIcon={isOperating ? <CircularProgress size={20} /> : <CheckIcon />}
                        color="primary"
                        onClick={() => updateGroup()}
                    >
                        {t('Edit group')}
                    </Button>
                ) : (
                    <Button
                        disabled={isOperating}
                        startIcon={isOperating ? <CircularProgress size={20} /> : <AddIcon />}
                        color="primary"
                        onClick={() => createGroup()}
                    >
                        {t('Add new group')}
                    </Button>
                )}
            </div>
        </div>
    );
};

ResourcesAccessGroupsForm.propTypes = {
    users: PropTypes.array,
    initialGroup: PropTypes.object,
};

ResourcesAccessGroupsForm.defaultProps = {
    users: [],
    initialGroup: null,
};

export default ResourcesAccessGroupsForm;

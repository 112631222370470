import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { Button, TextField } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import { PropTypes } from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import {
    calculateTotal,
    calculateVATValue,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
} from 'utils/index';

const Variable = (props) => {
    const optionSpanClass = 'text-main-text font-bold';
    const { deleteVariable, variable, editVariable, articlePrice, vars, unitList, setUnitList, disabled } = props;
    const { t } = useTranslation();
    const { currencyObj, language } = useContext(GlobalContext);

    const [um, setUM] = useState(null);
    const [newUm, setNewUm] = useState(null);
    const [newUmField, setNewUmField] = useState(false);
    // const [unitList, setUnitList] = useState([
    //     t('hours'),
    //     t('days'),
    //     t('piece'),
    //     'Kg',
    //     t('linear-meter'),
    //     t('square-meter'),
    //     t('add-new-um'),
    // ])

    useEffect(() => {
        if (variable.unit == unitList.length - 1) {
            setUM(null);
            setNewUmField(!newUmField);
        }
    }, [variable.unit]);

    useEffect(() => {
        if (variable.type === 2) {
            editVariable(
                'price',
                (
                    (vars
                        .slice(0, -1)
                        .map((v) => Number(v.price))
                        ?.reduce((a, b) => a + b, 0) +
                        Number(articlePrice)) *
                    (variable.percent / 100)
                ).toFixed(2),
            );
        }
    }, [variable.price, variable.percent, articlePrice]);

    return (
        <div className="mb-7 rounded-md bg-layout-transparent p-6">
            <div>
                <div className="mb-5 flex gap-4 sm:flex-col">
                    <div>
                        <TextField
                            name="ordine"
                            label={t('Order')}
                            placeholder="1"
                            value={variable.ordine}
                            onChange={(e) => editVariable('ordine', e.target.value)}
                            disabled={true}
                        />
                    </div>
                    <TextField
                        disabled={disabled}
                        name="numele variabilei"
                        label={t('Variable name')}
                        placeholder={t('Add name for the variable')}
                        value={variable.name}
                        onChange={(e) => editVariable('name', e.target.value)}
                    />
                </div>

                <div className="mb-5">
                    <TextField
                        disabled={disabled}
                        name="cod articol"
                        label={t('Variable code(SKU)')}
                        placeholder="ABC1234"
                        value={variable.code}
                        onChange={(e) => editVariable('code', e.target.value)}
                    />
                </div>

                <div className="mb-5">
                    <TextField
                        disabled={disabled}
                        name="descriere"
                        label={t('Variable description')}
                        placeholder={t('Variable description')}
                        value={variable.description}
                        onChange={(e) => editVariable('description', e.target.value)}
                        multiline
                        rows={3}
                    />
                </div>

                {variable.type === 1 ? (
                    <div className="mb-5 flex-1">
                        <LocaleTextField
                            disabled={disabled}
                            name="percent"
                            label={t('Percent')}
                            placeholder="10%"
                            InputProps={{
                                readOnly: !articlePrice,
                            }}
                            inputProps={{ min: 1 }}
                            value={variable.percent}
                            onChange={(e) => {
                                editVariable('percent', formatVATnumber(e.target.value));
                                if (variable.type === 1) {
                                    editVariable('price', ((variable.percent / 100) * articlePrice).toFixed(2));
                                }
                            }}
                        />
                    </div>
                ) : variable.type === 2 ? (
                    <div className="mb-5 flex-1">
                        <LocaleTextField
                            disabled={disabled}
                            name="percent"
                            label={t('Percent')}
                            placeholder="10%"
                            value={variable.percent}
                            onChange={(e) => {
                                editVariable('percent', formatVATnumber(e.target.value));
                            }}
                        />
                    </div>
                ) : null}

                {newUmField && (
                    <div className="inline-flex w-full gap-4 ">
                        <div className="mb-5 w-1/2">
                            <div className="mb-4">
                                <TextField
                                    disabled={disabled}
                                    name="new um"
                                    label={t('New UM')}
                                    placeholder={t('New UM')}
                                    value={newUm}
                                    onChange={(e) => setNewUm(e.target.value)}
                                />
                            </div>
                            <Button
                                disabled={disabled}
                                size="small"
                                color="primary"
                                onClick={() => {
                                    const newUnitList = [...unitList];
                                    const listLen = newUnitList.length;
                                    if (newUm == null) {
                                        setNewUmField(!newUmField);
                                        return;
                                    } else {
                                        newUnitList[listLen - 1] = newUm;
                                    }
                                    newUnitList.push(t('Add new UM'));
                                    setUnitList(newUnitList);
                                    setNewUm('');
                                    setNewUmField(false);
                                }}
                            >
                                {t('Add new UM')}
                            </Button>
                        </div>
                    </div>
                )}

                <div>
                    <div className="mb-5 inline-flex gap-5">
                        <div className="inline-block">
                            <LabelWrapper label={t('Amount/Percent')}>
                                <Dropdown
                                    disabled={disabled}
                                    placeholder={t('Choose the option')}
                                    options={[
                                        t(' Amount'),
                                        t('Percentage of basic item'),
                                        t('Percentage of the entire article(article + all variables)'),
                                    ]}
                                    selectedOption={variable.type}
                                    setSelectedOption={(newPriceType) => editVariable('type', newPriceType)}
                                />
                            </LabelWrapper>
                        </div>

                        <div className="inline-block">
                            <LabelWrapper label="UM">
                                <Dropdown
                                    disabled={disabled}
                                    placeholder={t('Choose the option')}
                                    options={unitList}
                                    selectedOption={variable.unit}
                                    setSelectedOption={(newUm) => {
                                        editVariable('unit', newUm);
                                    }}
                                />
                            </LabelWrapper>
                        </div>
                    </div>

                    <div className="flex  w-full flex-row gap-2 xl:flex-col">
                        <div className="mb-5 flex-1">
                            <LocaleTextField
                                disabled={disabled}
                                name="price without TVA"
                                label={t('Price without VAT')}
                                placeholder={`${toLocaleNumber(1234, language, 2)} ${currencyObj.currency}`}
                                value={variable.price}
                                InputProps={{
                                    readOnly: variable.type === 1 || variable.type === 2,
                                }}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                    if (variable.type !== 1) {
                                        editVariable('price', formatPositiveNumberWithDigits(e.target.value));
                                    }
                                }}
                            />
                        </div>

                        <div className="mb-5 flex-1">
                            <LocaleTextField
                                disabled={disabled}
                                name="cota TVA"
                                label={t('VAT rate')}
                                placeholder="19%"
                                value={variable.priceTVA}
                                onChange={(e) => editVariable('priceTVA', formatVATnumber(e.target.value))}
                            />
                        </div>

                        <div className="mb-5 flex-1">
                            <LabelWrapper label={t('VAT value')}>
                                <div className="flex h-10 cursor-not-allowed items-center rounded-md bg-layout-transparent px-3">
                                    <span className={optionSpanClass}>
                                        {toLocaleNumber(
                                            calculateVATValue(
                                                variable.type == 1
                                                    ? (variable.percent / 100) * articlePrice
                                                    : variable.price,
                                                variable.priceTVA,
                                            ),
                                            language,
                                            2,
                                        )}{' '}
                                        {currencyObj.currency}
                                    </span>
                                </div>
                            </LabelWrapper>
                        </div>

                        <div className="mb-5 flex-1">
                            <LabelWrapper label={t('Final price with VAT')}>
                                <div className="flex h-10 cursor-not-allowed items-center rounded-md bg-layout-transparent px-3">
                                    <span className={optionSpanClass}>
                                        {toLocaleNumber(
                                            calculateTotal(
                                                calculateVATValue(
                                                    variable.type == 1
                                                        ? (variable.percent / 100) * articlePrice
                                                        : variable.price,
                                                    variable.priceTVA,
                                                ),
                                                variable.type == 1
                                                    ? (variable.percent / 100) * articlePrice
                                                    : variable.price,
                                            ),
                                            language,
                                            2,
                                        )}{' '}
                                        {currencyObj.currency}
                                    </span>
                                </div>
                            </LabelWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end">
                <div className={`inline-flex items-center ${!disabled ? 'text-main-text' : 'text-disabled'}`}>
                    <CloseIcon className="text-base" />
                    <p
                        className={`ml-2 transition-colors ${
                            !disabled ? 'cursor-pointer text-main-text hover:text-secondary-main' : 'text-disabled'
                        }`}
                        onClick={!disabled ? deleteVariable : undefined}
                    >
                        {t('Delete variable')}
                    </p>
                </div>
            </div>
        </div>
    );
};

Variable.propTypes = {
    deleteVariable: PropTypes.func,
    variable: PropTypes.object,
    editVariable: PropTypes.func,
    articlePrice: PropTypes.number,
    vars: PropTypes.array,
    unitList: PropTypes.array,
    setUnitList: PropTypes.func,
    disabled: PropTypes.bool,
};

Variable.defaultProps = {
    deleteVariable: () => null,
    variable: null,
    editVariable: () => null,
    articlePrice: null,
    vars: [],
    unitList: [],
    setUnitList: () => null,
    disabled: false,
};

export default Variable;

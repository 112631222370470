import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SendRounded } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import {
    Button,
    CircularProgress,
    createStyles,
    InputAdornment,
    makeStyles,
    Popper,
    TextField,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from '@material-ui/lab';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import GlobalContext from 'contexts/GlobalContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown, MultiDropdown, Toggle } from 'RaisisComponents';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX, errorHandling, generateUUID, uploadMultiFiles } from 'utils';
import API from 'utils/axios';
import { getImagesToCanvas } from 'utils/exportUtils';
import generateNotificationPDF from 'utils/generateNotificationPDF';
import { generateMilestoneContent } from 'utils/notificationsUtils';
import * as yup from 'yup';

const useStyles = makeStyles(() => {
    return {
        textField: {
            '& .MuiTypography-colorTextSecondary': {
                fontSize: '1.2rem',
            },
        },
    };
});

const useStylesAutocomplete = makeStyles(() => {
    return createStyles({
        root: {
            '& .MuiAutocomplete-inputRoot': {
                alignItems: 'start',
                border: `1px solid var(--primary-main)`,

                ' & ::placeholder': {
                    opacity: 1,
                    color: `var(--secondary-text)`,
                    fontSize: '1.2rem',
                    fontWeight: 600,
                },

                '& .MuiIconButton-label .MuiSvgIcon-root': {
                    fill: `var(--primary-main)`,
                    width: '1.5rem',
                    height: '1.5rem',
                },
            },
            '& .MuiAutocomplete-popper': {
                zIndex: 9999,
            },

            '& .MuiChip-root': {
                backgroundColor: `var(--primary-main)`,
                color: `var(--buttons-text)`,
                fontSize: '0.9rem',
                fontWeight: '600',
            },
        },
        option: {
            '&[data-focus="true"]': {
                backgroundColor: `var(--layout-lighter)`,
                color: `var(--main-text)`,
            },
        },
    });
});

const EmailTransactionInfo = ({
    disableSend,
    emailOptions,
    handleEmailToChange,
    emailToUsers,
    handleEmailReplyToChange,
    emailReplyToUsers,
    handleSubjectChange,
    emailSubject,
    handleAddOption,
    emailCcUsers,
    emailCcOptions,
    emailBccUsers,
    emailBccOptions,
    handleEmailCcChange,
    handleEmailBccChange,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const classesAutocomplete = useStylesAutocomplete();
    const [open, setOpen] = useState(false);
    const [openCC, setOpenCC] = useState(false);
    const [openBCC, setOpenBCC] = useState(false);
    const defaultData = { alias: '', email: '' };
    const [data, setData] = useState(defaultData);
    const [dataCC, setDataCC] = useState(defaultData);
    const [dataBCC, setDataBCC] = useState(defaultData);
    const [width] = useScreenSizes();

    const CustomPopper = function (props) {
        return (
            <Popper
                {...props}
                style={{
                    width: '48%',
                    position: 'relative',
                    zIndex: 9999,
                }}
            />
        );
    };

    const CustomPaper = (props) => {
        return (
            <Paper
                elevation={8}
                {...props}
                style={{
                    backgroundColor: `var(--layout-light)`,
                    color: `var(--main-text)`,
                }}
            />
        );
    };

    const schema = yup.object().shape({
        alias: yup.string().trim().typeError(t('The alias is not valid')),
        email: yup
            .string()
            .trim()
            .typeError(t('The email address is not valid'))
            .required(t('The email address is mandatory'))
            .matches(EMAIL_REGEX, t('The email address is not valid')),
    });

    const handleCancel = (type) => {
        if (type === 'to') {
            setData(defaultData);
            setOpen(false);
        }

        if (type === 'cc') {
            setDataCC(defaultData);
            setOpenCC(false);
        }

        if (type === 'bcc') {
            setDataBCC(defaultData);
            setOpenBCC(false);
        }
    };

    const handleAddRecipient = async (type) => {
        try {
            if (type === 'to') {
                await schema.validate(data);

                handleAddOption(data, 'to');
                handleCancel('to');
            }

            if (type === 'cc') {
                await schema.validate(dataCC);

                handleAddOption(dataCC, 'cc');
                handleCancel('cc');
            }

            if (type === 'bcc') {
                await schema.validate(dataBCC);

                handleAddOption(dataBCC, 'bcc');
                handleCancel('bcc');
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    const handleChange = (e, type) => {
        if (type === 'to') {
            setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }

        if (type === 'cc') {
            setDataCC((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }

        if (type === 'bcc') {
            setDataBCC((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }
    };

    return (
        <div className="flex w-full flex-col gap-3 p-2 pb-0">
            <div className="flex w-full gap-3 sm:flex-col">
                <div className="flex-shrink" style={{ width: open || width < 600 ? '100%' : 'calc(100% - 5.15rem)' }}>
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-to-users"
                        disabled={disableSend}
                        options={emailOptions.sort(
                            (a, b) => -b.profile.name.toUpperCase().localeCompare(a.profile.name.toUpperCase()),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailToChange}
                        value={emailToUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('TO')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!open && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip position="left" tip={t('Add new recipient option')}>
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <AddIcon />
                                {width < 600 && t('Add new recipient option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {open && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={data.alias}
                        onChange={(e) => handleChange(e, 'to')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={data.email}
                        onChange={(e) => handleChange(e, 'to')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('to')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('to')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <Autocomplete
                multiple
                autoComplete
                fullWidth
                openOnFocus
                classes={{
                    root: classesAutocomplete.root,
                    option: classesAutocomplete.option,
                }}
                PopperComponent={CustomPopper}
                filterSelectedOptions
                id="email-reply-to-users"
                disabled={disableSend}
                options={emailOptions.sort(
                    (a, b) => -b.profile.name.toUpperCase().localeCompare(a.profile.name.toUpperCase()),
                )}
                getOptionLabel={(option) => `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`}
                onChange={handleEmailReplyToChange}
                value={emailReplyToUsers}
                PaperComponent={CustomPaper}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t('REPLY TO')}
                        className={classes.textField}
                    />
                )}
                style={{ zIndex: 10000 }}
            />

            <div className="flex w-full gap-3 sm:flex-col">
                <div className="flex-shrink" style={{ width: openCC || width < 600 ? '100%' : 'calc(100% - 5.15rem)' }}>
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-cc-users"
                        disabled={disableSend}
                        options={emailCcOptions.sort(
                            (a, b) => -b.profile.name.toUpperCase().localeCompare(a.profile.name.toUpperCase()),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailCcChange}
                        value={emailCcUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('CARBON COPY')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!openCC && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip position="left" tip={t('Add new carbon copy option')}>
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpenCC(true)}
                            >
                                <AddIcon />
                                {width < 600 && t('Add new carbon copy option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {openCC && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={dataCC.alias}
                        onChange={(e) => handleChange(e, 'cc')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={dataCC.email}
                        onChange={(e) => handleChange(e, 'cc')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('cc')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('cc')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <div className="flex w-full gap-3 sm:flex-col">
                <div
                    className="flex-shrink"
                    style={{ width: openBCC || width < 600 ? '100%' : 'calc(100% - 5.15rem)' }}
                >
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-bcc-users"
                        disabled={disableSend}
                        options={emailBccOptions.sort(
                            (a, b) => -b.profile.name.toUpperCase().localeCompare(a.profile.name.toUpperCase()),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailBccChange}
                        value={emailBccUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('BLIND CARBON COPY')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!openBCC && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip position="left" tip={t('Add new blind carbon copy option')}>
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpenBCC(true)}
                            >
                                <AddIcon />
                                {width < 600 && t('Add new blind carbon copy option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {openBCC && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={dataBCC.alias}
                        onChange={(e) => handleChange(e, 'bcc')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={dataBCC.email}
                        onChange={(e) => handleChange(e, 'bcc')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('bcc')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('bcc')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <TextField
                variant="outlined"
                color="primary"
                className={classes.textField}
                name="emailSubject"
                disabled={disableSend}
                onChange={handleSubjectChange}
                value={emailSubject}
                InputProps={{
                    startAdornment: (
                        <InputAdornment className="w-24 px-3" position="start">
                            {t('SUBJECT')}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

EmailTransactionInfo.propTypes = {
    disableSend: PropTypes.bool,
    emailOptions: PropTypes.array,
    handleEmailToChange: PropTypes.func,
    emailToUsers: PropTypes.array,
    handleEmailReplyToChange: PropTypes.func,
    emailReplyToUsers: PropTypes.array,
    handleSubjectChange: PropTypes.func,
    emailSubject: PropTypes.string,
    handleAddOption: PropTypes.func,
    emailCcUsers: PropTypes.array,
    emailCcOptions: PropTypes.array,
    emailBccUsers: PropTypes.array,
    emailBccOptions: PropTypes.array,
    handleEmailCcChange: PropTypes.func,
    handleEmailBccChange: PropTypes.func,
};

EmailTransactionInfo.defaultProps = {
    disableSend: false,
    emailOptions: [],
    handleEmailToChange: () => {},
    emailToUsers: [],
    handleEmailReplyToChange: () => {},
    emailReplyToUsers: [],
    handleSubjectChange: () => {},
    emailSubject: '',
    handleAddOption: () => {},
    emailCcUsers: [],
    emailCcOptions: [],
    emailBccUsers: [],
    emailBccOptions: [],
    handleEmailCcChange: () => {},
    handleEmailBccChange: () => {},
};

const EmailButtons = ({ cancel, send, disableSend, step, handleChangeNotifyStep, handleNextStep, canGoNext }) => {
    const { t } = useTranslation();

    return (
        <div className="rounded-b-x w-full p-4">
            <div className="flex justify-between">
                <Button onClick={cancel} disabled={disableSend}>
                    {t('CANCEL')}
                </Button>
                <div className="flex items-center gap-8">
                    {step == 0 && (
                        <Button
                            onClick={() => {
                                handleNextStep();
                                handleChangeNotifyStep(1);
                            }}
                            disabled={canGoNext || disableSend}
                        >
                            {t('NEXT')}
                        </Button>
                    )}
                    {step == 1 && (
                        <Button disabled={disableSend} onClick={() => handleChangeNotifyStep(0)}>
                            {t('BACK')}
                        </Button>
                    )}
                    {step === 1 && (
                        <Button
                            color="primary"
                            onClick={send}
                            disabled={disableSend}
                            endIcon={disableSend ? <CircularProgress size={20} /> : <SendRounded />}
                        >
                            {t('SEND')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
EmailButtons.propTypes = {
    send: PropTypes.func,
    cancel: PropTypes.func,
    disableSend: PropTypes.bool,
    step: PropTypes.number,
    handleChangeNotifyStep: PropTypes.func,
    handleNextStep: PropTypes.func,
    canGoNext: PropTypes.bool,
};

EmailButtons.defaultProps = {
    send: () => {},
    cancel: () => {},
    disableSend: false,
    step: 0,
    handleChangeNotifyStep: () => {},
    handleNextStep: () => {},
    canGoNext: false,
};

const DocumentsSection = ({ files, onAddFiles, onDeleteFile }) => {
    const { t } = useTranslation();

    return (
        <div className="my-6 w-full px-2">
            <div className="flex w-full flex-col gap-6 rounded-md bg-layout-transparent p-4">
                <div className="flex items-center gap-2 ">
                    <div className="text-primary-light">
                        <AttachFileIcon style={{ width: '2rem', height: '2rem' }} />
                    </div>
                    <h2>{t('Email Attachments')}</h2>
                </div>
                {files.length === 0 && <h3 className="text-center">{t('There are no files uploaded!')}</h3>}
                {files.length > 0 && (
                    <div className="flex flex-col gap-2">
                        {files.map((file) => (
                            <div
                                key={file.id}
                                className="flex w-full items-center justify-between gap-2 rounded-md bg-layout-transparent-dark p-2"
                            >
                                <div className="flex items-center gap-2">
                                    <div className="text-primary-light">
                                        <InsertDriveFileIcon />
                                    </div>
                                    <p className="break-words">{file.name}</p>
                                </div>
                                <BasicTooltip position="left" tip={t('Delete file')}>
                                    <ActionButton
                                        icon={<DeleteIcon />}
                                        color={`var(--error)`}
                                        size={9}
                                        onClick={() => onDeleteFile(file.id)}
                                    />
                                </BasicTooltip>
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex justify-end">
                    <FileUploadContainer onUpload={onAddFiles}>
                        <Button startIcon={<NoteAddIcon />} color="primary" className="self-center">
                            {t('Add document/s')}
                            <input name="image" hidden accept="image/*, application/pdf" type="file" />
                        </Button>
                    </FileUploadContainer>
                </div>
            </div>
        </div>
    );
};

DocumentsSection.propTypes = {
    files: PropTypes.array,
    onAddFiles: PropTypes.func,
    onDeleteFile: PropTypes.func,
};

DocumentsSection.defaultProps = {
    files: [],
    onAddFiles: () => {},
    onDeleteFile: () => {},
};

const EmailCreator = ({ cancel, users, emailReplyToUsers, purpose, milestones, initialMilestone, exportProps }) => {
    const { t } = useTranslation();
    const { tenant, language } = useContext(GlobalContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [width] = useScreenSizes();
    const { enqueueSnackbar } = useSnackbar();

    const [emailSubject, setEmailSubject] = useState('');
    const [emailTo, setEmailTo] = useState([]);
    const [emailCC, setEmailCC] = useState([]);
    const [emailBCC, setEmailBCC] = useState([]);
    const [emailReplyTo, setEmailReplyTo] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [emailOptionsCC, setEmailOptionsCC] = useState([]);
    const [emailOptionsBCC, setEmailOptionsBCC] = useState([]);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [useMilestonesContent, setUseMilestonesContent] = useState(true);

    const [milestonesContent, setMilestonesContent] = useState(null);
    const [selectedMilestones, setSelectedMilestones] = useState(initialMilestone ? [initialMilestone] : []);
    const handleMilestoneSelection = (i) => {
        const { id } = milestones[i];

        if (selectedMilestones.indexOf(id) > -1) setSelectedMilestones((prev) => prev.filter((opt) => opt !== id));
        else setSelectedMilestones((prev) => [...prev, id]);
    };

    const [step, setStep] = useState(0);
    const handleChangeNotifyStep = (step) => setStep(step);

    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(null);

    const viewModes = ['Day', 'Week', 'Month'];
    const [viewType, setViewType] = useState('Day');
    const handleChangeViewType = (i) => setViewType(viewModes[i]);

    const [files, setFiles] = useState([]);

    const { projectId } = useParams();

    //Set company details
    const setCompanyDetails = async () => {
        setInfo(tenant);
        setEmailSubject(tenant.companyName);
    };

    const getProjectDetails = async (projectId) => {
        setLoading(true);
        try {
            const response = await API.get('/pm_projectPlanningOverview', {
                params: {
                    id: projectId,
                },
            });

            setInfo(response.data.pm);
            setEmailSubject(response.data.pm.name);
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setEmailReplyTo(emailReplyToUsers);
        setEmailOptions(users);
        setEmailOptionsCC(users);
        setEmailOptionsBCC(users);

        if (purpose === 'CRM') setCompanyDetails();
        if (purpose === 'PM') getProjectDetails(projectId);
    }, []);

    const handleNextStep = () => {
        const contentHtml = generateMilestoneContent(
            {
                selectedMilestones,
                milestones,
                purpose,
                info,
            },
            t,
        );

        setMilestonesContent(contentHtml);

        if (useMilestonesContent) setEditorState(htmlToDraftBlocks(contentHtml));
        else setEditorState(EditorState.createEmpty());
    };

    const handleSubjectChange = (e) => setEmailSubject(e.target.value);

    const handleAddOption = (data, type) => {
        if (type === 'to') {
            setEmailOptions((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
            setEmailTo((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
        }

        if (type === 'cc') {
            setEmailOptionsCC((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
            setEmailCC((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
        }

        if (type === 'bcc') {
            setEmailOptionsBCC((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
            setEmailBCC((prev) => [...prev, { email: data.email, profile: { name: data.alias } }]);
        }
    };

    const handleAddFiles = (e) => {
        uploadMultiFiles(
            e,
            ({ message, blob, info }) => {
                if (message) {
                    enqueueSnackbar(t(message), {
                        variant: 'error',
                    });
                } else if (blob && info) {
                    const newFiles = [];

                    for (let i = 0; i < blob.length; i++) {
                        newFiles.push({
                            id: generateUUID(),
                            blob: blob[i],
                            name: info[i].name,
                        });
                    }

                    setFiles((prev) => [...prev, ...newFiles]);
                }
            },
            'all',
        );
    };

    const handleDeleteFile = (id) => {
        setFiles((prev) => prev.filter((file) => file.id !== id));
    };

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const validateForm = (emailTo, emailSubject, emailReplyTo) => {
        if (emailTo.length === 0) {
            enqueueSnackbar(t('You must select at least one recipient'), { variant: 'error' });
            return false;
        }

        if (!emailReplyTo) {
            enqueueSnackbar(t('You must select one person to witch you respond'), { variant: 'error' });
            return false;
        }
        if (emailSubject.trim().length === 0) {
            enqueueSnackbar(t('You must add a subject'), { variant: 'error' });
            return false;
        }

        return true;
    };

    //Send notification function
    const sendNotification = async (html, milestonesHtml, emailTo, emailCC, emailBCC, emailSubject, emailReplyTo) => {
        if (!validateForm(emailTo, emailSubject, emailReplyTo)) return;

        setSendingEmail(true);

        const reqBody = new FormData();
        reqBody.append(
            'data',
            JSON.stringify({
                html,
                milestonesHtml,
                emailTo: emailTo.map((item) => item.email),
                emailCC: emailCC.map((item) => item.email),
                emailBCC: emailBCC.map((item) => item.email),
                emailSubject,
                emailReplyTo: emailReplyTo.email,
            }),
        );

        if (files.length) {
            files.forEach((file) => {
                reqBody.append('files', file.blob);
            });
        }

        const { purpose, params } = exportProps;
        const [ganttWrapper, legendWrapper] = await getImagesToCanvas(
            [
                {
                    ids: ['gantt-wrapper-root'],
                    type: 'gantt',
                    element: {
                        type: 'milestones',
                        purpose,
                    },
                    data: {
                        view: viewType,
                        customDates: null,
                        selectedEntities: selectedMilestones,
                    },
                    request: {
                        params,
                    },
                },
                {
                    ids: ['gantt-legend-root'],
                    type: 'gantt-legend',
                    element: {
                        type: 'milestones',
                    },
                },
            ],
            language,
        );

        const ganttPDF = await generateNotificationPDF(ganttWrapper, legendWrapper, 'landscape', tenant, 'multi-page');
        reqBody.append('files', ganttPDF);

        try {
            await API.post('/send_activity_notification', reqBody, { 'Content-Type': 'multipart/form-data' });
            enqueueSnackbar(t('Notification sent successfully!'), { variant: 'success' });
            cancel();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(t(errorHandling(err)), { variant: 'error' });
        } finally {
            setSendingEmail(false);
        }
    };

    if (loading)
        return (
            <div className="flex w-96 flex-col items-center justify-center gap-8 px-10 py-20 sm:w-64">
                <CircularProgress />
                <h2 className="text-2xl sm:text-xl">
                    {t('Data is loading, please wait and do not close or reload the page!')}
                </h2>
            </div>
        );

    return (
        <Fragment>
            <div
                className={`w-full rounded-xl p-px ${
                    step === 0 ? 'overflow-visible' : 'overflow-y-auto overflow-x-hidden'
                }`}
                style={{
                    maxHeight: '65vh',
                    maxWidth: step === 0 ? '500px' : '',
                    width: width > 600 ? '80vw' : '90vw',
                }}
            >
                {step === 0 && (
                    <div className="mx-10 my-12 flex flex-col gap-10 rounded-t-xl">
                        <h3 className="text-center text-3xl">{t('Select milestones and Gantt view mode')}</h3>
                        <div className="flex gap-6 sm:mx-5 sm:flex-wrap">
                            <div className="w-full">
                                <MultiDropdown
                                    options={milestones.map((milestone) => milestone.title)}
                                    placeholder={t('Choose milestones')}
                                    selectedOptions={selectedMilestones.map((milestoneId) =>
                                        milestones.findIndex((milestone) => milestone.id === milestoneId),
                                    )}
                                    setSelectedOptions={handleMilestoneSelection}
                                />
                            </div>

                            <div className="w-full">
                                <Dropdown
                                    options={viewModes.map((el) => t(el))}
                                    selectedOption={viewModes.indexOf(viewType)}
                                    setSelectedOption={handleChangeViewType}
                                />
                            </div>
                        </div>
                        <div className="flex items-center rounded-lg bg-layout-transparent px-1.5 py-3 sm:mx-5">
                            <Toggle checked={useMilestonesContent} setChecked={setUseMilestonesContent} />

                            <p className="ml-4 font-bold">{t('Use generated milestones content for the email')}</p>
                        </div>
                    </div>
                )}
                {step === 1 && (
                    <div className="m-0 flex w-full flex-col items-center justify-center rounded-t-xl">
                        <EmailTransactionInfo
                            emailToUsers={emailTo}
                            emailOptions={emailOptions}
                            handleEmailToChange={(_, value) => setEmailTo(value)}
                            emailCcUsers={emailCC}
                            emailCcOptions={emailOptionsCC}
                            handleEmailCcChange={(_, value) => setEmailCC(value)}
                            emailBccUsers={emailBCC}
                            emailBccOptions={emailOptionsBCC}
                            handleEmailBccChange={(_, value) => setEmailBCC(value)}
                            handleEmailReplyToChange={(_, value, reason) => {
                                if (reason === 'remove-option') {
                                    setEmailReplyTo([]);
                                }
                                if (reason === 'select-option') {
                                    const len = value.length;
                                    setEmailReplyTo([value[len - 1]]);
                                }
                            }}
                            emailReplyToUsers={emailReplyTo}
                            handleAddOption={handleAddOption}
                            emailSubject={emailSubject}
                            handleSubjectChange={handleSubjectChange}
                        />
                        <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            toolbarStyle={{
                                margin: 2,
                                padding: 2,
                                borderRadius: 0,
                                backgroundColor: `var(--layout-light)`,
                                border: 'none',
                                marginTop: '8px',
                                color: 'black',
                            }}
                            wrapperStyle={{
                                width: '100%',
                            }}
                            editorStyle={{
                                borderRadius: 0,
                                backgroundColor: 'white',
                                padding: '0px 8px',
                                minHeight: '20rem',
                                maxHeight: width <= 750 ? '' : '40rem',
                                lineHeight: 1.2,
                                color: 'black',
                            }}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'list',
                                    'textAlign',
                                    'colorPicker',
                                    'link',
                                    'remove',
                                    'history',
                                ],
                            }}
                        />
                        <DocumentsSection
                            files={files}
                            setFiles={setFiles}
                            onAddFiles={handleAddFiles}
                            onDeleteFile={handleDeleteFile}
                        />
                    </div>
                )}
            </div>

            <EmailButtons
                send={() => {
                    const htmlContent = DOMPurify.sanitize(
                        draftToHtml(convertToRaw(editorState.getCurrentContent())).replaceAll(
                            'color: currentcolor;',
                            '',
                        ),
                    );
                    sendNotification(
                        htmlContent,
                        milestonesContent,
                        emailTo,
                        emailCC,
                        emailBCC,
                        emailSubject,
                        emailReplyTo[0],
                    );
                }}
                cancel={cancel}
                disableSend={sendingEmail}
                step={step}
                handleChangeNotifyStep={handleChangeNotifyStep}
                handleNextStep={handleNextStep}
                canGoNext={!selectedMilestones.length}
            />
        </Fragment>
    );
};
EmailCreator.propTypes = {
    cancel: PropTypes.func,
    users: PropTypes.array,
    emailReplyToUsers: PropTypes.array,
    setEmailSubject: PropTypes.func,
    milestones: PropTypes.array,
    purpose: PropTypes.string,
    initialMilestone: PropTypes.string,
    exportProps: PropTypes.object,
};

EmailCreator.defaultProps = {
    cancel: () => {},
    users: [],
    emailReplyToUsers: [],
    setEmailSubject: () => {},
    milestones: [],
    purpose: 'CRM',
    initialMilestone: null,
    exportProps: {
        purpose: 'ia',
        params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
    },
};

export default EmailCreator;

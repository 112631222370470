import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import Loading from 'components/shared/loading';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { configurator } from 'routes';
import { errorHandling, generateUUID, uploadSingleFile } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const FilesRow = (props) => {
    const { file, deleteFilesAndDocuments, fileType } = props;
    const { t } = useTranslation();

    return (
        <div className="group flex items-center justify-between rounded-md bg-layout-transparent px-4 py-2 transition-colors hover:bg-layout-transparent-dark">
            {fileType === 'FILE' ? (
                <div className="flex items-center">
                    <AttachFileIcon
                        className="mr-2 rotate-45 transform text-buttons-text"
                        style={{ fontSize: '1.75rem' }}
                    />
                    <p className="user-select-none text-buttons-text">{file.info.name}</p>
                </div>
            ) : (
                <div className="flex items-center">
                    <InsertDriveFileIcon className="mr-2 text-buttons-text" style={{ fontSize: '1.75rem' }} />
                    <p className="user-select-none text-buttons-text">{file.info.name}</p>
                </div>
            )}

            <div className="flex items-center">
                <BasicTooltip tip={`${fileType == 'FILE' ? t('Delete file') : t('Delete document')}`}>
                    <div
                        className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error-light opacity-0 transition-all hover:bg-error group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                        onClick={() => deleteFilesAndDocuments(file.id, file.fileType)}
                    >
                        <CloseIcon className="text-buttons-text" style={{ fontSize: '1.25rem' }} />
                    </div>
                </BasicTooltip>
            </div>
        </div>
    );
};

FilesRow.propTypes = {
    file: PropTypes.object,
    deleteFilesAndDocuments: PropTypes.func,
    fileType: PropTypes.string,
};

FilesRow.defaultProps = {
    file: null,
    deleteFilesAndDocuments: () => null,
    fileType: 'FILE',
};

const PMWikiModal = (props) => {
    const { open, closeModal, getWikis, initialData, departments } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { projectId } = useParams();

    const contentRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [height] = useScreenSizes();

    const [selectedDepartment, setSelectedDepartment] = useState(departments[0]?.id ?? '');

    const [wikiSubject, setWikiSubject] = useState('');
    const [wikiContent, setWikiContent] = useState('');

    const [files, setFiles] = useState([]);
    const [documents, setDocuments] = useState([]);

    /**
     * This use effect set the modal with info from the wiki info who must be edited
     */
    useEffect(() => {
        if (open) {
            if (initialData) {
                setSelectedDepartment(initialData.pmDepartmentId);
                setWikiSubject(initialData.subject);
                setWikiContent(initialData.details);
            } else {
                setSelectedDepartment(departments[0]?.id ?? '');
                setWikiSubject('');
                setWikiContent('');
            }
            setFiles([]);
            setDocuments([]);
        }
    }, [initialData, open]);

    /**
     * Function delete files or documents uploaded on the modal, before creation of wiki
     * @param {*} id  id of the file
     * @param {*} fileType can be 'FILE' or 'DOC'
     */
    const deleteFilesAndDocuments = (id, fileType) => {
        let copy;
        if (fileType === 'FILE') {
            copy = files.filter((file) => file.id !== id);
            setFiles(copy);
        } else {
            copy = documents.filter((document) => document.id !== id);
            setDocuments(copy);
        }
    };

    const schema = yup.object().shape({
        wikiSubject: yup
            .string()
            .trim()
            .typeError(t('The subject for the wiki is mandatory!'))
            .min(2, t('The subject must be at least 2 characters long!'))
            .required(t('The subject for the wiki is mandatory!')),
        wikiContent: yup
            .string()
            .trim()
            .typeError(t('The wiki must have a content!'))
            .required(t('The wiki must have a content!')),
        selectedDepartment: yup
            .string()
            .typeError(t('The wiki must have a department selected!'))
            .required(t('The wiki must have a department selected!')),
    });

    const createWiki = async () => {
        try {
            await schema.validate({
                wikiSubject,
                wikiContent,
                selectedDepartment,
            });

            const allFilesAndDocuments = [...files, ...documents]; //Create a single array with files and documents

            setLoading(true);
            try {
                const formData = new FormData();
                const reqBody = {
                    subject: wikiSubject,
                    details: wikiContent,
                    pmDepartmentId: selectedDepartment,
                    projectId: projectId,
                };

                formData.append('data', JSON.stringify(reqBody));

                allFilesAndDocuments.forEach((file) => formData.append('files', file.blob));
                formData.append(
                    'filesMetaData',
                    JSON.stringify(allFilesAndDocuments.map((file) => ({ name: file.info.name, type: file.fileType }))),
                );

                await API.post('/wikis', formData);
                await getWikis();
                closeModal();
                setLoading(false);
                enqueueSnackbar(t('The wiki was successfully created!'), { variant: 'success' });
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const editWiki = async () => {
        try {
            await schema.validate({
                wikiSubject,
                wikiContent,
                selectedDepartment,
            });

            const allFilesAndDocuments = [...files, ...documents]; //Create a single array with files and documents

            setLoading(true);
            try {
                const formData = new FormData();
                const reqBody = {
                    id: initialData.id,
                    data: {
                        subject: wikiSubject,
                        details: wikiContent,
                        pmDepartmentId: selectedDepartment,
                        projectId: projectId,
                        filesToDelete: [],
                    },
                };

                formData.append('data', JSON.stringify(reqBody));

                allFilesAndDocuments.forEach((file) => formData.append('files', file.blob));
                formData.append(
                    'filesMetaData',
                    JSON.stringify(allFilesAndDocuments.map((file) => ({ name: file.info.name, type: file.fileType }))),
                );

                await API.put('/wikis', formData);
                await getWikis();
                closeModal();
                setLoading(false);
                enqueueSnackbar(t('The wiki was successfully edited!'), { variant: 'success' });
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let observer = null;

        if (contentRef.current) {
            observer = new ResizeObserver((entries) => {
                if (contentRef.current) {
                    for (const entry of entries) {
                        if (entry.target.getBoundingClientRect().height >= height * 0.6) {
                            contentRef.current.style.overflowY = 'auto';
                            contentRef.current.style.overflowX = 'hidden';
                        } else {
                            contentRef.current.style.overflow = 'visible';
                        }
                    }
                }
            });

            observer.observe(contentRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [loading, height]);

    if (!open) return null;

    return (
        <>
            <div className="sticky top-8 z-50 flex w-full max-w-2xl flex-col gap-5 self-start rounded-md bg-layout-transparent p-10 xl:fixed xl:left-2/4 xl:top-2/4 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-lighter sm:w-11/12">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {departments.length > 0 ? (
                            <>
                                {/* Modal Header */}
                                <div className="flex justify-between gap-10">
                                    <h2 className="font-semibold">{`${initialData ? t('Edit wiki') : t('Post wiki')}`}</h2>

                                    <CloseIcon
                                        className="cursor-pointer text-primary-main opacity-75 hover:opacity-100"
                                        style={{
                                            fontSize: '2.5rem',
                                            transition: 'opacity .2s ease',
                                        }}
                                        onClick={closeModal}
                                    />
                                </div>

                                <div className="flex flex-col gap-5" style={{ maxHeight: '70vh' }} ref={contentRef}>
                                    {/* Modal departments */}

                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="status"
                                            name="status-filter"
                                            value={selectedDepartment}
                                            onChange={(e) => setSelectedDepartment(e.target.value)}
                                        >
                                            <div className="no-user-select-recursive flex items-center text-main-text">
                                                <div className="flex flex-wrap">
                                                    {departments.map((d) => (
                                                        <FormControlLabel
                                                            key={d.id}
                                                            value={d.id}
                                                            control={<Radio />}
                                                            label={d.name}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </RadioGroup>
                                    </FormControl>

                                    <div className="flex flex-col gap-3">
                                        <TextField
                                            name={t('Subject')}
                                            placeholder={t('Subject')}
                                            value={wikiSubject}
                                            onChange={(e) => setWikiSubject(e.target.value)}
                                        />

                                        <TextField
                                            name={t('Post content')}
                                            placeholder={t('Post content')}
                                            value={wikiContent}
                                            onChange={(e) => setWikiContent(e.target.value)}
                                            multiline
                                        />
                                    </div>

                                    <div className="mb-5 flex flex-col gap-2">
                                        {files.map((file) => (
                                            <FilesRow
                                                key={file.id}
                                                file={file}
                                                deleteFilesAndDocuments={deleteFilesAndDocuments}
                                                fileType={file.fileType}
                                            />
                                        ))}

                                        {documents.map((document) => (
                                            <FilesRow
                                                key={document.id}
                                                file={document}
                                                deleteFilesAndDocuments={deleteFilesAndDocuments}
                                                fileType={document.fileType}
                                            />
                                        ))}

                                        <div className="flex flex-wrap gap-3">
                                            <FileUploadContainer
                                                accept="*"
                                                onUpload={(e) => {
                                                    uploadSingleFile(
                                                        e,
                                                        ({ message, blob, info }) => {
                                                            if (message) {
                                                                enqueueSnackbar(message, {
                                                                    variant: 'error',
                                                                });
                                                            } else if (blob && info) {
                                                                setFiles([
                                                                    ...files,
                                                                    {
                                                                        id: generateUUID(),
                                                                        blob,
                                                                        info,
                                                                        fileType: 'FILE',
                                                                    },
                                                                ]);
                                                            }
                                                        },
                                                        '*',
                                                    );
                                                }}
                                            >
                                                <Button type="submit" startIcon={<AttachmentIcon />}>
                                                    {initialData ? t('Attach new file') : t('Attach file')}
                                                </Button>
                                            </FileUploadContainer>

                                            <FileUploadContainer
                                                accept="*"
                                                onUpload={(e) => {
                                                    uploadSingleFile(
                                                        e,
                                                        ({ message, blob, info }) => {
                                                            if (message) {
                                                                enqueueSnackbar(message, {
                                                                    variant: 'error',
                                                                });
                                                            } else if (blob && info) {
                                                                setDocuments([
                                                                    ...documents,
                                                                    {
                                                                        id: generateUUID(),
                                                                        blob,
                                                                        info,
                                                                        fileType: 'DOCUMENT',
                                                                    },
                                                                ]);
                                                            }
                                                        },
                                                        '*',
                                                    );
                                                }}
                                            >
                                                <Button type="submit" startIcon={<InsertDriveFileIcon />}>
                                                    {initialData ? t('Attach new document') : t('Attach document')}
                                                </Button>
                                            </FileUploadContainer>
                                        </div>
                                    </div>

                                    {/* Modal buttons for actions */}
                                    {initialData ? (
                                        <div className="flex gap-3">
                                            <Button
                                                type="submit"
                                                startIcon={<EditIcon />}
                                                color="secondary"
                                                onClick={editWiki}
                                            >
                                                {t('Edit wiki')}
                                            </Button>

                                            <div>
                                                <Button type="submit" color="secondary" onClick={closeModal}>
                                                    {t('Cancel')}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex gap-3">
                                            <Button
                                                type="submit"
                                                startIcon={<AddIcon />}
                                                color="secondary"
                                                onClick={createWiki}
                                            >
                                                {t('Post')}
                                            </Button>

                                            <div>
                                                <Button type="submit" color="secondary" onClick={closeModal}>
                                                    {t('Cancel')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <p className="mb-8 text-center text-base font-semibold opacity-75">
                                    {t('To add a new wiki, you must add a department first!')}
                                </p>
                                <Button
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    onClick={() =>
                                        history.push(
                                            configurator.base +
                                                configurator.departments.base +
                                                configurator.departments.create,
                                        )
                                    }
                                >
                                    {t('Add department')}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </div>
            <div
                className={`fixed left-0 top-0 z-40 hidden h-svh w-screen ${open ? 'xl:flex' : ''}`}
                style={{ backgroundColor: 'rgba(0,0,0,0.3)', backdropFilter: 'blur(5px)' }}
                onClick={closeModal}
            />
        </>
    );
};

PMWikiModal.propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    getWikis: PropTypes.func,
    initialData: PropTypes.object,
    departments: PropTypes.array,
};

PMWikiModal.defaultProps = {
    open: false,
    closeModal: () => null,
    getWikis: () => null,
    initialData: {},
    departments: [],
};

export default PMWikiModal;

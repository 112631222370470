import React from 'react';

import { CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';

const Loading = ({ style }) => {
    return (
        <div
            className="flex w-full items-center justify-center"
            style={{
                height: '20rem',
                ...style,
            }}
        >
            <CircularProgress />
        </div>
    );
};

Loading.propTypes = {
    style: PropTypes.object,
};
Loading.defaultValues = {
    style: '',
};

export default Loading;

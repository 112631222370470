import React, { useContext, useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import InvoiceForm from 'components/accountancy/invoice-form';
import BasicTooltip from 'components/shared/basic-tooltip';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as yup from 'yup';

const File = (props) => {
    const { file, deleteFile } = props;
    const { t } = useTranslation();

    return (
        <div className="group mb-2 flex items-center justify-between rounded-md bg-layout-transparent-dark px-4 py-2 transition-colors hover:bg-primary-main hover:bg-opacity-40">
            <div className="flex items-center">
                <EuroSymbolIcon className="mr-2 text-main-text" style={{ fontSize: '1.75rem' }} />
                <p className="user-select-none">{file.info.name}</p>
            </div>

            <div className="flex items-center">
                <BasicTooltip tip={t('Delete file')}>
                    <div
                        className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error-light opacity-0 transition-all hover:bg-error group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                        onClick={() => deleteFile(file.id)}
                    >
                        <CloseIcon className="text-main-text" style={{ fontSize: '1.25rem' }} />
                    </div>
                </BasicTooltip>
            </div>
        </div>
    );
};

File.propTypes = {
    file: PropTypes.object,
    deleteFile: PropTypes.func,
};

File.defaultProps = {
    file: null,
    deleteFile: () => null,
};

const PMTaskManagementInvoices = (props) => {
    const { departmentId, partnerId, tenantUsers } = props;
    const { t } = useTranslation();
    const { projectId } = useParams();

    const { user } = useContext(UserContext);
    const taskManagementContext = useContext(TaskManagementContext);

    const [invoiceFile, setInvoiceFile] = useState(null);
    const [responsible, setResponsible] = useState([]);
    const [invoiceDetails, setInvoiceDetails] = useState(null);

    /**
     * @param {*} users The list of the users index from an dropdown
     * @returns An array with users ids
     */
    const userIndexToId = (users) => {
        return users.map((user) => tenantUsers[user].id);
    };

    const createPMTaskManagementInvoices = async () => {
        if (taskManagementContext.isCreating) return;

        try {
            const formData = new FormData();
            const reqBody = {
                title: 'Invoice',
                description: 'descriere',
                note: '-',
                actionType: 'INVOICES_AND_BILLS',
                status: 'TODO',
                ownerId: user.id,
                responsible: userIndexToId(responsible),
                supervisor: [],
                watcher: [],
                partnerId: partnerId ? partnerId : undefined,
                pmDepartmentId: departmentId,
                pmProjectPlanId: projectId,
                invoice: {
                    ...invoiceDetails,
                    pmProjectOverviewPlanId: projectId,
                },
            };

            formData.append('data', JSON.stringify(reqBody));
            formData.append('files', invoiceFile);

            formData.append('filesMetaData', JSON.stringify([{ name: invoiceFile.name, type: 'FILE' }]));

            taskManagementContext.submitTaskManagementActivity(formData);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (invoiceDetails !== null && invoiceFile !== null) {
            createPMTaskManagementInvoices();
        }
    }, [invoiceDetails, invoiceFile]);

    return (
        <div className="mt-10">
            <div className="mb-10 inline-flex">
                <MultiDropdown
                    options={tenantUsers.map((user) => user?.profile?.name)}
                    placeholder={t('Responsible')}
                    selectedOptions={responsible}
                    variant="black"
                    setSelectedOptions={(i) => {
                        if (responsible.indexOf(i) > -1) {
                            setResponsible(responsible.filter((opt) => opt !== i));
                        } else {
                            setResponsible([...responsible, i]);
                        }
                    }}
                />
            </div>

            {responsible.length > 0 && (
                <div className="flex justify-center">
                    <InvoiceForm
                        displayCrmProjects={false}
                        displayPmProjects={true}
                        viewOnly={false}
                        isValid={false}
                        setInvoiceDetails={setInvoiceDetails}
                        setInvoiceFile={setInvoiceFile}
                    />
                </div>
            )}
        </div>
    );
};

PMTaskManagementInvoices.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    closeModal: PropTypes.func,
    beneficiaryCheck: PropTypes.bool,
};

PMTaskManagementInvoices.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    closeModal: () => null,
    beneficiaryCheck: false,
};

export default PMTaskManagementInvoices;

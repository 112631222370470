import React from 'react';

import PropTypes from 'prop-types';

const WikiBody = ({ wiki }) => {
    return (
        <div className="flex flex-col gap-4">
            <p className="text-3xl font-medium leading-10">{wiki.subject}</p>
            <p className="text-xl leading-7">{wiki.details}</p>
        </div>
    );
};

WikiBody.propTypes = {
    wiki: PropTypes.object,
};

WikiBody.defaultProps = {
    wiki: {},
};

export default WikiBody;

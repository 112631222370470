import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

import PipelineItem from './item/pipeline-item';
import PipelineLabel from './pipeline-label';
import PipelineSortLabel from './pipeline-sort-label';

const columnsClasses = {
    1: 'grid-cols-pipeline-1',
    2: 'grid-cols-pipeline-2',
    3: 'grid-cols-pipeline-3',
    4: 'grid-cols-pipeline-4',
    5: 'grid-cols-pipeline-5',
};

const breakpointClasses = {
    sm: {
        wrapper: 'sm:mx-auto sm:w-full sm:max-w-3xl sm:grid-cols-1 sm:gap-0',
        mobileColumnsWrapper: 'sm:grid',
        desktopColumnsWrapper: 'sm:hidden',
    },
    md: {
        wrapper: 'md:mx-auto md:w-full md:max-w-3xl md:grid-cols-1 md:gap-0',
        mobileColumnsWrapper: 'md:grid',
        desktopColumnsWrapper: 'md:hidden',
    },
    lg: {
        wrapper: 'lg:mx-auto lg:w-full lg:max-w-3xl lg:grid-cols-1 lg:gap-0',
        mobileColumnsWrapper: 'lg:grid',
        desktopColumnsWrapper: 'lg:hidden',
    },
    xl: {
        wrapper: 'xl:mx-auto xl:w-full xl:max-w-3xl xl:grid-cols-1 xl:gap-0',
        mobileColumnsWrapper: 'xl:grid',
        desktopColumnsWrapper: 'xl:hidden',
    },
    '2xl': {
        wrapper: '2xl:mx-auto 2xl:w-full 2xl:max-w-3xl 2xl:grid-cols-1 2xl:gap-0',
        mobileColumnsWrapper: '2xl:grid',
        desktopColumnsWrapper: '2xl:hidden',
    },
};

const PipelineContent = ({ columns, sortKeys, itemProps, data, mobileBP }) => {
    const [columnsStatus, setColumnsStatus] = useState(() => {
        const columnsStatus = {};
        columns.forEach((column) => {
            columnsStatus[column.key] = false;
        });

        return columnsStatus;
    });
    const handleToggleColumnStatus = (key) => setColumnsStatus((prev) => ({ ...prev, [key]: !prev[key] }));

    return (
        <div className={`grid ${columnsClasses[columns.length]} gap-8 ${breakpointClasses[mobileBP].wrapper}`}>
            {columns.map((column) => (
                <Fragment key={column.key}>
                    <PipelineLabel
                        title={column.title}
                        column={column}
                        count={data.content[column.key].length}
                        mobileBP={mobileBP}
                        open={columnsStatus[column.key]}
                        handleToggleOpen={() => handleToggleColumnStatus(column.key)}
                    />
                    <div
                        className={`hidden transform transition-all duration-300 ${
                            columnsStatus[column.key] ? 'mb-2 translate-y-0' : '-translate-y-2'
                        } ${breakpointClasses[mobileBP].mobileColumnsWrapper}`}
                        style={{
                            gridTemplateRows: columnsStatus[column.key] ? '1fr' : `0fr`,
                        }}
                    >
                        <div className="overflow-hidden">
                            {sortKeys.map((sortKey) =>
                                (() => {
                                    if (!sortKey.render) return null;
                                    const columnData = data.content[column.key].content[sortKey.key];

                                    return (
                                        <div key={sortKey.key} className="flex flex-col">
                                            <div className="col-span-full mb-4 mt-2">
                                                <PipelineSortLabel sortKey={sortKey} />
                                            </div>

                                            {columnData.length > 0 ? (
                                                <div className="mb-2 flex flex-col gap-2">
                                                    {columnData.map((item) => (
                                                        <PipelineItem
                                                            key={item.id}
                                                            itemProps={itemProps}
                                                            column={column}
                                                            item={item}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="mb-2">
                                                    {/* <NoDataPlaceholder styles={{ padding: '0.5rem 0 0.5rem 0' }} /> */}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })(),
                            )}
                        </div>
                    </div>
                </Fragment>
            ))}

            {sortKeys.map((sortKey, index) =>
                (() => {
                    if (!sortKey.render) return null;

                    return (
                        <div
                            key={sortKey.key}
                            className={`col-span-full grid gap-6 ${
                                index !== sortKeys.length - 1 ? 'mb-32' : ''
                            } ${breakpointClasses[mobileBP].desktopColumnsWrapper}`}
                            style={{
                                gridTemplateColumns: 'subgrid',
                            }}
                        >
                            <div className="col-span-full">
                                <PipelineSortLabel sortKey={sortKey} />
                            </div>
                            <div
                                key={sortKey.key}
                                className="col-span-full grid gap-6"
                                style={{ gridTemplateColumns: 'subgrid' }}
                            >
                                {columns.map((column) => (
                                    <div
                                        key={column.key}
                                        className="grid place-content-start gap-4"
                                        style={{ gridTemplateColumns: 'subgrid' }}
                                    >
                                        {(() => {
                                            const columnData = data.content[column.key].content[sortKey.key];

                                            if (columnData.length > 0)
                                                return columnData.map((item) => (
                                                    <PipelineItem
                                                        key={item.id}
                                                        itemProps={itemProps}
                                                        column={column}
                                                        item={item}
                                                    />
                                                ));

                                            return null;
                                        })()}
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })(),
            )}
        </div>
    );
};

PipelineContent.propTypes = {
    columns: PropTypes.array,
    sortKeys: PropTypes.array,
    data: PropTypes.object,
    itemProps: PropTypes.object,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']).isRequired,
};

PipelineContent.defaultProps = {
    columns: [],
    sortKeys: [],
    data: {},
    itemProps: {},
    mobileBP: 'sm',
};

export default PipelineContent;

import React, { useContext, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const GroupsSelector = ({ disabled, groups, setGroups }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [allGroups, setAllGroups] = useState([]);

    const getGroups = async () => {
        try {
            const allGroupsRes = await API.get('groups', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });
            setAllGroups(allGroupsRes.data.Groups);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getGroups();
    }, []);

    if (!user.isAdmin) return null;

    return (
        <LabelWrapper label={t('User groups that can access the resource')}>
            {loading ? (
                <div className="flex h-11 items-center justify-center rounded-md border border-layout-transparent bg-layout-transparent">
                    <CircularProgress size={20} />
                </div>
            ) : (
                <MultiDropdown
                    disabled={disabled}
                    placeholder={t('Choose options')}
                    options={allGroups.map((group) => group.name)}
                    selectedOptions={allGroups.reduce(
                        (acc, curr, index) => (groups.includes(curr.id) ? [...acc, index] : acc),
                        [],
                    )}
                    setSelectedOptions={(i) => {
                        const selectedUserId = allGroups[i].id;
                        const value = groups.includes(selectedUserId)
                            ? groups.filter((id) => id !== selectedUserId)
                            : [...groups, selectedUserId];

                        setGroups(value);
                    }}
                />
            )}
        </LabelWrapper>
    );
};

GroupsSelector.propTypes = {
    disabled: PropTypes.bool,
    groups: PropTypes.array,
    setGroups: PropTypes.func,
};

GroupsSelector.defaultProps = {
    disabled: false,
    groups: [],
    setGroups: () => {},
};

export default GroupsSelector;

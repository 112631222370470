import React, { useContext, useEffect } from 'react';
import { createPortal, render } from 'react-dom';
import { useHistory } from 'react-router-dom';

import currentDayHighlightPath from 'assets/frappe-gantt/svgs/current-day-highlight.svg';

import Gantt from 'components/frappe-gantt/frappe-gantt';
import GanttLegend from 'components/frappe-gantt/gantt-legend';
import GanttTable from 'components/frappe-gantt/gantt-table';
import GlobalContext from 'contexts/GlobalContext';
import { sanitize } from 'dompurify';
import useQuery from 'hooks/useQuery';
import CashflowTable from 'pages/financial-tool/cashflow-table';
import PLTable from 'pages/financial-tool/pl-table';
import RiemLegend from 'pages/single-pm-module/riem/components/riem-legend';
import RiemTable from 'pages/single-pm-module/riem/components/riem-table';
import { getRawImageFromPath } from 'utils';
import API from 'utils/axios';
import { getActivities, getTasks } from 'utils/ganttUtils';

const getTasksFn = {
    milestones: getTasks,
    projectStructures: getActivities,
};

const Puppeteer = () => {
    const history = useHistory();
    const [query] = useQuery();
    const components = JSON.parse(sanitize(query.get('components')));
    const { tenant, currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);

    useEffect(() => {
        if (!components.length) history.goBack();

        (async () => {
            try {
                await Promise.all(
                    components.map(async (component) => {
                        const { ids, type, element, data, request } = component;
                        const { type: elementType, purpose } = element ?? {};
                        const {
                            view,
                            customDates,
                            selectedEntities,
                            order,
                            pageOrientation,
                            exchangeRate,
                            exchangeRateDate,
                            headersData,
                            canRenderNational,
                            canRenderReference,
                        } = data ?? {};
                        const { body, params } = request ?? {};

                        if (type === 'gantt') {
                            let entries = [];
                            let tasks = [];
                            let disabledCropHeightsIntervals = [];

                            if (elementType === 'milestones') {
                                if (purpose === 'ia') {
                                    const response = await API.get('/internal_activities', { params });
                                    entries = response.data.activity.filter(
                                        (activity) => activity.activitiesTasks.length > 0,
                                    );
                                }

                                if (purpose === 'client') {
                                    const response = await API.get('/contacts_activities', { params });
                                    entries = response.data.activities.filter(
                                        (activity) => activity.activitiesTasks.length > 0,
                                    );
                                }

                                if (purpose === 'pm') {
                                    const response = await API.get('/pmProjectActivity', { params });
                                    entries = response.data.activity
                                        .map((activity) => activity.activityInfo)
                                        .filter((activity) => activity.activitiesTasks.length > 0);
                                }
                            }

                            if (elementType === 'projectStructures') {
                                const response = await API.get('/projectStructures', { params });
                                entries = response.data.data.data;
                                entries = entries.map((e) => e.Revisions.at(-1) ?? e);
                            }

                            entries = selectedEntities
                                ? selectedEntities.map((sE) => {
                                      const entry = entries.find((e) => e.id === sE);
                                      return entry;
                                  })
                                : entries;

                            [tasks, disabledCropHeightsIntervals] = getTasksFn[elementType](entries);

                            document
                                .getElementById(ids[0])
                                .setAttribute(
                                    'data-disabledCropHeightsIntervals',
                                    JSON.stringify(disabledCropHeightsIntervals),
                                );

                            const currentDayHighlightEl = await getRawImageFromPath(currentDayHighlightPath);

                            new Gantt('#gantt-container-root', tasks, {
                                language,
                                start_date: customDates?.startDate ? new Date(customDates.startDate) : null,
                                end_date: customDates?.endDate ? new Date(customDates.endDate) : null,
                                view_mode: view,
                                current_day_highlight_element: currentDayHighlightEl,
                            });

                            render(
                                <GanttTable tasks={tasks} type={elementType} />,
                                document.getElementById('gantt-table-root'),
                            );
                        }

                        if (type === 'gantt-legend') {
                            render(<GanttLegend type={elementType} />, document.getElementById('gantt-legend-root'));
                        }

                        if (type === 'riem-table') {
                            let [companyLogo, project, riems] = await Promise.all([
                                getRawImageFromPath(tenant.logoUrl),
                                API.get('/pm_projectPlanningOverview', {
                                    params: { id: params.projectId },
                                }),
                                API.get('/riem', {
                                    params,
                                }),
                            ]);

                            project = project.data.pm;
                            riems = riems.data.riem.map((r) => ({
                                ...r,
                                items: r.items.map((item) => ({
                                    ...item,
                                    identificationDate: new Date(item.identificationDate).toDateString(),
                                    lastUpdate: new Date(item.lastUpdate).toDateString(),
                                })),
                                project,
                            }));

                            render(
                                order.map((element) => (
                                    <RiemTable
                                        key={element.position}
                                        riem={riems[element.position]}
                                        companyLogo={companyLogo}
                                        renderType={pageOrientation}
                                        id={`riem-table-${element.position}`}
                                    />
                                )),
                                document.getElementById('riem-wrapper-root'),
                            );
                        }

                        if (type === 'riem-legend') {
                            let riems = await API.get('/riem', {
                                params,
                            });
                            riems = riems.data.riem;

                            render(
                                order.map((element) => (
                                    <RiemLegend
                                        key={element.position}
                                        riem={riems[element.position]}
                                        id={`riem-legend-${element.position}`}
                                    />
                                )),
                                document.getElementById('riem-legend-root'),
                            );
                        }

                        if (type === 'report') {
                            let companyLogo = null;
                            let excelData = {};

                            if (elementType === 'pl') {
                                [companyLogo, { data: excelData }] = await Promise.all([
                                    getRawImageFromPath(tenant.logoUrl),
                                    API.post(
                                        '/getCompanyPL',
                                        { ...body, dates: headersData },
                                        {
                                            params,
                                        },
                                    ),
                                ]);

                                render(
                                    <PLTable
                                        companyLogo={companyLogo}
                                        headersData={headersData}
                                        excelData={excelData}
                                        exchangeRate={exchangeRate}
                                        exchangeRateDate={exchangeRateDate}
                                        canRenderNational={canRenderNational}
                                        canRenderReference={canRenderReference}
                                        currencyObj={currencyObj}
                                        referenceCurrencyObj={referenceCurrencyObj}
                                        language={language}
                                    />,
                                    document.getElementById('report-wrapper-root'),
                                );
                            }

                            if (elementType === 'cashflow') {
                                [companyLogo, { data: excelData }] = await Promise.all([
                                    getRawImageFromPath(tenant.logoUrl),
                                    API.post(
                                        '/projectCashflow',
                                        { ...body, dates: headersData },
                                        {
                                            params,
                                        },
                                    ),
                                ]);

                                render(
                                    <CashflowTable
                                        companyLogo={companyLogo}
                                        headersData={headersData}
                                        excelData={excelData}
                                        exchangeRate={exchangeRate}
                                        exchangeRateDate={exchangeRateDate}
                                        canRenderNational={canRenderNational}
                                        canRenderReference={canRenderReference}
                                        currencyObj={currencyObj}
                                        referenceCurrencyObj={referenceCurrencyObj}
                                        language={language}
                                    />,
                                    document.getElementById('report-wrapper-root'),
                                );
                            }
                        }
                    }),
                );
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    return createPortal(
        <>
            <div
                id="gantt-wrapper-root"
                className="gantt-wrapper relative table overflow-hidden rounded-md border-none"
                data-disabledCropHeightsIntervals={JSON.stringify([])}
            >
                <div id="gantt-table-root" className="table-cell align-top"></div>
                <div id="gantt-container-root" className="table-cell align-top"></div>
            </div>

            <div id="gantt-legend-root" className="min-w-max max-w-6xl"></div>

            <div id="riem-wrapper-root" className="min-w-max max-w-max"></div>

            <div id="riem-legend-root" className="min-w-max max-w-max"></div>

            <div id="report-wrapper-root" className="min-w-max max-w-max"></div>
        </>,
        document.getElementById('hidden-root'),
    );
};

export default Puppeteer;

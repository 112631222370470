import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import GroupsSelector from 'components/shared/groups-selector/groups-selector';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { errorHandling, formatPositiveNumber } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const CreateStockModal = ({ onClose, articleId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [isCreating, setIsCreating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ name: '', numberOfArticles: 1, originalArticleId: null, groups: [] });
    const [articles, setArticles] = useState([]);

    const handleFormDataChange = (e) => setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const schema = yup.object().shape({
        name: yup.string().typeError(t('The stock name is required!')).required(t('The stock name is required!')),
        originalArticleId: yup
            .string()
            .typeError(t('The article is required!'))
            .required(t('The article is required!')),
        numberOfArticles: yup
            .number()
            .typeError(t('The number of items value is required!'))
            .min(1, t('You must have at least have one item!'))
            .required(t('The number of items value is required!')),
    });

    const handleCreateStock = async () => {
        try {
            setIsCreating(true);
            await schema.validate(formData);

            const res = await API.post('/stock', formData);

            enqueueSnackbar(t('Stock created successfully!'), { variant: 'success' });
            history.push(
                quotes.base +
                    quotes.nomenclature.base +
                    quotes.stocks.base +
                    quotes.stocks.update +
                    '/' +
                    res.data.stock.id,
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsCreating(false);
        }
    };

    useEffect(
        () =>
            (async () => {
                try {
                    const response = await API.get('/articles', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                            type: null,
                            categorieId: null,
                            many: 'ALL',
                        },
                    });
                    const articleRes = response.data.articles;

                    if (articleId) {
                        const articleIdx = articleRes.findIndex((a) => a.id === articleId);
                        setFormData((prev) => ({ ...prev, originalArticleId: articleRes[articleIdx].id }));
                    }
                    setArticles(articleRes);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })(),
        [articleId],
    );

    return loading ? (
        <div className="flex h-64 w-full items-center justify-center">
            <CircularProgress />
        </div>
    ) : (
        <div className="flex w-full flex-col gap-4 px-12 py-12 sm:px-6">
            <div className="flex items-start justify-between gap-16">
                <h2 className="text-center">
                    {t('Create stock')}
                    {formData.originalArticleId
                        ? ` - ${articles.find((a) => a.id === formData.originalArticleId).name}`
                        : ''}
                </h2>

                <CloseIcon
                    className="text-prim ml-10 cursor-pointer text-primary-main opacity-75 hover:opacity-100"
                    style={{
                        fontSize: '2.5rem',
                        transition: 'opacity .2s ease',
                    }}
                    onClick={onClose}
                />
            </div>

            <div className="my-6 flex items-center gap-2">
                <ErrorOutlineIcon className="text-warning" />
                <p className="text-lg text-warning">
                    {t('The the stock items value will be taken into the financial reports as a forecasted value!')}
                </p>
            </div>

            <div className="flex flex-col gap-6 sm:flex-wrap">
                <GroupsSelector
                    groups={formData.groups}
                    setGroups={(value) =>
                        handleFormDataChange({
                            target: {
                                name: 'groups',
                                value,
                            },
                        })
                    }
                />

                {!articleId && (
                    <LabelWrapper label={t('Select one article in order to create a stock')}>
                        <Dropdown
                            placeholder={t('Select article')}
                            options={articles.map((article) => article.name)}
                            selectedOption={(() => {
                                const idx = articles.findIndex((a) => a.id === formData.originalArticleId);
                                return idx >= 0 ? idx : null;
                            })()}
                            setSelectedOption={(i) =>
                                handleFormDataChange({
                                    target: {
                                        name: 'originalArticleId',
                                        value: articles[i].id,
                                    },
                                })
                            }
                        />
                    </LabelWrapper>
                )}

                <LabelWrapper label={t('Stock name')}>
                    <TextField
                        placeholder={t('Name')}
                        value={formData.name}
                        name="name"
                        onChange={handleFormDataChange}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('Insert the initial number of items for the stock')}>
                    <TextField
                        placeholder={t('Number of items')}
                        value={formData.numberOfArticles}
                        type="number"
                        name="numberOfArticles"
                        onChange={(e) =>
                            handleFormDataChange({
                                target: {
                                    name: e.target.name,
                                    value: formatPositiveNumber(e.target.value),
                                },
                            })
                        }
                    />
                </LabelWrapper>
            </div>

            <div className="mt-6 flex justify-between">
                <Button disabled={isCreating} color="secondary" onClick={handleCreateStock}>
                    {!isCreating ? t('Create') : <CircularProgress size={20} />}
                </Button>
            </div>
        </div>
    );
};

CreateStockModal.propTypes = {
    onClose: PropTypes.func,
    articleId: PropTypes.string,
};

CreateStockModal.defaultProps = {
    onClose: () => {},
    articleId: null,
};

export default CreateStockModal;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ClipboardCheck, ClipboardList, KanbanSquare, Rows3, Users } from 'lucide-react';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ReactComponent as CalendarBlankIcon } from 'assets/pipelines/svgs/calendar-blank-icon.svg';
import { ReactComponent as CalendarRangeIcon } from 'assets/pipelines/svgs/calendar-range-icon.svg';
import { ReactComponent as CalendarSelectionIcon } from 'assets/pipelines/svgs/calendar-selection-icon.svg';
import { ReactComponent as ClientIcon } from 'assets/pipelines/svgs/client-icon.svg';
import { ReactComponent as CurrentDayIcon } from 'assets/pipelines/svgs/current-day-icon.svg';
import { ReactComponent as HighestIcon } from 'assets/pipelines/svgs/highest-icon.svg';
import { ReactComponent as LastThirtyDaysIcon } from 'assets/pipelines/svgs/last-thirty-days-icon.svg';
import { ReactComponent as LowestIcon } from 'assets/pipelines/svgs/lowest-icon.svg';
import { ReactComponent as RecentIcon } from 'assets/pipelines/svgs/recent-icon.svg';
import { ReactComponent as SpecificIcon } from 'assets/pipelines/svgs/specific-icon.svg';

import { Button } from '@material-ui/core';

import Filter from 'components/shared/filter/filter';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PipelineCompactContent from 'components/shared/pipelines/pipeline-compact-content';
import PipelineContent from 'components/shared/pipelines/pipeline-content';
import Sort from 'components/shared/sort/sort';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, internalActivity, projectInManagement } from 'routes';
import { errorHandling, formatDate, getDayBeginningAndEnding } from 'utils';
import API from 'utils/axios';
import {
    getDepartments as getDepartmentsFn,
    getPmDepartments,
    getProjectUsers,
    getTenantUsers,
} from 'utils/getterFunctions';

const DEFAULT_PIPELINE_STATE = {
    length: 0,
    content: {
        TODO: {
            length: 0,
            content: {
                REST: [],
            },
        },
        DONE: {
            length: 0,
            content: {
                REST: [],
            },
        },
    },
};

const DEFAULT_COMPACT_PIPELINE_STATE = {
    length: 0,
    content: {
        REST: {
            length: 0,
            content: [],
        },
    },
};

const VIEW_TYPES = ['CLASSIC', 'COMPACT'];

const COLUMNS_BREAKPOINTS = {
    5: '2xl',
    4: 'xl',
    3: 'lg',
    2: 'md',
    1: 'sm',
};

const TASKS_ROUTES = {
    IA: 'pipeline_tasks',
    CLIENT: 'pipeline_tasks_client',
    PROJECT: 'pipeline_tasks_project',
};

const DEPARTMENTS_FUNCTIONS = {
    IA: () => getDepartmentsFn(),
    CLIENT: () => getDepartmentsFn(),
    PROJECT: () => getPmDepartments(),
};

const RESPONSIBLE_FUNCTIONS = {
    IA: () => getTenantUsers(),
    CLIENT: () => getTenantUsers(),
    PROJECT: (params) => getProjectUsers(params),
};

const TasksPipeline = ({ purpose }) => {
    const { t } = useTranslation();

    const { id: clientId, projectId: pmProjectId } = useParams();

    const timelineRoutes = {
        IA: internalActivity.base + internalActivity.timeline.base,
        CLIENT: crm.base + crm.contactActivity.base + '/' + clientId,
        PROJECT: projectInManagement.base + '/' + pmProjectId + projectInManagement.projectsTaskManagement.base,
    };

    const params = {
        IA: {
            tasks: {},
            departments: {},
            responsible: {},
        },
        CLIENT: {
            tasks: {
                clientId,
            },
            departments: {},
            responsible: {},
        },
        PROJECT: {
            tasks: { pmProjectId },
            departments: {},
            responsible: { pmProjectId },
        },
    };

    const types = [
        { id: 'task', name: t('Task') },
        { id: 'milestone', name: t('Milestone') },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('CLASSIC');

    const [responsible, setResponsible] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [tasks, setTasks] = useState([]);
    const [formattedTasks, setFormattedTasks] = useState({
        classic: DEFAULT_PIPELINE_STATE,
        compact: DEFAULT_COMPACT_PIPELINE_STATE,
    });

    const { checkPerm } = useContext(UserContext);

    const canViewIA = checkPerm([
        {
            permissionId: '1',
            permissionType: 'VIEW',
        },
    ]);

    const canViewClient = checkPerm([
        {
            permissionId: '5',
            permissionType: 'VIEW',
        },
    ]);

    const canViewProject = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canView = useMemo(
        () => ({
            IA: canViewIA,
            CLIENT: canViewClient,
            PROJECT: canViewProject,
        }),
        [canViewIA, canViewClient, canViewProject],
    );

    const statuses = [
        { id: 'TODO', label: t('To do') },
        { id: 'DONE', label: t('Done') },
    ];

    const [filter, setFilter] = useState([]);
    const filterOptions = [
        {
            id: 'statuses',
            icon: <Rows3 />,
            label: t('Statuses'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Statuses'),
                    render: (value) => {
                        let statusesString = '';
                        value.forEach((v, index) => {
                            const status = statuses.find((t) => t.id === v).label;
                            statusesString += `${status}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return statusesString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: statuses,
                        label: t('Select statuses'),
                        render: (item) => item.label,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'responsible-group',
            icon: <ClientIcon />,
            label: t('Responsible'),
            data: null,
            list: [
                {
                    id: 'responsibleId',
                    icon: <RecentIcon />,
                    label: 'Recent',
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: t('Responsible'),
                            render: (value) => responsible.find((client) => client.id === value).profile.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'list',
                                options: responsible.slice(0, 4),
                                label: t('Select responsible'),
                                render: (item) => ({
                                    icon: <ClientIcon />,
                                    label: item.profile.name,
                                }),
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'responsibleId',
                    icon: <SpecificIcon />,
                    label: t('Specific responsible'),
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: t('Responsible'),
                            render: (value) => responsible.find((client) => client.id === value).profile.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'select',
                                options: responsible,
                                label: t('Select responsible'),
                                render: (item) => item.profile.name,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'date-group',
            icon: <CalendarBlankIcon />,
            label: t('Date'),
            data: null,
            list: [
                {
                    id: 'date',
                    icon: <CurrentDayIcon />,
                    label: t('Today'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) => formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <LastThirtyDaysIcon />,
                    label: t('Last 30 days'),
                    data: {
                        defaultValue: {
                            startDate: (() => {
                                const date = getDayBeginningAndEnding(new Date()).startDate;
                                date.setDate(date.getDate() - 29);
                                return date;
                            })(),
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarSelectionIcon />,
                    label: t('Selected date'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) => formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate', 'endDate'],
                                type: 'date',
                                options: null,
                                label: t('Choose a date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarRangeIcon />,
                    label: t('Selection range'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date()).startDate,
                            endDate: getDayBeginningAndEnding(new Date()).endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the end date'),
                                render: null,
                            },
                            {
                                key: ['endDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the start date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        // {
        //     id: 'type',
        //     icon: <AssignmentOutlinedIcon />,
        //     label: t('Type'),
        //     data: {
        //         defaultValue: null,
        //         filterData: {
        //             label: t('Type'),
        //             render: (value) => types.find((u) => u.id === value).name,
        //             metadata: {},
        //         },
        //         inputs: [
        //             {
        //                 key: ['id'],
        //                 type: 'select',
        //                 options: types,
        //                 label: t('Select type'),
        //                 render: (item) => item.name,
        //             },
        //         ],
        //     },
        //     list: null,
        // },
        {
            id: 'departmentsIds',
            icon: <ListAltIcon />,
            label: t('Departments'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Departments'),
                    render: (value) => {
                        let typesString = '';
                        value.forEach((v, index) => {
                            const type = departments.find((t) => t.id === v).name;
                            typesString += `${type}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return typesString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: departments,
                        label: t('Select departments'),
                        render: (item) => item.name,
                    },
                ],
            },
            list: null,
        },
    ];

    const columns = useMemo(() => {
        const defCols = [
            {
                title: t('To do'),
                key: 'TODO',
                color: 'var(--main-text)',
                backgroundColor: '#083469',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('Done'),
                key: 'DONE',
                color: 'var(--main-text)',
                backgroundColor: '#3EC356',
                borderColor: 'var(--layout-transparent)',
            },
        ];

        const statusesFilter = filter.find((f) => f.key === 'statuses');
        if (statusesFilter) return defCols.filter((c) => statusesFilter.value.includes(c.key));

        return defCols;
    }, [filter]);

    const sortKeys = [
        {
            key: 'REST',
            title: t('All tasks'),
            tags: [],
            render: true,
        },
    ];

    const [sort, setSort] = useState([
        {
            key: 'startDate',
            label: t('Start date'),
            value: 'desc',
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
        {
            key: 'endDate',
            label: t('End date'),
            value: null,
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
    ]);

    const mappedStatusData = {
        TODO: {
            label: t('To do'),
            icon: <ClipboardList />,
        },
        DONE: {
            label: t('Done'),
            icon: <ClipboardCheck />,
        },
    };

    const itemProps = {
        header: {
            status: (item) => mappedStatusData[item.metadata.pipelineStatus],
            tags: () => [],
        },
        title: {
            icon: <Users />,
            render: (item) =>
                item.users
                    .filter((u) => u.userType === 'RESPONSIBLE')
                    .map((u) => u.user.profile.name)
                    .join(', '),
        },
        head: {
            render: (item) => item.title,
        },
        body: {
            renderVisibleRows: (item) => [
                {
                    label: t('Type'),
                    content: item.activitiesTasks.length > 0 ? t('Milestone') : t('Task'),
                    canRender: true,
                },
                {
                    label: t('Department'),
                    content: item.departament.name,
                    canRender: true,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    label: t('Start date'),
                    content:
                        item.activitiesTasks.length > 0
                            ? formatDate(item.activitiesTasks.at(0).estimatedStartDate)
                            : formatDate(item.startDate),
                    canRender: true,
                },
                {
                    label: t('End date'),
                    content:
                        item.activitiesTasks.length > 0
                            ? formatDate(item.activitiesTasks.at(-1).estimatedEndDate)
                            : formatDate(item.endDate ?? item.startDate),
                    canRender: true,
                },
                {
                    label: t('No. comments'),
                    content: item.comments.length,
                    canRender: true,
                },
            ],
        },
    };

    const getResponsible = async () => {
        try {
            const response = await RESPONSIBLE_FUNCTIONS[purpose](params[purpose].responsible);
            setResponsible(response);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getDepartments = async () => {
        try {
            const response = await DEPARTMENTS_FUNCTIONS[purpose](params[purpose].departments);
            setDepartments(response);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleFormatTasks = (tasks) => {
        const { classic, compact } = tasks.reduce(
            (acc, curr) => ({
                classic: {
                    ...acc.classic,
                    length: tasks.length,
                    content: {
                        ...acc.classic.content,
                        [curr.status]: {
                            ...acc.classic.content[curr.status],
                            length: acc.classic.content[curr.status].length + 1,
                            content: {
                                ...acc.classic.content[curr.status].content,
                                ['REST']: [
                                    ...acc.classic.content[curr.status].content['REST'],
                                    {
                                        ...curr,
                                        metadata: {
                                            ...curr.metadata,
                                            pipelineStatus: curr.status,
                                            pipelineKey: 'REST',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                compact: {
                    ...acc.compact,
                    length: tasks.length,
                    content: {
                        ...acc.compact.content,
                        ['REST']: {
                            length: acc.compact.content['REST'].length + 1,
                            content: [
                                ...acc.compact.content['REST'].content,
                                {
                                    ...curr,
                                    metadata: {
                                        ...curr.metadata,
                                        pipelineStatus: curr.status,
                                        pipelineKey: 'REST',
                                    },
                                },
                            ],
                        },
                    },
                },
            }),
            { classic: DEFAULT_PIPELINE_STATE, compact: DEFAULT_COMPACT_PIPELINE_STATE },
        );

        return { classic, compact };
    };

    const getTasks = async (sort, filter) => {
        try {
            const querySort = sort
                .filter((element) => element.value !== null)
                .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

            const queryFilter = filter.reduce(
                (acc, curr) => ({ ...acc, [curr.metadata.backendKey ?? curr.key]: curr.value }),
                {},
            );

            const response = await API.get(TASKS_ROUTES[purpose], {
                params: { ...querySort, ...queryFilter, ...params[purpose].tasks },
            });
            const formattedTasks = handleFormatTasks(response.data.tasks, sort);
            setFormattedTasks(formattedTasks);
            setTasks(response.data.tasks);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    useEffect(() => {
        if (!canView[purpose]) history.push('/');

        (async () => {
            try {
                await Promise.all([getTasks(sort, filter), getDepartments(), getResponsible()]);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t(errorHandling(error)), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [canView]);

    const handleSort = async (sort, lastAppliedSort) => {
        try {
            setLoading(true);
            await Promise.all([getTasks(sort, filter), getDepartments(), getResponsible()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = async (filter) => {
        try {
            setLoading(true);
            await Promise.all([getTasks(sort, filter), getDepartments(), getResponsible()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Tasks pipeline')}</title>
            </Helmet>

            <Header
                pageTitle={t('Tasks pipeline')}
                pageIcon={<KanbanSquare />}
                action={
                    <>
                        <Button
                            color="secondary"
                            startIcon={<VisibilityIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() => history.push(timelineRoutes[purpose])}
                        >
                            {t('View timeline activities')}
                        </Button>
                    </>
                }
                toolbar={
                    <div className="flex w-full items-center justify-between gap-3">
                        <Tabs
                            tabs={VIEW_TYPES.map((type) => t(type))}
                            activeTab={VIEW_TYPES.indexOf(view)}
                            setActiveTab={(i) => setView(VIEW_TYPES[i])}
                        />
                        <Sort
                            disabled={loading || formattedTasks.length === 0}
                            sort={sort}
                            setSort={setSort}
                            onSort={handleSort}
                        />
                    </div>
                }
                toolbarSecondary={
                    <Filter
                        disabled={loading}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                        mobileBP="md"
                    />
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '70vh' }} />
                ) : tasks.length ? (
                    <>
                        {view === 'CLASSIC' && (
                            <PipelineContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedTasks.classic}
                                mobileBP={COLUMNS_BREAKPOINTS[columns.length]}
                            />
                        )}
                        {view === 'COMPACT' && (
                            <PipelineCompactContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedTasks.compact}
                            />
                        )}
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </>
    );
};

TasksPipeline.defaultProps = {
    purpose: 'IA',
};

TasksPipeline.propTypes = {
    purpose: PropTypes.oneOf(['IA', 'CLIENT', 'PROJECT']).isRequired,
};

export default TasksPipeline;

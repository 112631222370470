import React, { useCallback, useEffect, useState } from 'react';

import Gantt from 'components/planning-overview/gantt/gantt';
import GantContext from 'contexts/GantContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import { getResources } from 'utils/getterFunctions';

const PMPlanningOverview = () => {
    const { t } = useTranslation();

    const [projects, setProjects] = useState([]);

    const [internalEmployees, setInternalEmployees] = useState([]);
    const [externalEmployees, setExternalEmployees] = useState([]);

    const fetchEmployees = useCallback(async () => {
        const resources = await getResources('internal');
        setInternalEmployees(resources.internalEmployees);
        setExternalEmployees(resources.externalEmployees);
    }, []);

    useEffect(() => {
        (async () => {
            await fetchEmployees();

            try {
                const res = await API.get('/pm_projectsPlanningOverview', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                    },
                });

                setProjects(res.data.projects);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Planning overview on projects')}</title>
            </Helmet>

            <Header pageTitle={t('Planning overview on projects')} />

            <GantContext.Provider
                value={{
                    viewOnly: true,
                    internalEmployees,
                    externalEmployees,
                    type: 'pm',
                }}
            >
                <Gantt projects={projects} viewOnly />
            </GantContext.Provider>
        </>
    );
};

export default PMPlanningOverview;

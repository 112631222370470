import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import MilestonePipeline from 'components/internal-activity/milestone-pipeline';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import { Header, Search, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const MilestonesPipeline = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const [milestones, setMilestones] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '2',
            permissionType: 'VIEW',
        },
    ]);

    function checkActiveTab() {
        if (activeTab === 0) {
            return 'LEADS_TO_POTENTIAL';
        } else if (activeTab === 1) {
            return 'OFFER';
        } else if (activeTab === 2) {
            return 'CLIENT_ACTIVITY';
        }
    }

    useEffect(() => {
        if (!canView) {
            history.push('/');
            return;
        }

        (async () => {
            setLoading(true);
            try {
                const type = checkActiveTab();

                const res = await API.get('/getActivePipeline', {
                    params: { currentPage: 0, perPage: 99999, pagesToLoad: 1, pipelineType: type },
                });

                setMilestones(res.data.data.milestones);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [canView, activeTab]);

    return (
        <>
            <Helmet>
                <title>MilestonesPipeline</title>
            </Helmet>

            <Header
                pageTitle={
                    <div className="flex w-full flex-wrap items-center justify-between gap-6">
                        <span>MilestonesPipeline</span>
                        <Search searchIconBig={true} />
                    </div>
                }
                toolbar={
                    <Tabs
                        tabs={[t('Leads to potential'), t('Offers'), t('Clients Activity')]}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                }
            />

            {canView && (
                <div className="page-container pipeline-container">
                    {loading ? (
                        <div className="mb-2 flex items-center justify-center rounded-md bg-layout-transparent px-8 py-20">
                            <CircularProgress />
                        </div>
                    ) : milestones.length > 0 ? (
                        <div className="flex flex-wrap gap-28">
                            {milestones.map((milestone) => (
                                <MilestonePipeline key={milestone.id} milestone={milestone} />
                            ))}
                        </div>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </div>
            )}
        </>
    );
};

export default MilestonesPipeline;

import React, { cloneElement } from 'react';

import { Box } from 'lucide-react';

import PropTypes from 'prop-types';

const Tag = ({ tag }) => {
    const { icon, label, color, backgroundColor, borderColor } = tag;

    return (
        <div
            className="flex h-full items-center gap-3 rounded-xl border px-4"
            style={{
                backgroundColor,
                borderColor,
            }}
        >
            {cloneElement(icon, {
                style: {
                    color,
                    flexShrink: 0,
                },
            })}
            <p className="text-lg leading-4" style={{ color }}>
                {label}
            </p>
        </div>
    );
};

Tag.propTypes = {
    tag: PropTypes.object,
};

Tag.defaultProps = {
    tag: {
        icon: <Box />,
        label: 'Placeholder',
        color: 'var(--main-text)',
        backgroundColor: 'var(--primary-main)',
        borderColor: 'var(--layout-transparent)',
    },
};

export default Tag;

import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(() => {
    return {
        gradient: {
            backgroundImage: 'linear-gradient(to right, var(--gradient-primary-dark), var(--gradient-primary-light))',
            color: `var(--gradient-text)`,
            '&:hover': {
                color: `var(--gradient-primary-light)`,
            },
        },
    };
});

const GradientButton = ({ children, className, ...props }) => {
    const { gradient } = useStyles();
    const classes = `${gradient} group relative overflow-hidden z-10 ${className}`;

    return (
        <Button {...props} className={classes}>
            {children}
            <div
                className="pointer-events-none absolute left-0 top-0 h-full w-full bg-gradient-to-r from-gradient-primary-light via-gradient-text to-gradient-text opacity-0 transition-opacity group-hover:opacity-100"
                style={{
                    zIndex: 'calc(-infinity)',
                    transitionDuration: '250ms',
                }}
            />
        </Button>
    );
};

GradientButton.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
};

GradientButton.defaultProps = {
    children: null,
    className: '',
};

export default GradientButton;

import React, { useContext, useMemo } from 'react';

import Comments from 'components/shared/comments/comments';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';

import WikiBody from './wiki-body';
import WikiFiles from './wiki-files';
import WikiHeader from './wiki-header';

const Wiki = ({ wiki, disabled, onDeleteWiki, onDeleteFile, onAddComment, onDeleteComment, openEdit }) => {
    const { user, checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const canAllAffiliate =
        user.isAffiliate &&
        checkPerm([
            {
                permissionId: '19',
                permissionType: 'VIEW',
            },
        ]);

    const { comments } = wiki;
    const formattedComments = useMemo(
        () =>
            comments.map((element) => ({
                ...element,
                text: element.comment,
                files: element.files.map((file) => ({ ...file, id: file.fileId, url: file.urls })),
            })),
        [comments],
    );

    return (
        <div className="flex w-full flex-col gap-8 border-b border-layout-transparent py-16">
            <WikiHeader wiki={wiki} disabled={disabled} onDeleteWiki={onDeleteWiki} openEdit={openEdit} />
            <WikiBody wiki={wiki} />
            <WikiFiles wiki={wiki} disabled={disabled} onDeleteFile={onDeleteFile} />
            <Comments
                comments={formattedComments}
                onSubmit={onAddComment.bind(null, wiki.id)}
                onDelete={onDeleteComment.bind(null, wiki.id)}
                disabled={disabled}
                canAdd={canAll || canAllAffiliate}
                canDelete={canAll}
            />
        </div>
    );
};

Wiki.propTypes = {
    wiki: PropTypes.object,
    disabled: PropTypes.bool,
    onDeleteFile: PropTypes.func,
    onDeleteWiki: PropTypes.func,
    onAddComment: PropTypes.func,
    onDeleteComment: PropTypes.func,
    openEdit: PropTypes.func,
};

Wiki.defaultProps = {
    wiki: {},
    disabled: false,
    onDeleteFile: () => {},
    onDeleteWiki: () => {},
    onAddComment: () => {},
    onDeleteComment: () => {},
    openEdit: () => {},
};

export default Wiki;

/**
 * The function `getCheckValues` calculates various values and checks for different conditions based on
 * an input array and a case type.
 * @param {Array} array - The `array` parameter is an array of values that represent the status of tasks or
 * activities. Each value can be one of the following: 'DONE', 'IN_PROGRESS', or
 * 'PARTIAL_COMPLETED'.
 * @param {String} isCase - The `isCase` parameter is a string that determines the case for which the check
 * values are calculated. It can have two possible values: "tasks" or "subMilestones".
 * @returns {object} The function `getCheckValues` returns an object with the following properties: countInProgress, countDoneTasks, checkWorkInProgress, checkPartialCompleted, checkCompeted,
 */
const getCheckValues = (array, isCase) => {
    let checkPartialCompleted = false;

    // ? We count the number of finished entities
    const countDoneTasks = array.reduce((sum, cur) => {
        if (cur === 'DONE') {
            return (sum += 1);
        } else {
            return sum;
        }
    }, 0);

    // ? We count the number of in progress entities
    const countInProgress = array.reduce((sum, cur) => {
        if (cur === 'IN_PROGRESS') {
            return (sum += 1);
        } else {
            return sum;
        }
    }, 0);

    // ? We determine if the entity is a work in progress by checking if we have more then one entity witch is a work in progress
    const checkWorkInProgress = countInProgress > 0;

    // ? In the case of tasks we determine if the entity is partially completed by checking if the number of finished entities is more then 0 and less then the total length of the entities
    if (isCase === 'tasks') checkPartialCompleted = countDoneTasks > 0 && countDoneTasks < array.length;

    // ? In the case of activities we determine if the entity is partially completed by checking if the number of finished entities is more then 0 and less then the total length of the entities and at least one of its tasks is partially completed
    if (isCase === 'subMilestones')
        checkPartialCompleted =
            array.indexOf('PARTIAL_COMPLETED') > -1 || (countDoneTasks > 0 && countDoneTasks < array.length);

    // ? We determine if the entity is completed by checking if we have more then one entity witch is finished and the length of done entities is equal to the number of entities array
    const checkCompeted = countDoneTasks > 0 && countDoneTasks === array.length;

    return {
        countInProgress,
        countDoneTasks,
        checkWorkInProgress,
        checkPartialCompleted,
        checkCompeted,
    };
};

/**
 * The function `getTaskInfo` takes an array of task statuses and returns the overall status and
 * completion percentage of the tasks.
 * @param {Array} statusArray - The `statusArray` parameter is an array that contains the status of tasks. It
 * is used to determine the overall status and progress of the tasks.
 * @returns The function `getTaskInfo` returns an object with two properties: `status` and `percent`.
 */
const getTaskInfo = (statusArray, t) => {
    // ? We hold the status value and text into an object
    let status = { value: '', translation: '' };

    // ? We determine the status of completion for this task
    const { countDoneTasks, countInProgress, checkWorkInProgress, checkPartialCompleted, checkCompeted } =
        getCheckValues(statusArray, 'tasks');

    // ? We calculate the percentage completion of the task
    const percent = ((countDoneTasks + countInProgress / 2) / statusArray.length) * 100;

    // ? We set the correct value and text based on the completion status
    if (checkWorkInProgress)
        status = {
            value: 'IN_PROGRESS',
            translation: t('Work in progress'),
        };
    else if (checkPartialCompleted)
        status = {
            value: 'PARTIAL_COMPLETED',
            translation: t('Partial completed'),
        };
    else if (checkCompeted) status = { value: 'DONE', translation: t('Finalized') };
    else status = { value: 'NEW', translation: t('New') };

    return { status, percent };
};

/**
 * The function `getMilestoneInfo` takes a milestone object as input and returns information about the
 * milestone's status, percentage completion, and a formatted HTML string of the milestone's activities
 * and subtasks.
 * @param {object} milestone - The `milestone` parameter is an object that represents a milestone. It contains
 * the following properties:
 * @returns {object} The function `getMilestoneInfo` returns an object with three properties: `milestoneStatus`,
 * `milestonePercent`, and `activityHTML`.
 */
const getMilestoneInfo = (milestone, t) => {
    // ? We need the spacing in order to properly align the content
    const spacingHTML = `\u200B\t\u200B\t`;

    // ? We create the title for all activities of the milestone
    let activityHTML = `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${t(
        'Milestone Objectives Details',
    )}:</p>`;

    // ? We need an array with the status and percent of all activities in order to determine the activity completion status
    const subMilestonesArray = [];
    let milestoneStatus = '';

    const getStatusTranslation = (status) => {
        if (status === 'NEW') return t('New');
        if (status === 'IN_PROGRESS') return t('Work in progress');
        if (status === 'DONE') return t('Finalized');
    };

    milestone.activitiesTasks.forEach((activity, index) => {
        // ? We get the information about the current activity
        const { status, percent } = getTaskInfo(
            activity.subTasks.map((subTask) => subTask.status),
            t,
        );

        // ? We push the current activity status and percentage into the list
        subMilestonesArray.push({ status: status.value, percent });

        // ? We add the activity subtitle
        activityHTML += `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${index + 1}. ${
            activity.name
        } - <strong>${percent.toFixed(2)}% / ${status.translation}</strong></p>`;

        // ? We add an empty row used for spacing
        activityHTML += '<p style="font-size: 12px;"></p>';

        // ? We add the list with all the tasks and their status
        activity.subTasks.forEach((subTask, stIndex) => {
            activityHTML += `<p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${spacingHTML}${
                stIndex + 1
            }. ${subTask.name} - <strong>${getStatusTranslation(subTask.status)}</strong></p>`;
        });

        // ? If the activity is not the last one we add a new row for spacing
        if (index !== milestone.activitiesTasks.length - 1) activityHTML += '<p style="font-size: 12px;"></p>';
    });

    // ? We determine the status of completion for this activity
    const { checkWorkInProgress, checkPartialCompleted, checkCompeted } = getCheckValues(
        subMilestonesArray.map((subMilestone) => subMilestone.status),
        'subMilestones',
    );

    // ? We add the correct text based on the completion status
    if (checkWorkInProgress) milestoneStatus = t('Work in progress');
    else if (checkPartialCompleted) milestoneStatus = t('Partial completed');
    else if (checkCompeted) milestoneStatus = t('Finalized');
    else milestoneStatus = t('New');

    // ? We calculate the milestone percentage based on all of the activities percentage
    const milestonePercent =
        subMilestonesArray.reduce((sum, cur) => (sum += cur.percent), 0) / subMilestonesArray.length;

    return { milestoneStatus, milestonePercent, activityHTML };
};

/**
 * The function `generateMilestoneContent` generates HTML content for displaying milestones information,
 * including milestone status, description, achievement percentage, and related activities.
 * @param {object} data - Includes what the info about the company or project, the purpose being one of these two, and the all milestones content, plus the selected list
 * @returns {string} HTML content.
 */
export const generateMilestoneContent = (data, t) => {
    const emptyRowSpacingHTML = `\u200B\t`;
    const { purpose, info, selectedMilestones, milestones } = data;

    // ? We create the title of the notification
    let contentHtml = `<h2 style="text-align: center; font-size: 24px; color: rgb(0,0,0)">${
        purpose === 'CRM' ? info.companyName : purpose === 'PM' ? info.name : ''
    }</h2><p style="font-size: 12px;">${emptyRowSpacingHTML}</p>`;

    // ? We need a percentage list of all milestone in order to calculate a general one
    const milestonePercentArray = [];

    // ? The code block is iterating over each selected milestone and generating HTML content for each milestone.
    selectedMilestones.forEach((milestoneId, index) => {
        // ? We need the spacing in order to properly align the content
        const spacingHTML = `\u200B\t\u200B\t\u200B\t\u200B\t`;
        const currentMilestone = milestones.find((m) => m.id === milestoneId);
        const milestoneNumber = index + 1;

        // ? We get the information about the current milestone
        const { milestoneStatus, milestonePercent, activityHTML } = getMilestoneInfo(currentMilestone, t);

        // ? We push the current milestone percentage into the list
        milestonePercentArray.push(milestonePercent);

        // ? We create the milestone subtitle
        let milestoneHTML = `<p style="font-size: 12px; color: rgb(0,0,0)"><strong>${milestoneNumber}.</strong> ${currentMilestone.title}: <strong>${milestoneStatus}</strong></p>`;

        // ? We check if we edited a line and new we start with an empty row. In the case of an empty row we only append the content to that, otherwise we create a new row
        milestoneHTML += currentMilestone.description.startsWith('<p>')
            ? `${currentMilestone.description}`
            : `<p>${currentMilestone.description}</p>`;

        // ? We append the milestone percentage
        milestoneHTML += `<p style="font-size: 12px;">${emptyRowSpacingHTML}</p><p style="font-size: 12px; color: rgb(0,0,0)">${spacingHTML}${t(
            'MILESTONE {{milestoneNumber}} ACHIEVEMENT PERCENTAGE, RELATED TO STAGE',
            { milestoneNumber },
        )}: <strong>${milestonePercent.toFixed(2)}%</strong></p><p style="font-size: 12px;">${emptyRowSpacingHTML}</p>`;

        // ? We append the activities content of this milestone
        milestoneHTML += `${activityHTML} ${
            milestoneNumber !== selectedMilestones.length
                ? `<p style="font-size: 12px;">${emptyRowSpacingHTML}</p><p style="font-size: 12px;">${emptyRowSpacingHTML}</p><p style="font-size: 12px;">${emptyRowSpacingHTML}</p>`
                : `</p><p style="font-size: 12px;">${emptyRowSpacingHTML}</p>`
        }`;

        // ? We append everything to the main content
        contentHtml += milestoneHTML;
    });

    // ? We calculate the general percentage of all milestones
    const generalPercent = milestonePercentArray.reduce((sum, cur) => (sum += cur), 0) / milestonePercentArray.length;

    // ? We append the general percentage
    contentHtml += `<p style="font-size: 12px;"><strong>${t(
        'PERCENTAGE OF COMPLETION STAGE',
    )}: ${generalPercent.toFixed(2)}%</strong></p>`;

    return contentHtml;
};

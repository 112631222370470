import React, { useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import BasicTooltip from 'components/shared/basic-tooltip';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { crm, internalActivity } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const PlanningOverviewProject = ({ projectInfo }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(true);

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [projectName, setProjectName] = useState(null);

    const [projectStructures, setProjectStructures] = useState([]);
    const [selectedProjectStructure, setSelectedProjectStructure] = useState(null);

    const [teams, setTeams] = useState([]);

    const defaultTeam = {
        order: teams.length + 1,
        name: null,
    };

    const editTeam = (index, key, value) => {
        const newTeams = [...teams];
        newTeams[index][key] = value;
        setTeams(newTeams);
    };

    const deleteTeam = (index) => {
        setTeams(teams.filter((_, i) => i !== index));
    };

    let schema = yup.object().shape({
        teams: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('The name of the team is required!'))
                        .required(t('The name of the team is required!')),
                }),
            )
            .min(1, t('The project must have at least one team!'))
            .required(t('The project must have at least one team!')),
        selectedClient: yup
            .number()
            .typeError(t('Project must have a client!'))
            .required(t('Project must have a client!')),
        projectName: yup
            .string()
            .trim()
            .typeError(t('Project name must be at least 3 characters long!'))
            .required(t('Project name must be at least 3 characters long!')),
    });

    const createProject = async () => {
        try {
            setLoading(true);
            await schema.validate({
                projectName,
                selectedClient,
                teams,
            });

            try {
                await API.post('/projectsOverviewPlanning', {
                    name: projectName,
                    contactId: clients[selectedClient].id,
                    projectStructureId: projectStructures[selectedProjectStructure]?.id ?? null,
                    teams: teams.map((t) => ({ name: t.name })),
                });
                enqueueSnackbar(t('The project has been successfully added!'), {
                    variant: 'success',
                });
                setLoading(false);
                history.push(internalActivity.base + internalActivity.planning.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const editProject = async () => {
        try {
            setLoading(true);
            await schema.validate({
                projectName,
                selectedClient,
                teams,
            });

            try {
                await API.put('/projectsOverviewPlanning', {
                    id: projectInfo.id,
                    data: {
                        name: projectName,
                        contactId: clients[selectedClient].id,
                        projectStructureId: projectStructures[selectedProjectStructure]?.id ?? null,
                        teams: teams.map((t) => ({
                            id: t.id ? t.id : undefined,
                            name: t.name,
                            employees:
                                'employees' in t
                                    ? t.employees.map((e) => ({
                                          employeeId: e.employee.id,
                                          employeeTeamId: e.employee.employeeTeamId
                                              ? e.employee.employeeTeamId
                                              : undefined,
                                          tasks: [],
                                      }))
                                    : [],
                        })),
                    },
                });
                enqueueSnackbar(t('The project has been successfully updated!'), {
                    variant: 'success',
                });
                setLoading(false);
                history.push(internalActivity.base + internalActivity.planning.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    /**
     * Fetch the client for the project
     */
    useEffect(() => {
        (async () => {
            try {
                //Fetch Clients
                const response = await API.get('/contacts', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                });
                const fetchedClients = response.data.data.contacts;
                setClients(fetchedClients);

                setClientOptions(
                    fetchedClients.map((c) => {
                        const data = JSON.parse(c.data);
                        return 'standard' in data && 'name' in data['standard']
                            ? data['standard'].name
                            : 'Nume inexistent';
                    }),
                );

                //Fetch Project Structures
                const rowResponseProjectStructures = await API.get('projectStructures', {
                    params: {
                        perPage: 999999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });
                const projectStructuresResponse = rowResponseProjectStructures.data.data.data;
                setProjectStructures(projectStructuresResponse);

                if (projectInfo) {
                    setProjectName(projectInfo.name);
                    setTeams(projectInfo.teams.map((t) => t));
                    setSelectedClient(fetchedClients.findIndex((c) => c.id === projectInfo.contactId));

                    if (projectInfo.projectStructureId)
                        setSelectedProjectStructure(
                            projectStructuresResponse.findIndex((c) => c.id === projectInfo.projectStructureId),
                        );
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [projectInfo]);

    return (
        <>
            {loading ? (
                <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className="flex w-full max-w-xl flex-col space-y-10">
                    <TextField
                        name={t('Project name')}
                        label={t('Project name')}
                        placeholder={t('Project name')}
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />

                    <div className="flex gap-10">
                        {/* Customers */}
                        {clientOptions.length > 0 ? (
                            <LabelWrapper label={t('Choose the customer for this project')}>
                                <Dropdown
                                    options={clientOptions}
                                    selectedOption={selectedClient}
                                    setSelectedOption={(newClientIndex) => setSelectedClient(newClientIndex)}
                                    label="Client"
                                    placeholder={t('Customer')}
                                />
                            </LabelWrapper>
                        ) : (
                            <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p className="mb-5">{t('There are no customers yet, you have to add one first')}</p>
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    onClick={() => history.push(crm.base + crm.contacts.base + crm.contacts.create)}
                                >
                                    {t('Add customer')}
                                </Button>
                            </div>
                        )}

                        {projectStructures.length > 0 ? (
                            <div className="flex items-center gap-3">
                                <LabelWrapper label={t('Choose the project structure for this project')}>
                                    <Dropdown
                                        options={projectStructures.map((ps) => ps.name)}
                                        selectedOption={selectedProjectStructure}
                                        setSelectedOption={(i) => {
                                            setSelectedProjectStructure(i);
                                        }}
                                        placeholder={t('Project structure')}
                                    />
                                </LabelWrapper>
                                <div className="mt-6">
                                    <ActionButton
                                        icon={<DeleteIcon />}
                                        color={selectedProjectStructure !== null ? `var(--error)` : `var(--disabled)`}
                                        disabled={selectedProjectStructure !== null ? false : true}
                                        onClick={() => {
                                            setSelectedProjectStructure(null);
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                <p className="mb-5">
                                    {t('There are no project structures yet, you have to add one first')}
                                </p>
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    onClick={() =>
                                        history.push(
                                            internalActivity.base +
                                                internalActivity.projectStructure.base +
                                                internalActivity.projectStructure.create,
                                        )
                                    }
                                >
                                    {t('Add project structure')}
                                </Button>
                            </div>
                        )}
                    </div>

                    {/* Teams */}
                    <h3 className="text-dark-text">{t('Teams on this projects')}</h3>
                    <div className="space-y-2">
                        {teams.map((team, index) => (
                            <div key={index} className="block">
                                <div className="flex items-center gap-2">
                                    <div className="text-lg text-main-text">{index + 1}.</div>
                                    <TextField
                                        name="variable"
                                        value={team.name}
                                        onChange={(e) => editTeam(index, 'name', e.target.value)}
                                    />
                                    <div className="flex items-center">
                                        <BasicTooltip tip={t('Delete team')}>
                                            <div
                                                className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-error transition-all  hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                                onClick={() => deleteTeam(index)}
                                            >
                                                <DeleteIcon className="text-main-text" style={{ fontSize: '1.1rem' }} />
                                            </div>
                                        </BasicTooltip>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button
                            startIcon={<AddIcon />}
                            color="secondary"
                            onClick={() => {
                                setTeams([...teams, defaultTeam]);
                            }}
                        >
                            {t('Add team')}
                        </Button>
                    </div>

                    <div>
                        {projectInfo ? (
                            <Button
                                startIcon={<EditIcon />}
                                color="primary"
                                onClick={() => {
                                    editProject();
                                }}
                            >
                                {t('Edit project')}
                            </Button>
                        ) : (
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() => {
                                    createProject();
                                }}
                            >
                                {t('Create project')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

PlanningOverviewProject.propTypes = {
    projectInfo: PropTypes.object,
};
PlanningOverviewProject.defaultProps = {
    projectInfo: null,
};

export default PlanningOverviewProject;

import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import EditIcon from '@material-ui/icons/Edit';

import BasicTooltip from 'components/shared/basic-tooltip';
import SuccessButton from 'components/shared/success-button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RiemContext } from '../riem';
import RiemFooterForm from './riem-footer-form';

const RiemFooter = ({ riem, renderType, height }) => {
    const { t } = useTranslation();

    const [isMounted, setIsMounted] = useState(false);
    const { canEdit, isSaving, isOperatingOnRowAt, setIsOperatingOnRowAt } = useContext(RiemContext);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isOperatingOnRowAt === 'footer') return <RiemFooterForm riem={riem} />;

    return (
        <div
            role="row"
            className="col-span-full grid border border-black bg-pdf-layout-background text-justify"
            style={{
                gridTemplateColumns: 'subgrid',
                height: height ?? '',
            }}
        >
            <div role="cell" colSpan="3" className="col-span-3 flex items-center justify-center">
                <p className="w-full break-words px-0.5 py-1 text-xl font-bold text-pdf-layout-text">
                    {riem.footer.total}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedScore), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedProbability), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedTotal), 0)}
                </p>
            </div>
            <div role="cell" />
            <div role="cell" />
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualScore), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualProbability), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-right text-xl font-bold text-pdf-layout-text">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualTotal), 0)}
                </p>
            </div>
            <div role="cell" />
            <div role="cell" />

            {!renderType &&
                isMounted &&
                createPortal(
                    <div className="flex items-center justify-center">
                        <BasicTooltip
                            tip={t("You can't add new rows while you create or edit an existing one")}
                            disabled={!canEdit || isOperatingOnRowAt === null}
                        >
                            <SuccessButton
                                disabled={!canEdit || isSaving || isOperatingOnRowAt !== null}
                                startIcon={<EditIcon />}
                                size="small"
                                onClick={() => setIsOperatingOnRowAt('footer')}
                            >
                                {t('Edit')}
                            </SuccessButton>
                        </BasicTooltip>
                    </div>,
                    document.getElementById('riem-footer-container'),
                )}
        </div>
    );
};

RiemFooter.propTypes = {
    riem: PropTypes.object,
    renderType: PropTypes.string,
    height: PropTypes.string,
};

RiemFooter.defaultProps = {
    riem: {},
    renderType: null,
    height: null,
};

export default RiemFooter;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';

import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditContact = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [contactValues, setContactValues] = useState(null);
    const [pmId, setPMId] = useState(null);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const res = await API.get('contact', {
                    params: {
                        id,
                    },
                });
                setContactValues(JSON.parse(res.data.data.data));
                setPMId(res.data.data.pm_planningOverviewProjectId);
                setGroups(res.data.data.groups.map((group) => group.id));
            } catch (err) {
                console.error(err);
                history.goBack();
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>CRM | {t('edit-contact')}</title>
            </Helmet>

            <Header pageTitle={t('edit-contact')} />

            <div className="page-container">
                {contactValues !== null && !loading && (
                    <ConfiguratorForm
                        entity="contact"
                        entityId={id}
                        defaultValues={contactValues}
                        pmProjectId={pmId}
                        initialGroups={groups}
                    />
                )}
                {loading && <LinearProgress />}
            </div>
        </>
    );
};

export default EditContact;

import React, { useContext } from 'react';

import { Download, File, FileText, Trash } from 'lucide-react';

import FileRow from 'components/shared/file-row/file-row';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadFile, errorHandling, getFileNameAndExtension } from 'utils';

const typeIcon = {
    DOCUMENT: <FileText />,
    FILE: <File />,
};

const WikiFiles = ({ wiki, disabled, onDeleteFile }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const files = wiki.fileUrls.map((file) => {
        const { fileName, type } = file;
        const { name, extension } = getFileNameAndExtension(fileName);
        const icon = typeIcon[type];

        return { ...file, fileName: name, info: { icon, text: extension } };
    });

    if (files.length === 0) return null;

    const canView = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const handleDownloadFile = async (file) => {
        try {
            const {
                fileName,
                urls,
                info: { text },
            } = file;

            await downloadFile(fileName, urls, text);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    const actions = canAll
        ? [
              {
                  icon: <Download />,
                  text: t('Download'),
                  action: (file) => handleDownloadFile(file),
                  disabled,
                  confirm: false,
              },
              {
                  icon: <Trash />,
                  text: t('Delete'),
                  action: (file, wiki) => onDeleteFile(file.fileId, wiki.id),
                  disabled,
                  confirm: true,
              },
          ]
        : canView
          ? [
                {
                    icon: <Download />,
                    text: t('Download'),
                    action: (file) => handleDownloadFile(file),
                    disabled,
                    confirm: false,
                },
            ]
          : [];

    return (
        <div className="flex flex-col gap-2">
            {files.map((file) => (
                <FileRow
                    key={file.fileId}
                    info={file.info}
                    actions={actions.map((element) => ({ ...element, action: element.action.bind(null, file, wiki) }))}
                    disabled={disabled}
                >
                    {file.fileName}
                </FileRow>
            ))}
        </div>
    );
};

WikiFiles.propTypes = {
    wiki: PropTypes.object,
    disabled: PropTypes.bool,
    onDeleteFile: PropTypes.func,
};

WikiFiles.defaultProps = {
    wiki: {},
    disabled: false,
    onDeleteFile: () => null,
};

export default WikiFiles;

import React, { useState } from 'react';

import { Check, Download, Paperclip, Plus, Trash, Upload, X } from 'lucide-react';

import { TextareaAutosize, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadFile, errorHandling, generateUUID, getFileNameAndExtension } from 'utils';
import * as yup from 'yup';

import FileRow from '../file-row/file-row';
import FileUploadContainer from '../file-upload-container';
import RoundedButton from '../rounded-button/rounded-button';

const DEFAULT_FORM_STATE = {
    comment: '',
    files: [],
};

const CommentsAdd = ({ onSubmit, disabled, canRender }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [formState, setFormState] = useState(DEFAULT_FORM_STATE);

    const schema = yup.object().shape({
        comment: yup
            .string()
            .trim()
            .typeError(t('Comment is required and can not be empty!'))
            .required(t('Comment is required and can not be empty!')),
    });

    const handleOpen = () => {
        setFormState(DEFAULT_FORM_STATE);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleFormChange = (e) => setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const handleFilesUpload = async (e) => {
        const { files } = e.target;
        const formattedFiles = await Promise.all(
            Array.from(files).map((file) => {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();

                    fileReader.readAsDataURL(file);
                    fileReader.onload = (event) => {
                        try {
                            const { name, extension } = getFileNameAndExtension(file.name);
                            resolve({ id: generateUUID(), url: event.target.result, name, extension, file });
                        } catch {
                            enqueueSnackbar(t('Could not upload file/s! Try again!'), { variant: 'error' });
                            reject(t('Could not upload file/s! Try again!'));
                        }
                    };
                });
            }),
        );

        setFormState((prev) => ({ ...prev, [e.target.name]: [...prev[e.target.name], ...formattedFiles] }));
    };

    const handleDownloadFile = async (file) => {
        try {
            const { name, url, extension } = file;
            await downloadFile(name, url, extension);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    const handleDeleteFile = (file) =>
        setFormState((prev) => ({ ...prev, files: prev.files.filter((element) => element.id !== file.id) }));

    const fileActions = [
        {
            icon: <Download />,
            text: t('Download'),
            action: (file) => handleDownloadFile(file),
            disabled,
            confirm: false,
        },
        {
            icon: <Trash />,
            text: t('Delete'),
            action: (file) => handleDeleteFile(file),
            disabled,
            confirm: false,
        },
    ];

    const handleSubmit = async () => {
        try {
            await schema.validate(formState);
            await onSubmit(formState);
            handleClose();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        }
    };

    if (!canRender) return null;

    return (
        <div className="flex flex-col items-center">
            <div
                className="grid w-full transition duration-300"
                style={{ transitionProperty: 'grid-template-rows', gridTemplateRows: open ? '1fr' : `0fr` }}
            >
                <div className="overflow-hidden">
                    <div className="relative mb-9 flex rounded-r-xl border border-layout-transparent bg-layout-transparent">
                        <div className="w-0.5 flex-shrink-0 bg-secondary-lighter" />
                        <form className="flex w-full flex-col gap-8 p-5">
                            <TextField
                                name="comment"
                                InputProps={{
                                    inputComponent: TextareaAutosize,
                                    rows: 5,
                                }}
                                placeholder={`${t('Write comment')}...`}
                                value={formState.comment}
                                onChange={handleFormChange}
                                disabled={disabled}
                            />
                            {formState.files.length > 0 && (
                                <div className="flex flex-col gap-2">
                                    {formState.files.map((file) => (
                                        <FileRow
                                            key={file.id}
                                            info={{ text: file.extension, icon: <Paperclip /> }}
                                            actions={fileActions.map((element) => ({
                                                ...element,
                                                action: element.action.bind(null, file),
                                            }))}
                                            disabled={disabled}
                                        >
                                            {file.name}
                                        </FileRow>
                                    ))}
                                </div>
                            )}
                            <div className="mb-9 flex justify-center">
                                <FileUploadContainer
                                    accept="*"
                                    name="files"
                                    disabled={disabled}
                                    onUpload={handleFilesUpload}
                                >
                                    <RoundedButton icon={<Upload />} variant="dark" disabled={disabled} />
                                </FileUploadContainer>
                            </div>
                        </form>
                        <div className="absolute bottom-0 right-0 flex translate-y-1/2 transform justify-end gap-4">
                            <RoundedButton
                                icon={<X />}
                                variant="secondary"
                                onClick={handleClose}
                                disabled={disabled}
                                height="4.5rem"
                            />
                            <RoundedButton
                                icon={<Check />}
                                variant="secondary"
                                onClick={handleSubmit}
                                disabled={disabled}
                                height="4.5rem"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="grid transition duration-300"
                style={{ transitionProperty: 'grid-template-rows', gridTemplateRows: !open ? '1fr' : `0fr` }}
            >
                <div className="overflow-hidden">
                    <div className="pt-6">
                        <RoundedButton icon={<Plus />} variant="dark" onClick={handleOpen} disabled={disabled} />
                    </div>
                </div>
            </div>
        </div>
    );
};

CommentsAdd.propTypes = {
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    canRender: PropTypes.bool,
};

CommentsAdd.defaultProps = {
    onSubmit: () => {},
    disabled: false,
    canRender: true,
};

export default CommentsAdd;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CircularProgress } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { projectInManagement } from 'routes';
import API from 'utils/axios';

export default function SelectResourcesOnProject() {
    const { t } = useTranslation();
    const [allUsers, setAllUsers] = useState([]);
    const [allOptions, setAllOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { projectId } = useParams();

    const location = useLocation();

    const resourceType = location.state?.resourceType;

    const rootUrl = projectInManagement.base + '/' + projectId + projectInManagement.configurator;

    const setTitleForSpecificResourceType = (resourceType, areaOfUse) => {
        let title;
        let buttonText;

        switch (resourceType) {
            case 'completed':
                title = 'Select the completed accounts';
                buttonText = 'Add on the project selected completed accounts';
                break;
            case 'affiliate':
                title = 'Select the affiliate accounts';
                buttonText = 'Add on the project selected affiliate accounts';
                break;
            case 'internal':
                title = 'Select internal resources';
                buttonText = 'Add on the project selected internal resources';
                break;
            case 'external':
                title = 'Select external resources';
                buttonText = 'Add on the project selected external resources';
                break;
            default:
                break;
        }

        if (areaOfUse === 'title') {
            return title;
        } else if (areaOfUse === 'button') {
            return buttonText;
        }
    };

    const getResourcesByType = async () => {
        let response;
        let allResources = [];
        let allUsersOnProject;
        let allAffiliatesOnProject;
        let allInternalResourcesOnProject;
        let allExternalResourcesOnProject;

        try {
            switch (resourceType) {
                case 'completed':
                    response = await API.get('/PmUsers', {
                        params: {
                            perPage: 999999,
                            pagesToLoad: 1,
                            currentPage: 0,
                        },
                    });

                    allUsersOnProject = await API.get(`/ProjectUsers?pmProjectId=${projectId}`, {
                        params: {
                            perPage: 999999,
                            pagesToLoad: 1,
                            currentPage: 0,
                        },
                    });

                    allResources = response.data.pmUsers.content.filter(
                        (user) => !allUsersOnProject.data.Users.users.some((us) => us.userId === user.id),
                    );

                    break;
                case 'affiliate':
                    response = await API.get('/PmAffiliates', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    });

                    allAffiliatesOnProject = await API.get(`/ProjectAffiliates?pmProjectId=${projectId}`, {
                        params: {
                            perPage: 999999,
                            pagesToLoad: 1,
                            currentPage: 0,
                        },
                    });

                    allResources = response.data.PmAffiliates.content.filter(
                        (affiliate) =>
                            !allAffiliatesOnProject.data.Affiliates.affiliates.some(
                                (af) => af.Affiliate.userId === affiliate.userId,
                            ),
                    );

                    break;
                case 'internal':
                    response = await API.get('/pm_employees', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    });

                    allInternalResourcesOnProject = await API.get(`/ProjectEmployees?pmProjectId=${projectId}`, {
                        params: {
                            perPage: 999999,
                            pagesToLoad: 1,
                            currentPage: 0,
                        },
                    });

                    allResources = response.data.pmEmployees
                        .filter((user) => user.employeeType === 'INTERNAL')
                        .filter(
                            (intern) =>
                                !allInternalResourcesOnProject.data.Employees.filter(
                                    (employee) => employee.Employees.employeeType === 'INTERNAL',
                                ).some((inter) => inter.employeeId === intern.id),
                        );
                    break;
                case 'external':
                    response = await API.get('/pm_employees', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        },
                    });

                    allExternalResourcesOnProject = await API.get(`/ProjectEmployees?pmProjectId=${projectId}`, {
                        params: {
                            perPage: 999999,
                            pagesToLoad: 1,
                            currentPage: 0,
                        },
                    });

                    allResources = response.data.pmEmployees
                        .filter((user) => user.employeeType === 'EXTERNAL')
                        .filter(
                            (intern) =>
                                !allExternalResourcesOnProject.data.Employees.filter(
                                    (employee) => employee.Employees.employeeType === 'EXTERNAL',
                                ).some((inter) => inter.employeeId === intern.id),
                        );
                    break;
                default:
                    break;
            }
            setAllUsers(allResources);
        } catch (err) {
            console.error(err);
        }
    };

    React.useEffect(() => {
        getResourcesByType();
    }, []);

    const displayResourcesNameInDropdownByType = useCallback(() => {
        let nameOptions = [];

        if (allUsers.length > 0) {
            switch (resourceType) {
                case 'completed':
                    nameOptions = allUsers.map((user) => user.profile.name);
                    break;
                case 'affiliate':
                    nameOptions = allUsers.map((user) => user.user.profile.name);
                    break;
                case 'internal':
                    nameOptions = allUsers.map((user) => user.firstName + ' ' + user.lastName);
                    break;
                case 'external':
                    nameOptions = allUsers.map((user) => user.firstName + ' ' + user.lastName);
                    break;
                default:
                    break;
            }
        }

        return nameOptions;
    }, [allUsers]);

    const addSelectedResourcesToProject = async () => {
        if (selected.length === 0) {
            enqueueSnackbar(t('You must select at least a resource/account!'), { variant: 'warning' });
            return;
        }

        setLoading(true);

        try {
            switch (resourceType) {
                case 'completed':
                    await API.post('/ProjectUsers', {
                        pmProjectId: projectId,
                        ids: selected.map((option) => allUsers[option].id),
                    });
                    history.push(rootUrl + '?tab=0');
                    break;
                case 'affiliate':
                    await API.post('/ProjectAffiliates', {
                        pmProjectId: projectId,
                        ids: selected.map((option) => allUsers[option].id),
                    });
                    history.push(rootUrl + '?tab=1');
                    break;
                case 'internal':
                    await API.post('/ProjectEmployees', {
                        pmProjectId: projectId,
                        ids: selected.map((option) => allUsers[option].id),
                    });
                    history.push(rootUrl + '?tab=2');
                    break;
                case 'external':
                    await API.post('/ProjectEmployees', {
                        pmProjectId: projectId,
                        ids: selected.map((option) => allUsers[option].id),
                    });
                    history.push(rootUrl + '?tab=3');
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t(setTitleForSpecificResourceType(resourceType, 'title'))}
                </title>
            </Helmet>

            <Header pageTitle={t(setTitleForSpecificResourceType(resourceType, 'title'))} />

            <div className="page-container">
                <React.Fragment>
                    <div className="mb-24 w-full max-w-md">
                        <MultiDropdown
                            options={displayResourcesNameInDropdownByType()}
                            placeholder={t(setTitleForSpecificResourceType(resourceType, 'title'))}
                            selectedOptions={selected}
                            setSelectedOptions={(i) => {
                                if (selected.indexOf(i) > -1) {
                                    setSelected(selected.filter((opt) => opt !== i));
                                } else {
                                    setSelected([...selected, i]);
                                }
                            }}
                        />
                    </div>
                    {loading ? (
                        <div className="bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Button color="primary" onClick={() => addSelectedResourcesToProject()}>
                            {t(setTitleForSpecificResourceType(resourceType, 'button'))}
                        </Button>
                    )}
                </React.Fragment>
            </div>
        </React.Fragment>
    );
}

SelectResourcesOnProject.propTypes = {
    affiliate: PropTypes.boolean,
    pmId: PropTypes.string,
};

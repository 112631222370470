import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ImportExportIcon from '@material-ui/icons/ImportExport';

import { Button, CircularProgress } from '@material-ui/core';

import FrappeGantt from 'components/frappe-gantt/frappe-gantt-layout';
import LoadingExportModal from 'components/modals/loading-export-modal';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useQuery from 'hooks/useQuery';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import exportImages from 'utils/exportImages';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getActivities } from 'utils/ganttUtils';

const GanttProjectStructure = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams();
    const { checkPerm } = useContext(UserContext);
    const { tenant, language } = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();

    const companyName = tenant.companyName;

    const [query] = useQuery();
    const view = query.get('view') ?? 'Day';

    const [loadingExportImage, setLoadingExportImage] = useState(false);
    const [loadingexportImagesAsPDF, setLoadingexportImagesAsPDF] = useState(false);
    const [loading, setLoading] = useState(true);
    const [projectStructure, setProjectStructure] = useState(null);
    const memoizedProjectStructures = useMemo(() => (projectStructure ? [projectStructure] : []), [projectStructure]);

    const canView = checkPerm([
        {
            permissionId: '42',
            permissionType: 'VIEW',
        },
    ]);

    const getProjectStructure = async () => {
        try {
            const response = await API.get(`projectStructure`, {
                params: {
                    id,
                },
            });

            const rowData = response.data.projectStructure;
            setProjectStructure(rowData);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!canView) history.goBack();

        (async () => {
            try {
                await getProjectStructure();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    // Export Gantt as image logic

    const handleExportGanntAsImage = async () => {
        try {
            setLoadingExportImage(true);

            const ganttQuery = [
                {
                    ids: ['gantt-wrapper-root'],
                    type: 'gantt',
                    element: {
                        type: 'projectStructures',
                    },
                    data: {
                        view,
                        customDates: null,
                        selectedEntities: memoizedProjectStructures.map((ps) => ps.id),
                    },
                    request: {
                        params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                    },
                },
            ];
            await exportImages(ganttQuery, language, true);
        } catch (error) {
            enqueueSnackbar(t("Gantt couldn't be exported"), { variant: 'error' });
            console.error(error);
        } finally {
            setLoadingExportImage(false);
        }
    };

    // Export Gantt as pdf logic

    const handleExportGanntAsPDF = async () => {
        try {
            setLoadingexportImagesAsPDF(true);

            await exportImagesToPDF(
                'landscape',
                language,
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'projectStructures',
                        },
                        data: {
                            view,
                            customDates: null,
                            selectedEntities: memoizedProjectStructures.map((ps) => ps.id),
                        },
                        request: {
                            params: { currentPage: 0, perPage: 99999, pagesToLoad: 1 },
                        },
                    },
                ],
                tenant,
                [
                    {
                        ids: ['gantt-legend-root'],
                        type: 'gantt-legend',
                        element: {
                            type: 'projectStructures',
                        },
                    },
                ],
                () => setLoadingexportImagesAsPDF(false),
                null,
                true,
                'multi-page',
                companyName,
                `Project_StructureGantt_Export_${new Date().toISOString().slice(0, 10)}_${companyName}.pdf`,
            );
        } catch (error) {
            console.error(error);
        }
    };

    if (loading)
        return (
            <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                <CircularProgress />
            </div>
        );

    return (
        <>
            <Helmet>
                <title>{t('Project Structure Gantt')}</title>
            </Helmet>
            <Header
                pageTitle={t('Project Structure Gantt')}
                action={
                    <Fragment>
                        <Button
                            startIcon={loadingExportImage ? <CircularProgress size={20} /> : <ImportExportIcon />}
                            color="secondary"
                            style={{ borderRadius: '999px' }}
                            onClick={loadingExportImage ? undefined : handleExportGanntAsImage}
                            disabled={loading}
                        >
                            {t('Export Gantt as image')}
                        </Button>

                        <Button
                            startIcon={loadingexportImagesAsPDF ? <CircularProgress size={20} /> : <ImportExportIcon />}
                            color="secondary"
                            style={{ borderRadius: '999px' }}
                            onClick={loadingexportImagesAsPDF ? undefined : handleExportGanntAsPDF}
                            disabled={loading}
                        >
                            {t('Export Gantt as PDF')}
                        </Button>
                    </Fragment>
                }
            />
            <div className="page-container">
                <FrappeGantt
                    type={'projectStructures'}
                    getTasksFunction={getActivities}
                    milestones={memoizedProjectStructures}
                />
                <LoadingExportModal open={loadingExportImage || loadingexportImagesAsPDF} />
            </div>
        </>
    );
};

export default GanttProjectStructure;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';

const ProjectsRow = (props) => {
    const { proj } = props;

    return (
        <TableRow key={proj}>
            <TableCell>
                <span style={{ color: '#2B7CDA' }}>&#8226; </span>
                {proj.name}
            </TableCell>
            <TableCell>{formatDate(proj.createAt)}</TableCell>
            <TableCell>{proj.description}</TableCell>
        </TableRow>
    );
};

ProjectsRow.propTypes = {
    proj: PropTypes.object,
};

ProjectsRow.defaultProps = {
    proj: null,
};

const ProjectsTable = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const { checkPerm } = useContext(UserContext);

    const [projects, setProjects] = useState([]);

    const getZonesAndProjects = async () => {
        try {
            const response = await API.get('/projects', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });
            setProjects(response.data.projects);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getZonesAndProjects();
    }, []);

    const canAll = checkPerm([
        {
            permissionId: '16',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '16',
            permissionType: 'VIEW',
        },
    ]);

    return (
        <>
            <Helmet>
                <title>
                    {t('Zones & Projects')} | {t('Projects')}
                </title>
            </Helmet>

            {canView && (
                <div className="page-container">
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Project name')}</TableCell>
                                    <TableCell>{t('Created on')}</TableCell>
                                    <TableCell>{t('Observations')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {projects
                                    .filter((p) => p.areaId === id)
                                    .map((proj) => (
                                        <ProjectsRow key={proj.id} proj={proj} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="mt-8">
                        {canAll && (
                            <Button
                                size="large"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() =>
                                    history.push(
                                        configurator.base +
                                            configurator.projects.base +
                                            configurator.projects.create +
                                            '/' +
                                            id,
                                    )
                                }
                            >
                                {t('Add new project')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ProjectsTable;

import React, { StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import './components/frappe-gantt/frappe-gantt.css';
import './i18n.js';

import Loading from 'components/shared/loading';

import App from './app.jsx';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';

const root = document.getElementById('root');

render(
    <StrictMode>
        <Suspense fallback={<Loading style={{ height: '100svh' }} />}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Suspense>
    </StrictMode>,
    root,
);

serviceWorkerRegistration.register();

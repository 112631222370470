import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { generateUUID } from 'utils';

const FileUploadContainer = (props) => {
    const { children, onUpload, name, multiple, accept, disabled } = props;
    const inputRef = useRef(null);

    const acceptValues = {
        '*': '',
        all: '.jpg, .jpeg, .png, .pdf',
        file: '.pdf',
        image: '.jpg, .jpeg, .png',
    };

    return (
        <div>
            <span
                onClick={() => {
                    inputRef?.current ? inputRef.current.click() : undefined;
                }}
            >
                {children}
            </span>

            <input
                id={`${generateUUID()}`}
                name={name}
                type="file"
                onChange={onUpload}
                ref={inputRef}
                multiple={multiple}
                accept={acceptValues[accept]}
                disabled={disabled}
                style={{
                    display: 'none',
                    visibility: 'hidden',
                    opacity: 0,
                    pointerEvents: 'none',
                }}
            />
        </div>
    );
};

FileUploadContainer.propTypes = {
    children: PropTypes.element,
    onUpload: PropTypes.func,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
};

FileUploadContainer.defaultProps = {
    children: <></>,
    onUpload: () => null,
    name: undefined,
    multiple: true,
    accept: 'all',
    disabled: false,
};

export default FileUploadContainer;

import React from 'react';

import PropTypes from 'prop-types';

const PipelineItemHead = ({ itemProps, item }) => {
    const { head } = itemProps;

    return <p className="text-xl leading-7">{head.render(item)}</p>;
};

PipelineItemHead.propTypes = {
    itemProps: PropTypes.object,
    item: PropTypes.object,
};

PipelineItemHead.defaultProps = {
    itemProps: {},
    item: {},
};

export default PipelineItemHead;

import React from 'react';

import PropTypes from 'prop-types';

const wrapperClassName =
    'relative w-full h-svh flex md:flex-col items-start justify-between bg-layout-main overflow-hidden';

export const Layout = (props) => {
    const { children, sidebar } = props;

    return (
        <div className={wrapperClassName}>
            {sidebar}
            <main className="main-content-area overflow-x-hidden">
                <section className="content-section">{children}</section>
            </main>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    sidebar: PropTypes.element,
    disabledSidebar: PropTypes.bool,
    handleLogout: PropTypes.func,
};

Layout.defaultProps = {
    children: <></>,
    sidebar: null,
    disabledSidebar: true,
    handleLogout: () => {},
};

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CheckIcon from '@material-ui/icons/Check';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';

import { Button } from '@material-ui/core';

import { ActionArrow } from 'components/internal-activity/activity';
import AddComment from 'components/internal-activity/add-comment';
import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator, internalActivity } from 'routes';
import { daysFrom, formatDate, formatTime, linkStyle } from 'utils';
import API from 'utils/axios';

import PMTaskManagementSubmilestoneDisplay from './pm-task-management-submilestone';

const Comment = (props) => {
    const { comment, userId } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { user, checkPerm } = useContext(UserContext);

    const userCanAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const affiliateCanAll =
        user.isAffiliate &&
        checkPerm([
            {
                permissionId: '19',
                permissionType: 'VIEW',
            },
        ]);

    const canAll = userCanAll || affiliateCanAll;

    const deleteComment = async () => {
        if (isDeleting) return;
        if (comment.authorId !== userId) return;

        setIsDeleting(true);
        try {
            await API.delete('activities_comments', {
                data: {
                    id: comment.id,
                },
            });
            enqueueSnackbar(comment?.comment ? t('Comment deleted!') : t('Document/s deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
        }
    };

    const fileNameFromKey = useCallback((key) => {
        const spl = key.split('/');
        const uuidPart = spl[spl.length - 1];
        const fileName = uuidPart.split('-').slice(5).join('');
        return fileName;
    }, []);

    return (
        <>
            {comment !== null && (
                <div
                    className={`relative mb-1.5 flex flex-col gap-2 rounded-br-md rounded-tr-md border-l border-secondary-light bg-layout-transparent px-8 py-3 transition-colors hover:bg-layout-transparent-dark ${
                        isDeleting ? 'hidden' : 'block'
                    }`}
                >
                    <div className="absolute right-2 top-2">
                        {canAll && (
                            <ActionArrow
                                actions={
                                    comment.authorId === userId
                                        ? [
                                              {
                                                  name: t('Delete'),
                                                  action: deleteComment,
                                              },
                                          ]
                                        : []
                                }
                            />
                        )}
                    </div>

                    {comment?.comment !== null && comment?.comment !== '' && (
                        <p className="mb-2 text-main-text">{comment.comment}</p>
                    )}

                    <p className="task-dark-text">
                        <span className="font-semibold">{comment.author?.profile?.name}</span>{' '}
                        {`${formatDate(comment.createAt)} ${formatTime(comment.createAt)}`}
                    </p>

                    {/* Comment Files */}
                    {comment.files?.length > 0 && (
                        <div className="relative z-20 flex flex-col gap-1">
                            {comment.files.map((file) => (
                                <div
                                    key={file.id}
                                    className="flex items-center justify-between border-b border-layout-lighter py-1"
                                >
                                    <a
                                        className="flex items-center"
                                        target="_blank"
                                        href={file.file.url}
                                        download={fileNameFromKey(file.file.key)}
                                        rel="noreferrer"
                                    >
                                        <div className="mr-1 flex h-6 w-6 items-center justify-center">
                                            <InsertDriveFileIcon
                                                className="text-secondary-lighter"
                                                style={{
                                                    fontSize: '1.5rem',
                                                }}
                                            />
                                        </div>
                                        <p className="text-medium mr-2 cursor-pointer py-1 opacity-75 transition-opacity hover:opacity-100">
                                            {fileNameFromKey(file.file.key)}
                                        </p>
                                    </a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

Comment.propTypes = {
    comment: PropTypes.object,
    userId: PropTypes.string,
    fetchActivities: PropTypes.func,
};

Comment.defaultProps = {
    comment: null,
    userId: null,
    fetchActivities: () => null,
};

const TaskManagementDisplay = (props) => {
    const { activity, deleteActivity, updateActivity, userLink, fetchActivities, onNotify, actionArrow } = props;

    const [checked, setChecked] = useState(false);
    const history = useHistory();
    const { checkPerm, user } = useContext(UserContext);
    const globalContext = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const userCanAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const affiliateCanAll =
        user.isAffiliate &&
        checkPerm([
            {
                permissionId: '19',
                permissionType: 'VIEW',
            },
        ]);

    const canAll = userCanAll || affiliateCanAll;

    const userIsResponsible =
        canAll &&
        (user.id === activity.activityInfo.authorId ||
            (user.isAffiliate &&
                activity.activityInfo.users
                    .filter((u) => u.userType === 'RESPONSIBLE')
                    .map((u) => u.userId)
                    .includes(user.id)));

    const responsible = activity.activityInfo.users
        .filter((u) => u.userType === 'RESPONSIBLE')
        .map((u) => u.user.profile.name)
        .join(', ');

    const supervisor = activity.activityInfo.users
        .filter((u) => u.userType === 'ACCEPTER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const watcher = activity.activityInfo.users
        .filter((u) => u.userType === 'WATCHER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const partner =
        activity.activityInfo.partners &&
        (typeof activity.activityInfo.partners.data === 'string'
            ? JSON.parse(activity.activityInfo.partners.data)
            : activity.activityInfo.partners.data);

    useEffect(() => {
        setChecked(activity.activityInfo.status === 'DONE');
    }, [activity.activityInfo.status]);

    const activityBorderColors = {
        COMMENT: 'border-blue-500',
        TASK: 'border-green-500',
        FILES: 'border-purple-500',
        DOCUMENTS: 'border-yellow-500',
        INVOICES_AND_BILLS: 'border-red-500',
    };

    const activityBackgroundColors = {
        COMMENT: 'bg-blue-500',
        TASK: 'bg-green-500',
        FILES: 'bg-purple-500',
        DOCUMENTS: 'bg-yellow-500',
        INVOICES_AND_BILLS: 'bg-red-500',
    };

    const activityTextColors = {
        COMMENT: 'text-blue-500',
        TASK: 'text-green-500',
        FILES: 'text-purple-500',
        DOCUMENTS: 'text-yellow-500',
        INVOICES_AND_BILLS: 'text-red-500',
    };

    // Add comment function
    const submitHandle = async (activityId, newComment, newCommentFiles) => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                activityId,
                comment: newComment,
            }),
        );

        if (newCommentFiles.length > 0) {
            newCommentFiles.forEach((file) => formData.append('files', file.blob));
        }

        try {
            await API.post('/activities_comments', formData);
            enqueueSnackbar(t('Comment added successfully!'), { variant: 'success' });

            await fetchActivities();
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Function delete files from a created wiki
     * @param {*} id - of the file which is need to be deleted
     */
    // const deleteFileFromActivity = async (id) => {
    //     try {
    //         await API.delete('/pmProjectActivityComments', {
    //             params: {
    //                 activityId: activity.activityInfo.id,
    //                 fileId: id,
    //             },
    //         });

    //         await fetchActivities();
    //     } catch (err) {
    //         enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
    //             variant: 'error',
    //         });
    //         console.error(err);
    //     }
    // };

    // const invoiceTypeDisplayed = (invoice) => {
    //     let typeDisplay;
    //     switch (invoice?.type) {
    //         case 'EXPENSE':
    //             typeDisplay = t('Expense');
    //             break;
    //         case 'ADVANCE':
    //             typeDisplay = t('Advance');
    //             break;
    //         case 'REVERSAL':
    //             typeDisplay = t('Reversal');
    //             break;
    //         case 'INCOME':
    //             if (invoice.incomeType === 'SIMPLE') {
    //                 typeDisplay = t('Income');
    //             } else if (invoice.incomeType === 'REVERSE') {
    //                 typeDisplay = t('Income with advance reversal');
    //             }
    //             break;
    //     }

    //     return typeDisplay?.toUpperCase();
    // };

    return (
        <>
            {activity !== null && user?.id && (
                <div className="relative mb-12 last:mb-0">
                    {/* Activity checkbox and date */}
                    <div
                        className={`absolute -left-8 top-5 h-6 w-6 rounded-full border-2 sm:-left-4 ${
                            activityBorderColors[activity.activityInfo.actionType]
                        } transition-colors ${
                            checked ? activityBackgroundColors[activity.activityInfo.actionType] : 'bg-layout-main'
                        } ${canAll ? 'cursor-pointer' : ''}`}
                        style={{ transform: 'translateX(calc(-50% - 1px))' }}
                        onClick={() => {
                            if (checked) return;

                            if (!userIsResponsible) {
                                enqueueSnackbar(
                                    t('You must be the author of this task in order to mark it as completed!'),
                                    { variant: 'error' },
                                );
                                return;
                            }

                            setChecked((prev) => !prev);

                            updateActivity(
                                activity.activityInfo,
                                activity.activityInfo.status === 'TODO' ? 'DONE' : 'TODO',
                            );
                        }}
                    >
                        <CheckIcon
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                fontSize: '1rem',
                            }}
                            className={`${checked ? 'opacity-100' : 'opacity-0'} text-buttons-text transition-opacity`}
                        />

                        <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform p-2.5 pr-5 text-right sm:flex sm:translate-x-10 sm:translate-y-2 sm:gap-6">
                            <p
                                style={{ userSelect: 'none' }}
                                className={`whitespace-nowrap text-right font-semibold ${
                                    activityTextColors[activity.activityInfo.actionType]
                                }`}
                            >{`${
                                daysFrom(activity.activityInfo.createAt) !== 0
                                    ? Math.abs(daysFrom(activity.activityInfo.createAt))
                                    : t('today')
                            } ${
                                daysFrom(activity.activityInfo.createAt) > 0
                                    ? t('days ago')
                                    : daysFrom(activity.activityInfo.createAt) === 1
                                      ? t('Yesterday')
                                      : ''
                            }`}</p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right font-semibold">
                                {formatDate(activity.activityInfo.createAt, false)}
                            </p>
                            <p style={{ userSelect: 'none' }} className="whitespace-nowrap text-right text-dark-text">
                                {formatTime(activity.activityInfo.createAt)}
                            </p>
                        </div>
                    </div>

                    {/* Activity department */}
                    <div className="absolute left-6 top-0 -translate-y-1/2 transform">
                        <div className="flex gap-4">
                            <p
                                className={`border-2 ${
                                    activityBorderColors[activity.activityInfo.actionType]
                                } cursor-pointer rounded-full px-5 py-3 font-bold`}
                                style={{
                                    backdropFilter: 'blur(6px)',
                                }}
                            >
                                {activity.activityInfo.pmDepartment.name}
                            </p>

                            {partner && (
                                <div
                                    className="cursor-pointer rounded-full bg-primary-main px-5 py-3 transition-colors hover:bg-primary-light"
                                    onClick={() => {
                                        if (activity.activityInfo.partners.id)
                                            history.push(
                                                internalActivity.base +
                                                    internalActivity.partners.base +
                                                    internalActivity.partners.update +
                                                    '/' +
                                                    activity.activityInfo.partners.id,
                                            );
                                    }}
                                >
                                    <p className="user-select-none font-semibold text-buttons-text">
                                        {partner.standard.name}
                                    </p>
                                </div>
                            )}

                            {/* {activity.activityInfo.actionType === 'INVOICES_AND_BILLS' &&
                                activity.activityInfo.invoice.isDeleted === true && (
                                    <div className=" cursor-default rounded-full bg-red-500 px-5 py-3 transition-colors hover:bg-red-400">
                                        <p className="user-select-none font-semibold">{t('Invoice is deleted')}</p>
                                    </div>
                                )} */}
                        </div>
                    </div>

                    {/* Action Arrow */}
                    <div className="absolute right-2 top-2">
                        {activity.activityInfo.actionType !== 'INVOICES_AND_BILLS' && userCanAll && (
                            <ActionArrow
                                actions={(() => {
                                    const displayArrow = actionArrow.edit || actionArrow.notify;
                                    // const displayArrow = actionArrow.edit || actionArrow.gantt || actionArrow.notify;

                                    return displayArrow
                                        ? [
                                              // {
                                              //     name: t('Edit'),
                                              //     render: canEditTasks || canEditDocuments,
                                              //     action: () => {
                                              //         setEditActivity();
                                              //     },
                                              // },
                                              {
                                                  name: t('Delete'),
                                                  render: actionArrow.edit,
                                                  action: () => {
                                                      globalContext.setGlobalModalOpen(true);
                                                      globalContext.setGlobalModalChildren(
                                                          <div className="flex-col">
                                                              <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                  {t('Are you sure you want to')}
                                                                  <br />
                                                                  {t('delete this activity?')}
                                                              </p>
                                                              <div className="flex items-center justify-center">
                                                                  <Button
                                                                      color="primary"
                                                                      onClick={() => {
                                                                          deleteActivity(activity.activityInfo);
                                                                          globalContext.setGlobalModalOpen(false);
                                                                      }}
                                                                  >
                                                                      {t('Yes')}
                                                                  </Button>
                                                                  <div className="w-4" />
                                                                  <Button
                                                                      onClick={() =>
                                                                          globalContext.setGlobalModalOpen(false)
                                                                      }
                                                                  >
                                                                      {t('No')}
                                                                  </Button>
                                                              </div>
                                                          </div>,
                                                      );
                                                  },
                                              },
                                              //   {
                                              //       name: 'Gantt',
                                              //       render: actionArrow.gantt,
                                              //       action: setSeeGantt,
                                              //   },
                                              {
                                                  name: t('Notify'),
                                                  render: actionArrow.notify,
                                                  action: () => {
                                                      onNotify(activity.activityInfo.id);
                                                  },
                                              },
                                          ]
                                        : [];
                                })()}
                            />
                        )}
                    </div>

                    <div className="mb-1.5 rounded-md bg-layout-transparent px-6 py-4 pt-10 text-main-text transition-colors sm:pt-24">
                        {/* Actitity name (header) */}
                        {activity.actionType === 'INVOICES_AND_BILLS' ? (
                            <h3 className="mb-5 font-semibold">{activity.activityInfo.invoice?.name}</h3>
                        ) : (
                            <h3 className="mb-5 font-semibold">{activity.activityInfo.title}</h3>
                        )}

                        {/* Activity body */}
                        {activity.activityInfo.actionType === 'COMMENT' && (
                            <>
                                {activity.activityInfo.description && (
                                    <p className="mb-5">{activity.activityInfo.description}</p>
                                )}
                            </>
                        )}

                        {activity.activityInfo.actionType === 'TASK' && (
                            <Fragment>
                                {activity.activityInfo.description &&
                                    activity.activityInfo.activitiesTasks.length > 0 && (
                                        <iframe
                                            className="mb-5 h-64 w-full rounded-md border border-layout-lighter bg-white p-2 md:h-96"
                                            srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                                                activity.activityInfo.description,
                                            )}`}
                                        />
                                    )}

                                {activity.activityInfo.description &&
                                    activity.activityInfo.activitiesTasks.length === 0 && (
                                        <p className="mb-5 font-semibold">{activity.activityInfo.description}</p>
                                    )}

                                {activity.activityInfo.activitiesTasks.length <= 0 && (
                                    <div className="mb-1 flex gap-2">
                                        <span className="text-dark-text">{t('Task interval')}:</span>
                                        <div className="font-bold text-main-text opacity-70">
                                            {formatDate(activity.activityInfo.startDate)}
                                        </div>
                                        <span className="font-bold text-main-text">-</span>
                                        <div className="font-bold text-main-text opacity-70">
                                            {formatDate(
                                                activity.activityInfo.endDate
                                                    ? activity.activityInfo.endDate
                                                    : activity.activityInfo.startDate,
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {/* {activity.activityInfo.actionType === 'FILES' && (
                            <>
                                {activity.activityInfo.description && (
                                    <p className="mb-5">{activity.activityInfo.description}</p>
                                )}
                                <div className="mb-3">
                                    {activity.fileInfoArray.map((file) => (
                                        <PMTaskManagementDisplayFiles
                                            key={file.id}
                                            type={'FILE'}
                                            file={file}
                                            deleteFileFromActivity={deleteFileFromActivity}
                                        />
                                    ))}
                                </div>
                            </>
                        )}

                        {activity.activityInfo.actionType === 'DOCUMENTS' && (
                            <>
                                {activity.activityInfo.description && (
                                    <p className="mb-5">{activity.activityInfo.description}</p>
                                )}

                                <div className="mb-3">
                                    {activity.fileInfoArray.map((file) => (
                                        <PMTaskManagementDisplayFiles
                                            key={file.id}
                                            type={'Document'}
                                            file={file}
                                            deleteFileFromActivity={deleteFileFromActivity}
                                        />
                                    ))}
                                </div>
                            </>
                        )}

                        {activity.activityInfo.actionType === 'INVOICES_AND_BILLS' && (
                            <>
                                {activity.activityInfo.description && (
                                    <p className="mb-3 font-semibold text-lg italic">
                                        {t('Invoice type')}: {invoiceTypeDisplayed(activity.activityInfo.invoice)}
                                    </p>
                                )}

                                <h3 className="text-lg mb-5">
                                    {t('Invoice value')}:{' '}
                                    <span className="font-semibold">{activity.activityInfo.invoice?.value}</span>{' '}
                                    {currencyObj.currency}
                                </h3>

                                {activity.fileInfoArray?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.fileInfoArray.map((file) => (
                                            <div
                                                key={file.id}
                                                className="group p-1 pr-2 flex items-center justify-between bg-layout-transparent hover:bg-blue-500 hover:bg-opacity-40 transition-colors rounded-lg mb-1"
                                            >
                                                <div className="w-28 flex-shrink-0 flex justify-center items-center rounded-md px-1 py-2 mr-4 bg-green-500">
                                                    <EuroSymbolIcon
                                                        className="text-white mr-2"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <p className="font-semibold">{t('Bills')}</p>
                                                </div>
                                                <p className="font-semibold w-full">{file.name}</p>

                                                <div className="flex items-center justify-self-end">
                                                    <div className="flex gap-2">
                                                        <div className="flex items-center">
                                                            <BasicTooltip tip={t('Download invoice')}>
                                                                <div className="cursor-pointer bg-blue-400 hover:bg-blue-500 w-7 h-7 rounded-full flex items-center justify-center  transform -translate-y-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 group-hover:pointer-events-auto transition-all">
                                                                    <a
                                                                        href={file.link}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <GetAppIcon
                                                                            className="text-white"
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            </BasicTooltip>
                                                        </div>

                                                        {canAll && (
                                                            <div className="flex items-center">
                                                                <BasicTooltip
                                                                    tip={`${
                                                                        file.type == 'FILE'
                                                                            ? t('Delete file')
                                                                            : t('Delete document')
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className="cursor-pointer bg-red-500 hover:bg-red-600 w-7 h-7 rounded-full flex justify-center items-center opacity-0 transform -translate-y-2 group-hover:opacity-100 group-hover:translate-y-0 group-hover:pointer-events-auto transition-all"
                                                                        onClick={() => {
                                                                            deleteFileFromActivity(file.id);
                                                                        }}
                                                                    >
                                                                        <CloseIcon
                                                                            className="text-white"
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </div>
                                                                </BasicTooltip>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )} */}

                        {(activity.activityInfo.actionType === 'FILES' ||
                            activity.activityInfo.actionType === 'DOCUMENTS') && (
                            <>
                                {activity.activityInfo.Files?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.activityInfo.Files.map((file) => (
                                            <div
                                                key={file.id}
                                                className="mb-1 flex items-center justify-between rounded-lg bg-layout-transparent p-1 pr-2 transition-colors hover:bg-layout-transparent-dark"
                                            >
                                                <div
                                                    className={`flex w-36 flex-shrink-0 items-center justify-center rounded-md ${
                                                        activityBackgroundColors[activity.activityInfo.actionType]
                                                    } mr-4 px-4 py-2`}
                                                >
                                                    <EuroSymbolIcon
                                                        className="mr-2 text-main-text"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <p className="font-semibold">
                                                        {activity.activityInfo.actionType === 'FILES'
                                                            ? t('Files')
                                                            : activity.activityInfo.actionType === 'DOCUMENTS'
                                                              ? t('Documents')
                                                              : t('Bills')}
                                                    </p>
                                                </div>
                                                <p className="w-full font-semibold">{file.file.name}</p>

                                                <div className="flex flex-shrink-0 items-center justify-self-end">
                                                    <div className="group relative mr-1">
                                                        <a href={file.file.url} target="_blank" rel="noreferrer">
                                                            <BasicTooltip tip="Link">
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<LinkIcon />}
                                                                />
                                                            </BasicTooltip>
                                                        </a>
                                                    </div>

                                                    <div className="group relative mr-1">
                                                        <BasicTooltip tip={t('Download')}>
                                                            <a href={file.file.url} download={file.file.name}>
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<GetAppIcon />}
                                                                />
                                                            </a>
                                                        </BasicTooltip>
                                                    </div>

                                                    {/* {deleteIcon && (
                                                        <div className="relative group">
                                                            <BasicTooltip tip="Șterge">
                                                                <ActionButton onClick={onDelete} icon={<CloseIcon />} />
                                                            </BasicTooltip>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        {/* {activity.activityInfo.actionType === 'INVOICES_AND_BILLS' && (
                            <>
                                {activity.activityInfo.Files?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.activityInfo.Files.map((file) => (
                                            <div
                                                key={file.id}
                                                className="mb-1 flex items-center justify-between rounded-lg bg-layout-transparent p-1 pr-2 transition-colors hover:bg-layout-transparent-dark"
                                            >
                                                <div
                                                    className={`flex w-36 flex-shrink-0 items-center justify-center rounded-md ${
                                                        activityBackgroundColors[activity.activityInfo.actionType]
                                                    } mr-4 px-4 py-2`}
                                                >
                                                    <EuroSymbolIcon
                                                        className="mr-2 text-white"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <p className="font-semibold">
                                                        {activity.activityInfo.actionType === 'FILES'
                                                            ? t('Files')
                                                            : activity.activityInfo.actionType === 'DOCUMENTS'
                                                            ? t('Documents')
                                                            : t('Bills')}
                                                    </p>
                                                </div>
                                                <p className="w-full font-semibold">{file.file.name}</p>

                                                <div className="flex flex-shrink-0 items-center justify-self-end">
                                                    <div className="group relative mr-1">
                                                        <a href={file.file.url} target="_blank" rel="noreferrer">
                                                            <BasicTooltip tip="Link">
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<LinkIcon />}
                                                                />
                                                            </BasicTooltip>
                                                        </a>
                                                    </div>

                                                    <div className="group relative mr-1">
                                                        <BasicTooltip tip="Descarca">
                                                            <a href={file.file.url} download={file.file.name}>
                                                                <ActionButton
                                                                    onClick={() => null}
                                                                    icon={<GetAppIcon />}
                                                                />
                                                            </a>
                                                        </BasicTooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )} */}

                        {/* Activity footer */}
                        {/* Activity Author */}
                        <div className="mb-5 flex items-center">
                            <div className="mr-5">
                                <span className="pointer-events-none text-dark-text">{t('Activity author')}: </span>
                                <span
                                    className={`font-semibold text-main-text ${userCanAll && userLink && linkStyle}`}
                                    onClick={() => {
                                        if (userCanAll) {
                                            history.push(
                                                configurator.base +
                                                    configurator.users.base +
                                                    configurator.users.update +
                                                    '/' +
                                                    activity.activityInfo.authorId +
                                                    '?tab=1',
                                            );
                                        }
                                    }}
                                >
                                    {activity.activityInfo.author?.profile?.name}
                                </span>
                            </div>

                            {/* Activity Asignee */}
                            {responsible && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">{t('Responsible')}: </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {responsible}
                                    </span>
                                </div>
                            )}
                            {supervisor && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">{t('Supervisor')}: </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {supervisor}
                                    </span>
                                </div>
                            )}
                            {watcher && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">
                                        {t('Watcher / Beneficiary')}:{' '}
                                    </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">{watcher}</span>
                                </div>
                            )}
                        </div>

                        {/* Add a comment */}
                        {canAll && (
                            <AddComment
                                onSubmit={(newComment, newCommentFiles) =>
                                    submitHandle(activity.activityInfo.id, newComment, newCommentFiles)
                                }
                            />
                        )}
                    </div>

                    {/* Comment section */}
                    {activity.activityInfo.comments?.length > 0 && (
                        <div className="relative">
                            {activity.activityInfo.comments.map((comment, index) => (
                                <div
                                    key={comment.id}
                                    style={{
                                        // zIndex: activity.activityInfo.comments.length - index + 1,
                                        position: 'relative',
                                    }}
                                >
                                    <Comment userId={user.id} comment={comment} />
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Submilestone section */}
                    {activity.activityInfo.actionType === 'TASK' &&
                        activity.activityInfo.activitiesTasks?.length > 0 && (
                            <div className="relative">
                                {activity.activityInfo.activitiesTasks.map((submilestone, index) => (
                                    <div
                                        key={submilestone.id}
                                        style={{
                                            // zIndex: activity.activityInfo.activitiesTasks.length - index,
                                            position: 'relative',
                                        }}
                                    >
                                        <PMTaskManagementSubmilestoneDisplay
                                            submilestone={submilestone}
                                            userIsResponsible={userIsResponsible}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                </div>
            )}
        </>
    );
};

TaskManagementDisplay.propTypes = {
    onLink: PropTypes.func,
    onDownload: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onNotify: PropTypes.func,
    activity: PropTypes.object,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func,
    setUpdatedActivity: PropTypes.func,
    actionArrow: PropTypes.object,
    deleteIcon: PropTypes.bool,
    commentView: PropTypes.bool,
    check: PropTypes.bool,
    userLink: PropTypes.bool,
    fetchActivities: PropTypes.func,
};

TaskManagementDisplay.defaultProps = {
    onLink: () => null,
    onDownload: () => null,
    onEdit: () => null,
    onDelete: () => null,
    onNotify: () => null,
    activity: null,
    deleteActivity: () => null,
    updateActivity: () => null,
    setUpdatedActivity: () => null,
    actionArrow: {
        edit: false,
        gantt: false,
        notify: false,
    },
    deleteIcon: false,
    commentView: false,
    check: false,
    userLink: false,
    fetchActivities: () => null,
};

export default TaskManagementDisplay;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';

import ResourcesAccessGroupsForm from 'components/resources-access-groups/resources-access-groups-form';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getTenantUsers } from 'utils/getterFunctions';

const ResourcesAccessGroupsUpdate = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState(null);

    const getData = async () => {
        try {
            const [groupRes, usersRes] = await Promise.all([API.get('group', { params: { id } }), getTenantUsers()]);

            setGroup(groupRes.data);
            setUsers(usersRes);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user.isAdmin) {
            history.push('/');
        }

        getData();
    }, [user]);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Edit group')}</title>
            </Helmet>

            <Header pageTitle={t('Edit group')} />

            <div className="page-container">
                {loading ? (
                    <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <ResourcesAccessGroupsForm users={users} initialGroup={group} />
                )}
            </div>
        </>
    );
};

export default ResourcesAccessGroupsUpdate;

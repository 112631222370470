import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from 'lucide-react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { ReactComponent as Logo } from 'assets/sidebar/svgs/logo.svg';

import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';

export const NewSidebar = (props) => {
    const {
        logoAction,
        userDropdownActions,
        imageUrl,
        userName,
        footer,
        disabled,
        navItems,
        moduleName,
        backUrl,
        initialSelectedItems,
    } = props;

    const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

    const handleChangeSelectedItems = (pairs) =>
        setSelectedItems((prev) => {
            const clonedSelection = structuredClone(prev);
            pairs.forEach(({ index, value }) => {
                clonedSelection[index] = value;
            });
            return clonedSelection;
        });

    const history = useHistory();
    const handleGoBack = () => history.push(backUrl ?? '/');

    const { theme } = useContext(GlobalContext);

    /**
     * Controls whenever the user dropdown is opened or not
     */
    const [userDropdown, setUserDropdown] = useState(false);

    const [openMenu, setOpenMenu] = useState(false);

    const [width, height] = useScreenSizes();

    const displayMobileNavHandler = () => {
        setOpenMenu((prev) => !prev);
        setUserDropdown(false);
    };

    useEffect(() => {
        setSelectedItems(initialSelectedItems);
    }, [initialSelectedItems]);

    useEffect(() => {
        if (width > 750) {
            setOpenMenu(false);
            setUserDropdown(false);
            return;
        }
    }, [width, height]);

    return (
        <div className={`main-sidebar ${disabled ? 'pointer-events-none' : ''} ${openMenu ? 'main-sidebar-open' : ''}`}>
            <div className={`inner-sidebar ${disabled ? 'inner-sidebar-disabled' : ''} ${openMenu ? 'gap-10' : ''}`}>
                {/*
            Upper sidebar section
            Logo, User Avatar, User Dropdown
         */}
                <div className="relative z-20 flex w-full flex-shrink-0 flex-col items-start gap-5 p-5 md:my-3 md:flex-row md:items-center md:justify-between md:px-5 md:py-0">
                    {/* Logo */}
                    {/* eslint-disable-next-line */}
                    <div
                        className="flex w-full cursor-pointer items-center justify-start opacity-75 transition-opacity hover:opacity-100 md:opacity-100"
                        onClick={logoAction}
                    >
                        <div className="relative mr-5 h-10 w-10 flex-shrink-0">
                            <Logo className="logo absolute left-1/2 top-1/2 h-full w-full flex-shrink-0 -translate-x-1/2 -translate-y-1/2 transform" />
                        </div>
                        <p className="appear-on-expand user-select-none whitespace-nowrap text-lg font-medium uppercase text-sidebar-text">
                            M2M ERP
                        </p>
                    </div>

                    {/* User */}
                    {/* eslint-disable-next-line */}
                    <div
                        className="relative flex w-full cursor-pointer items-center opacity-75 transition-opacity hover:opacity-100 md:w-auto md:opacity-100"
                        onClick={() => setUserDropdown((prev) => !prev)}
                        onMouseLeave={() => setUserDropdown(false)}
                    >
                        <div
                            className="mr-5 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border border-sidebar-text bg-cover bg-center md:mr-3"
                            style={{
                                backgroundImage: imageUrl ? `url('${imageUrl}')` : undefined,
                            }}
                        >
                            {!imageUrl && <User className="h-4/5 w-4/5" />}
                        </div>
                        <p className="appear-on-expand relative w-full whitespace-nowrap text-lg font-medium text-sidebar-text md:hidden">
                            {userName}
                        </p>
                        {userDropdownActions.length > 0 && (
                            <div
                                className={`absolute top-full transform overflow-hidden pt-1 transition-all md:right-0 ${
                                    userDropdown
                                        ? 'pointer-events-auto translate-x-0 opacity-100'
                                        : 'pointer-events-none translate-x-5 opacity-0'
                                }`}
                            >
                                <div className="rounded-md bg-primary-light">
                                    <ul>
                                        {/* eslint-disable-next-line */}
                                        <li
                                            className="cursor-pointer whitespace-nowrap px-8 py-3 text-sidebar-text hover:bg-layout-transparent"
                                            onClick={() => {
                                                userDropdownActions[0].handle();
                                                setOpenMenu(false);
                                            }}
                                        >
                                            {userDropdownActions[0].name}
                                        </li>
                                        {/* eslint-disable-next-line */}
                                        <li
                                            className="cursor-pointer whitespace-nowrap px-8 py-3 text-sidebar-text hover:bg-layout-transparent"
                                            onClick={() => {
                                                userDropdownActions[1].handle();
                                                setOpenMenu(false);
                                            }}
                                        >
                                            {userDropdownActions[1].name}
                                        </li>
                                        {/* eslint-disable-next-line */}
                                        <li
                                            className="flex cursor-pointer items-center whitespace-nowrap px-8 py-3 text-sidebar-text hover:bg-layout-transparent"
                                            style={{
                                                borderTop: `1px solid rgb(${theme['TEXT-COLORS']['MAIN-TEXT'].color} / 25%)`,
                                            }}
                                            onClick={() => {
                                                userDropdownActions[2].handle();
                                                setOpenMenu(false);
                                            }}
                                        >
                                            <ExitToAppIcon className="mr-3" />
                                            {userDropdownActions[2].name}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Mobile navigation toggle */}
                    <div className="hidden cursor-pointer flex-col gap-1 md:flex" onClick={displayMobileNavHandler}>
                        <span
                            className={`inline-block h-1 w-8 rounded-sm bg-white transition-all duration-300 ${
                                openMenu ? 'translate-y-2 rotate-45 transform' : ''
                            }`}
                        />
                        <span className={`inline-block h-1 w-8 rounded-sm bg-white ${openMenu ? 'opacity-0' : ''}`} />
                        <span
                            className={`inline-block h-1 w-8 rounded-sm bg-white transition-all duration-300 ${
                                openMenu ? '-translate-y-2 -rotate-45 transform' : ''
                            }`}
                        />
                    </div>
                </div>

                {/* NAV */}
                <div
                    className={`sidebar-content relative z-10 flex flex-grow flex-col  ${
                        openMenu ? 'delay-sidebar-content justify-start md:flex' : 'justify-center md:hidden'
                    }`}
                >
                    {/* Platform */}
                    {moduleName && (
                        <div className="flex items-center justify-start pb-5 pl-5">
                            <div
                                className="group flex w-10 flex-shrink-0 cursor-pointer items-center justify-center text-center"
                                onClick={handleGoBack}
                            >
                                <ArrowBackIcon className="text-md transform text-sidebar-text transition-transform duration-300 group-hover:-translate-x-1" />
                            </div>
                            <h1 className="appear-on-expand whitespace-nowrap pl-5 text-2xl font-bold uppercase tracking-widest text-sidebar-text">
                                {moduleName.length > 18 ? `${moduleName.slice(0, 18)}...` : moduleName}
                            </h1>
                        </div>
                    )}

                    {/* Nav Items */}
                    <ul
                        className="nav-list"
                        style={{
                            borderBottom: `1px solid rgb(var(--base-sidebar-text) / 25%)`,
                            borderTop: `1px solid rgb(var(--base-sidebar-text) / 25%)`,
                        }}
                    >
                        {navItems.map((item, index) => {
                            const liClassName = ['', ''];
                            liClassName[0] = 'relative group w-full text-white bg-white';
                            liClassName[1] = selectedItems[0] === index ? 'bg-opacity-5' : 'bg-opacity-0';

                            return (
                                <li
                                    key={item.name}
                                    className={liClassName.join(' ')}
                                    style={{
                                        borderBottom:
                                            index !== navItems.length - 1
                                                ? `1px solid rgb(var(--base-sidebar-text) / 25%)`
                                                : '',
                                    }}
                                    onClick={() => {
                                        if ('onRoute' in item) {
                                            setOpenMenu(false);
                                            setUserDropdown(false);
                                            item.onRoute();
                                            return;
                                        }

                                        if (selectedItems[0] === index)
                                            handleChangeSelectedItems([
                                                { index: 0, value: -1 },
                                                { index: 1, value: -1 },
                                            ]);
                                        else
                                            handleChangeSelectedItems([
                                                { index: 0, value: index },
                                                { index: 1, value: -1 },
                                            ]);
                                    }}
                                >
                                    <div className="flex cursor-pointer items-center justify-between px-5 py-5 opacity-75 transition-opacity group-hover:opacity-100">
                                        <div className="flex items-center justify-start">
                                            <div className="relative">
                                                {item.icon}
                                                <div
                                                    className={`absolute left-1/2 top-9 h-1 -translate-x-1/2 transform rounded-md bg-sidebar-text bg-opacity-20 transition-all ${
                                                        selectedItems[0] === index ? 'w-10' : 'w-0'
                                                    }`}
                                                />
                                            </div>
                                            <p
                                                className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium text-sidebar-text"
                                                style={{ lineHeight: 1.2, fontWeight: 600 }}
                                            >
                                                {item.name}
                                            </p>
                                        </div>

                                        {!('onRoute' in item) && (
                                            <DownArrowIcon
                                                className={`transform text-sidebar-text transition-transform ${
                                                    selectedItems[0] === index ? 'rotate-180' : 'rotate-0'
                                                }`}
                                            />
                                        )}
                                    </div>

                                    {/* If selected then sub items appear HERE */}
                                    {'subItems' in item && selectedItems[0] === index && (
                                        <div className="py-2.5 pl-14">
                                            {item.subItems.map((subItem, subIndex) => {
                                                return (
                                                    // eslint-disable-next-line
                                                    <Fragment key={subItem.name}>
                                                        <div
                                                            className="flex cursor-pointer items-center py-2 opacity-75 transition-opacity hover:opacity-100"
                                                            onClick={(e) => {
                                                                e.stopPropagation();

                                                                if ('onRoute' in subItem) {
                                                                    setOpenMenu(false);
                                                                    setUserDropdown(false);
                                                                    subItem.onRoute();
                                                                    return;
                                                                }

                                                                if (selectedItems[1] === subIndex)
                                                                    handleChangeSelectedItems([
                                                                        { index: 1, value: -1 },
                                                                    ]);
                                                                else
                                                                    handleChangeSelectedItems([
                                                                        { index: 1, value: subIndex },
                                                                    ]);
                                                            }}
                                                        >
                                                            <span className="move-on-expand relative">
                                                                {subItem.icon}
                                                                <span
                                                                    className={`absolute left-1/2 top-9 h-1 -translate-x-1/2 transform rounded-md bg-sidebar-text bg-opacity-20 transition-all ${
                                                                        selectedItems[1] === subIndex ? 'w-10' : 'w-0'
                                                                    }`}
                                                                />
                                                            </span>
                                                            {/* eslint-disable-next-line */}
                                                            <p
                                                                className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium text-sidebar-text"
                                                                style={{ lineHeight: 1.2, fontWeight: 600 }}
                                                            >
                                                                {subItem.name}
                                                            </p>

                                                            {!('onRoute' in subItem) && (
                                                                <DownArrowIcon
                                                                    className={`ml-auto mr-5 transform text-sidebar-text transition-transform ${
                                                                        selectedItems[1] === subIndex
                                                                            ? 'rotate-180'
                                                                            : 'rotate-0'
                                                                    }`}
                                                                />
                                                            )}
                                                        </div>

                                                        {/* If selected then sub items appear HERE */}
                                                        {'subItems' in subItem && selectedItems[1] === subIndex && (
                                                            <div className="py-2.5 pl-7">
                                                                {subItem.subItems.map((childItem) => {
                                                                    return (
                                                                        // eslint-disable-next-line

                                                                        <div
                                                                            key={childItem.name}
                                                                            className="flex cursor-pointer items-center py-2 opacity-75 transition-opacity hover:opacity-100"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setOpenMenu(false);
                                                                                setUserDropdown(false);
                                                                                childItem.onRoute();
                                                                            }}
                                                                        >
                                                                            <span className="move-child-on-expand">
                                                                                {childItem.icon}
                                                                            </span>
                                                                            {/* eslint-disable-next-line */}
                                                                            <p
                                                                                className="appear-on-expand ml-3 flex h-8 items-center whitespace-nowrap font-medium text-sidebar-text"
                                                                                style={{
                                                                                    lineHeight: 1.2,
                                                                                    fontWeight: 600,
                                                                                }}
                                                                            >
                                                                                {childItem.name}
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {/* Footer */}
                <div
                    className={`sidebar-content delay-sidebar-content z-50 mb-20 flex w-full md:justify-center ${
                        openMenu ? ' md:flex' : 'md:hidden'
                    }`}
                >
                    {footer}
                </div>
            </div>
        </div>
    );
};

NewSidebar.propTypes = {
    logoAction: PropTypes.func,
    userName: PropTypes.string,
    imageUrl: PropTypes.string,
    userDropdownActions: PropTypes.arrayOf(PropTypes.object),
    footer: PropTypes.element,
    navItems: PropTypes.arrayOf(PropTypes.object),
    moduleName: PropTypes.string,
    disabled: PropTypes.bool,
    backUrl: PropTypes.string,
    initialSelectedItems: PropTypes.array,
};

NewSidebar.defaultProps = {
    logoAction: () => null,
    userName: '',
    imageUrl: '',
    userDropdownActions: [],
    footer: <></>,
    navItems: [],
    moduleName: '',
    disabled: true,
    backUrl: null,
    initialSelectedItems: [],
};

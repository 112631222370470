import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { crm, internalActivity, projectManagement } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import { getContacts, getPartners } from 'utils/getterFunctions';
import * as yup from 'yup';

const STATUSES = ['NEW', 'IN_PROGRESS', 'REVIEW', 'DONE'];

const PMProjectForm = ({ projectInfo, pmId }) => {
    const { theme } = useContext(GlobalContext);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [loadingContracts, setLoadingContracts] = useState(false);

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedAssociatedClients, setSelectedAssociatedClients] = useState([]);

    const [partners, setPartners] = useState([]);
    const [partnersOptions, setPartnersOptions] = useState([]);
    const [selectedAssociatedPartners, setSelectedAssociatedPartners] = useState([]);

    const [projectStructures, setProjectStructures] = useState([]);
    const [selectedProjectStructure, setSelectedProjectStructure] = useState(null);

    const [contractsOptions, setContractsOptions] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);

    const [projectName, setProjectName] = useState(null);

    const [teams, setTeams] = useState([]);

    //TODO: Update status logic later
    const [selectedStatus, setSelectedStatus] = useState(null);

    const defaultTeam = {
        order: teams.length + 1,
        name: null,
    };

    const editTeam = useCallback(
        (index, key, value) => {
            const newTeams = [...teams];
            newTeams[index][key] = value;
            setTeams(newTeams);
        },
        [teams],
    );

    const deleteTeam = useCallback(
        (index) => {
            setTeams(teams.filter((_, i) => i !== index));
        },
        [teams],
    );

    const getHiddenClient = () => {
        if (selectedClient === null || clients.length === 0) return [];

        const selectedClientId = clients[selectedClient].id;
        const indexOfSelectedClient = clients.findIndex((client) => client.id === selectedClientId);

        if (indexOfSelectedClient > -1) return [indexOfSelectedClient];

        return [];
    };

    let schema = yup.object().shape({
        teams: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('The name of the team is required!'))
                        .required(t('The name of the team is required!')),
                }),
            )
            .min(1, t('The project must have at least one team!'))
            .required(t('The project must have at least one team!')),

        selectedClient: yup
            .number()
            .typeError(t('Project must have a client!'))
            .required(t('Project must have a client!')),
        projectName: yup
            .string()
            .trim()
            .typeError(t('Project name must be at least 3 characters long!'))
            .required(t('Project name must be at least 3 characters long!')),
    });

    const createProject = async () => {
        try {
            setLoading(true);
            await schema.validate({
                projectName,
                selectedClient,
                teams,
            });

            try {
                await API.post('/pm_projectsPlanningOverview', {
                    pMId: pmId,
                    name: projectName,
                    contactId: clients[selectedClient]?.id ?? null,
                    contractId: contracts[selectedContract]?.id ?? null,
                    projectStructureId: projectStructures[selectedProjectStructure]?.id ?? null,
                    contacts: selectedAssociatedClients.map((c) => ({ id: clients[c].id })),
                    partners: selectedAssociatedPartners.map((p) => ({ id: partners[p].id })),
                    teams: teams.map((t) => ({ name: t.name })),
                });
                enqueueSnackbar(t('The project has been successfully added!'), {
                    variant: 'success',
                });
                setLoading(false);
                history.push(projectManagement.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const editProject = async () => {
        try {
            setLoading(true);
            await schema.validate({
                projectName,
                selectedClient,
                teams,
            });

            try {
                await API.put('/pm_projectsPlanningOverview', {
                    id: projectInfo.id,
                    data: {
                        name: projectName,
                        contactId: clients[selectedClient]?.id ?? null,
                        contractId: contracts[selectedContract]?.id ?? null,
                        projectStructureId: projectStructures[selectedProjectStructure]?.id ?? null,
                        contacts: selectedAssociatedClients.map((c) => ({ id: clients[c].id })),
                        partners: selectedAssociatedPartners.map((p) => ({ id: partners[p].id })),
                        teams: teams.map((t) => ({
                            id: t.id ? t.id : undefined,
                            name: t.name,
                            employees: t.employees?.map((e) => ({
                                employeeId: e.employee.id,
                                employeeTeamId: e.employee.employeeTeamId ? e.employee.employeeTeamId : undefined,
                                tasks: [],
                            })),
                        })),
                        status:
                            STATUSES[selectedStatus] !== projectInfo.statusHistory.at(-1).status
                                ? STATUSES[selectedStatus]
                                : undefined,
                    },
                });
                enqueueSnackbar(t('The project has been successfully updated!'), {
                    variant: 'success',
                });
                setLoading(false);
                history.push(projectManagement.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const getNewContracts = async (id) => {
        setLoadingContracts(true);

        try {
            const resContracts = await API.get('/contactsContracts', { params: { contactId: id } });
            const newDisplayedContracts = resContracts.data.contactsContracts.contracts;
            setContracts(newDisplayedContracts);

            if (newDisplayedContracts.length !== 0) {
                const contractsOptions = newDisplayedContracts.map((c) => {
                    const data = typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                    return 'standard' in data && 'number' in data['standard']
                        ? data['standard'].number
                        : 'Numar contract inexistent';
                });

                setContractsOptions(contractsOptions);
            } else {
                setContractsOptions([]);
            }

            return newDisplayedContracts;
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingContracts(false);
        }
    };

    /**
     * Fetch the data for the project
     */
    const fetchData = async () => {
        try {
            const params = {
                perPage: 999999,
                currentPage: 0,
                pagesToLoad: 1,
            };

            const [clientsResponse, rowResponseProjectStructures, partnerResponse] = await Promise.all([
                getContacts(),
                API.get('projectStructures', { params }),
                getPartners(),
            ]);

            setClients(clientsResponse);
            setClientOptions(
                clientsResponse.map((c) => {
                    const data = JSON.parse(c.data);
                    return 'standard' in data && 'name' in data['standard'] ? data['standard'].name : 'Nume inexistent';
                }),
            );

            const projectStructuresResponse = rowResponseProjectStructures.data.data.data;
            setProjectStructures(projectStructuresResponse);

            setPartners(partnerResponse);
            setPartnersOptions(
                partnerResponse.map((partner) => {
                    const data = typeof partner.data === 'string' ? JSON.parse(partner.data) : partner.data;
                    return data['standard'].name;
                }),
            );

            return { clientsResponse, partnerResponse, projectStructuresResponse };
        } catch (err) {
            console.error(err);
        }
    };

    const loadDataForEdit = async () => {
        try {
            setLoading(true);

            const { clientsResponse, partnerResponse, projectStructuresResponse } = await fetchData();
            const contractsResponse = await getNewContracts(projectInfo.contactId, projectInfo.partnerId);

            setTeams(projectInfo.teams);
            setProjectName(projectInfo.name);
            setSelectedClient(clientsResponse.findIndex((c) => c.id === projectInfo.contactId));
            setSelectedStatus(STATUSES.indexOf(projectInfo.statusHistory.at(-1).status));

            if (projectInfo.projectStructureId)
                setSelectedProjectStructure(
                    projectStructuresResponse.findIndex((c) => c.id === projectInfo.projectStructureId),
                );

            const selectedAssociatedClients = projectInfo.Contact.reduce((acc, client) => {
                const indexOfClient = clientsResponse.findIndex((c) => c.id === client.id);

                if (indexOfClient >= 0) return [...acc, indexOfClient];

                return acc;
            }, []);

            setSelectedAssociatedClients(selectedAssociatedClients);

            const selectedAssociatedPartners = projectInfo.Partners.reduce((acc, partner) => {
                const indexOfPartner = partnerResponse.findIndex((p) => p.id === partner.id);

                if (indexOfPartner >= 0) return [...acc, indexOfPartner];

                return acc;
            }, []);

            setSelectedAssociatedPartners(selectedAssociatedPartners);

            if (projectInfo.contractId)
                setSelectedContract(contractsResponse.findIndex((c) => c.id === projectInfo.contractId));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const loadData = async () => {
        try {
            setLoading(true);

            const { state } = location;

            if (state) {
                const { clientsResponse, partnerResponse } = await fetchData();
                const contractsResponse = await getNewContracts(state.contactId, state.partnerId);

                if (state.contactId) setSelectedClient(clientsResponse.findIndex((c) => c.id === state.contactId));
                if (state.partnerId)
                    setSelectedAssociatedPartners([partnerResponse.findIndex((p) => p.id === state.partnerId)]);
                if (state.contractId)
                    setSelectedContract(contractsResponse.findIndex((c) => c.id === state.contractId));
            } else {
                await fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (projectInfo) {
            loadDataForEdit();
        } else {
            loadData();
        }
    }, [projectInfo]);

    return (
        <>
            {loading ? (
                <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className="flex w-full flex-col space-y-10">
                    <div className="max-w-xl">
                        <TextField
                            name={t('Project name')}
                            label={t('Project name')}
                            placeholder={t('Project name')}
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-wrap gap-10 sm:max-w-xl sm:flex-col">
                        {/* Customers */}
                        <div className="flex flex-col gap-2">
                            <h3 className="text-dark-text">{t('Customer on this project')}</h3>
                            {clientOptions.length > 0 ? (
                                <LabelWrapper label={t('Choose the customer for this project')}>
                                    <Dropdown
                                        options={clientOptions}
                                        selectedOption={selectedClient}
                                        setSelectedOption={(i) => {
                                            setSelectedClient(i);
                                            getNewContracts(clients[i].id);
                                        }}
                                        placeholder={t('Customer')}
                                    />
                                </LabelWrapper>
                            ) : (
                                <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                    <p className="mb-5">{t('There are no customers yet, you have to add one first')}</p>
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="secondary"
                                        onClick={() => history.push(crm.base + crm.contacts.base + crm.contacts.create)}
                                    >
                                        {t('Add customer')}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {/* Project Clients */}
                        <div className="flex flex-col gap-2">
                            <h3 className="text-dark-text">{t('Associated costumers on this project')}</h3>
                            {clientOptions.length > 0 ? (
                                <LabelWrapper label={t('Choose the associated costumers for this project')}>
                                    <MultiDropdown
                                        options={clientOptions}
                                        selectedOptions={selectedAssociatedClients}
                                        setSelectedOptions={(i) => {
                                            setSelectedAssociatedClients((prev) => {
                                                const newAssociatedClients = structuredClone(prev);
                                                const isPresent = newAssociatedClients.indexOf(i);

                                                if (isPresent >= 0)
                                                    return newAssociatedClients.filter((ac) => ac !== i);

                                                return [...newAssociatedClients, i];
                                            });
                                        }}
                                        placeholder={t('Associated costumers')}
                                        disabled={selectedClient === null}
                                        hiddenOptions={getHiddenClient()}
                                    />
                                </LabelWrapper>
                            ) : (
                                <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                    <p className="mb-5">{t('There are no customers yet, you have to add one first')}</p>
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="secondary"
                                        onClick={() => history.push(crm.base + crm.contacts.base + crm.contacts.create)}
                                    >
                                        {t('Add customer')}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {/* Project Partners */}
                        <div className="flex flex-col gap-2">
                            <h3 className="text-gray-300">{t('Associated partners on this project')}</h3>
                            {partners.length > 0 ? (
                                <LabelWrapper label={t('Choose the associated partners for this project')}>
                                    <MultiDropdown
                                        options={partnersOptions}
                                        selectedOptions={selectedAssociatedPartners}
                                        setSelectedOptions={(i) => {
                                            setSelectedAssociatedPartners((prev) => {
                                                const newAssociatedPartners = structuredClone(prev);
                                                const isPresent = newAssociatedPartners.indexOf(i);

                                                if (isPresent >= 0)
                                                    return newAssociatedPartners.filter((ac) => ac !== i);

                                                return [...newAssociatedPartners, i];
                                            });
                                        }}
                                        placeholder={t('Associated partners')}
                                    />
                                </LabelWrapper>
                            ) : (
                                <div className="bg-black-transparent mb-5 flex flex-col items-center justify-center rounded-md px-4 py-8 shadow">
                                    <p className="mb-5">{t('There are no partners yet, you have to add one first')}</p>
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                internalActivity.base +
                                                    internalActivity.partners.base +
                                                    internalActivity.partners.create,
                                            )
                                        }
                                    >
                                        {t('Add partner')}
                                    </Button>
                                </div>
                            )}
                        </div>

                        {/* Project Structure */}
                        <div className="flex flex-col gap-2">
                            <h3 className="text-dark-text">{t('Project structure on this project')}</h3>
                            {projectStructures.length > 0 ? (
                                <div className="flex items-center gap-3">
                                    <LabelWrapper label={t('Choose the project structure for this project')}>
                                        <Dropdown
                                            options={projectStructures.map((ps) => ps.name)}
                                            selectedOption={selectedProjectStructure}
                                            setSelectedOption={(i) => {
                                                setSelectedProjectStructure(i);
                                            }}
                                            placeholder={t('Project structure')}
                                        />
                                    </LabelWrapper>
                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={
                                                selectedProjectStructure !== null
                                                    ? `rgb(${theme['STATE-COLORS']['ERROR'].color})`
                                                    : `rgb(${theme['STATE-COLORS']['DISABLED'].color})`
                                            }
                                            disabled={selectedProjectStructure !== null ? false : true}
                                            onClick={() => {
                                                setSelectedProjectStructure(null);
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                    <p className="mb-5">
                                        {t('There are no project structures yet, you have to add one first')}
                                    </p>
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                internalActivity.base +
                                                    internalActivity.projectStructure.base +
                                                    internalActivity.projectStructure.create,
                                            )
                                        }
                                    >
                                        {t('Add project structure')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Contracts */}
                    <div className="max-w-xl">
                        {selectedClient !== null &&
                            (loadingContracts ? (
                                <div className="flex justify-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="flex flex-col gap-2">
                                    <h3 className="text-dark-text">{t('Contract on this project')}</h3>
                                    {contracts.length > 0 ? (
                                        <div className="flex items-center gap-3">
                                            <LabelWrapper label={t('Choose the contract for this project')}>
                                                <Dropdown
                                                    options={contractsOptions}
                                                    selectedOption={selectedContract}
                                                    setSelectedOption={(i) => setSelectedContract(i)}
                                                    placeholder={t('Contract')}
                                                    disabled={selectedClient === null}
                                                />
                                            </LabelWrapper>
                                            <div className="mt-6">
                                                <ActionButton
                                                    icon={<DeleteIcon />}
                                                    color={
                                                        selectedContract !== null
                                                            ? `rgb(${theme['STATE-COLORS']['ERROR'].color})`
                                                            : `rgb(${theme['STATE-COLORS']['DISABLED'].color})`
                                                    }
                                                    disabled={selectedContract !== null ? false : true}
                                                    onClick={() => {
                                                        setSelectedContract(null);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-5 flex flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow">
                                            <p className="mb-5">
                                                {t('There are no contracts yet, you have to add one first')}
                                            </p>
                                            <Button
                                                startIcon={<AddIcon />}
                                                color="secondary"
                                                onClick={() =>
                                                    history.push(crm.base + crm.contracts.base + crm.contracts.create)
                                                }
                                            >
                                                {t('Add contract')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>

                    {/* Status */}
                    {projectInfo && (
                        <div className="flex max-w-xl flex-col gap-2">
                            <h3 className="text-dark-text">{t('Project status')}</h3>
                            <LabelWrapper label={t('Choose project status')}>
                                <Dropdown
                                    options={STATUSES.map((status) => {
                                        const str = status.split('_').join(' ');
                                        return t(str.slice(0, 1) + str.slice(1).toLowerCase());
                                    })}
                                    selectedOption={selectedStatus}
                                    setSelectedOption={(i) => setSelectedStatus(i)}
                                    placeholder={'Status'}
                                />
                            </LabelWrapper>
                        </div>
                    )}

                    {/* Teams */}
                    <h3 className="text-dark-text">{t('Teams on this projects')}</h3>

                    <div className="max-w-xl space-y-2">
                        {teams.map((team, index) => (
                            <div key={index} className="block">
                                <div className="flex items-center gap-2">
                                    <div className="text-lg text-main-text">{index + 1}.</div>
                                    <TextField
                                        name="variable"
                                        value={team.name}
                                        onChange={(e) => editTeam(index, 'name', e.target.value)}
                                    />
                                    <div className="flex items-center">
                                        <BasicTooltip tip={t('Delete team')}>
                                            <div
                                                className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-error transition-all  hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                                onClick={() => deleteTeam(index)}
                                            >
                                                <DeleteIcon className="text-main-text" style={{ fontSize: '1.1rem' }} />
                                            </div>
                                        </BasicTooltip>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Button
                            startIcon={<AddIcon />}
                            color="secondary"
                            onClick={() => {
                                setTeams([...teams, defaultTeam]);
                            }}
                        >
                            {t('Add team')}
                        </Button>
                    </div>

                    <div>
                        {projectInfo ? (
                            <Button
                                startIcon={<EditIcon />}
                                color="primary"
                                onClick={() => {
                                    editProject();
                                }}
                            >
                                {t('Edit project')}
                            </Button>
                        ) : (
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() => {
                                    createProject();
                                }}
                            >
                                {t('Create project')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

PMProjectForm.propTypes = {
    projectInfo: PropTypes.object,
    pmId: PropTypes.string,
};
PMProjectForm.defaultProps = {
    projectInfo: null,
    pmId: '',
};

export default PMProjectForm;

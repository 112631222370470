import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import Quote from 'components/crm/quotes/quote';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import API from 'utils/axios';

const BiddingHistory = () => {
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const [offers, setOffers] = useState([]);
    const { t } = useTranslation();

    const [statusOffer, setStatusOffer] = useState('');
    const [offerClients, setOfferClients] = useState('');

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '9',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '9',
            permissionType: 'VIEW',
        },
    ]);

    const getOfferHistory = async () => {
        setLoading(true);
        try {
            const response = await API.get('offers', {
                params: {
                    status: statusOffer && statusOffer !== 'ALL' ? statusOffer : undefined,
                    contactIds: offerClients && offerClients !== 'ALL' ? [offerClients] : undefined,
                },
            });

            setOffers(response.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (!canView) history.push('/');
        getOfferHistory();
    }, [canView, statusOffer, offerClients]);

    useEffect(() => {
        (async () => {
            let fetchedClients;
            try {
                const response = await API.get('/contacts', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: 'ALL',
                    },
                });
                fetchedClients = response.data.data.contacts;
                setClients(fetchedClients);

                setClientOptions(
                    fetchedClients.map((c) => {
                        const data = JSON.parse(c.data);
                        return 'standard' in data && 'name' in data['standard']
                            ? data['standard'].name
                            : 'Nume inexistent';
                    }),
                );
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t('Offer')} | {t('Offer history')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('Offer history')}
                action={
                    <>
                        {canAll && (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() => {
                                    history.push(quotes.base + quotes.newBidding.base);
                                }}
                            >
                                {t('New offer')}
                            </Button>
                        )}
                    </>
                }
                toolbar={
                    <div className="flex items-center gap-10 text-main-text">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={statusOffer}
                            onChange={(e) => setStatusOffer(e.target.value)}
                        >
                            <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                <div className="flex flex-wrap">
                                    <FormControlLabel key={'ALL'} value={''} control={<Radio />} label={t('All')} />

                                    <FormControlLabel
                                        key={'DRAFT'}
                                        value={'DRAFT'}
                                        control={<Radio />}
                                        label={t('Drafts')}
                                    />

                                    <FormControlLabel
                                        key={'CREATED'}
                                        value={'CREATED'}
                                        control={<Radio />}
                                        label={t('Created offers')}
                                    />

                                    <FormControlLabel
                                        key={'DECLINED'}
                                        value={'DECLINED'}
                                        control={<Radio />}
                                        label={t('Lost offers')}
                                    />

                                    <FormControlLabel
                                        key={'ACCEPTED'}
                                        value={'ACCEPTED'}
                                        control={<Radio />}
                                        label={t('Accepted offers')}
                                    />
                                </div>
                            </div>
                        </RadioGroup>

                        <div className="min-h-full w-1 self-stretch bg-layout-transparent" />

                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={offerClients}
                            onChange={(e) => setOfferClients(e.target.value)}
                        >
                            <div className="no-user-select-recursive flex flex-wrap items-center text-main-text">
                                <FormControlLabel key={'ALL'} value={''} control={<Radio />} label={t('All')} />
                                {clientOptions.map((c, index) => (
                                    <FormControlLabel
                                        key={c + index}
                                        value={clients[index].id}
                                        control={<Radio />}
                                        label={c}
                                    />
                                ))}
                            </div>
                        </RadioGroup>
                    </div>
                }
            />

            {loading ? (
                <div className="grid h-svh place-content-center">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {canView && (
                        <>
                            {offers.length ? (
                                <div className="page-container ml-32 sm:ml-0">
                                    <div className="mx-auto w-full max-w-5xl border-l border-layout-lighter pl-8 sm:pl-6">
                                        {offers.map((offer, index) => (
                                            <div
                                                key={offer.Offer.id}
                                                className="relative mb-10"
                                                style={{ zIndex: offers.length - index }}
                                            >
                                                <Quote offer={offer.Offer} fetchHistory={getOfferHistory} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <NoDataPlaceholder />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default BiddingHistory;

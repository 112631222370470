import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import GantContext from 'contexts/GantContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { TabbedDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import { cellHeight } from './config';
import Employee from './employee';
import ProjectModal from './project-modal';

const TeamInfo = ({ team, teamInfo }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        internalEmployees = [],
        externalEmployees = [],
        addEmployeeOnTeam,
        deleteEmployeeFromTeam,
        viewOnly = false,
        type,
    } = useContext(GantContext);

    const isModulePM =
        history.location.pathname.search('/project-management') > -1 ||
        history.location.pathname.search('/projects-in-management') > -1;
    const { permissionMap } = useContext(UserContext);
    const canAll = permissionMap[isModulePM ? 'Modul Project Manager' : 'Planning Overview'] > 1;

    const [modal, setModal] = useState(false);

    const activeInternalEmpoyees = useMemo(() => {
        return internalEmployees.filter((e) => !e.isDeleted);
    }, [internalEmployees]);

    const activeExternalEmpoyees = useMemo(() => {
        return externalEmployees.filter((e) => !e.isDeleted);
    }, [externalEmployees]);

    return (
        <div>
            <div
                className="relative flex cursor-pointer flex-col justify-center rounded-md bg-layout-transparent p-4 hover:bg-layout-transparent-dark"
                style={{
                    height: `${cellHeight * 3 - 2}px`,
                    marginBottom: 2,
                }}
                onClick={() => setModal(true)}
                id="modal-project"
            >
                <h3 className="text-xl font-bold">{team.name}</h3>
                <div className="flex">
                    {teamInfo.startDate && team.employees.length > 0 ? (
                        <>
                            <p className="text-dark-text">
                                {formatDate(teamInfo.startDate)} - {formatDate(teamInfo.endDate)}
                            </p>
                            <p className="ml-2 mr-2 text-sm text-main-text opacity-50">&#9679;</p>
                            <p className="text-dark-text">
                                {teamInfo.hours} {t('standard hours')}
                            </p>{' '}
                        </>
                    ) : (
                        <div className="text-dark-text">{t('There is no timeline activity!')}</div>
                    )}
                </div>
            </div>

            <ProjectModal modal={modal} setModal={setModal} teamInfo={teamInfo} />

            {/* Members */}
            {team.employees
                .filter((e) => !e.employee.isDeleted)
                .map((employee) => (
                    <Employee
                        key={`${employee.employee.id}${team.id}`}
                        employee={employee}
                        projectId={team.projectId}
                        teamId={team.id}
                    />
                ))}

            <div>
                {canAll && !viewOnly ? (
                    <TabbedDropdown
                        icon={<AddIcon className="text-buttons-text" />}
                        tabs={[
                            {
                                name: t('INTERNAL'),
                                options: activeInternalEmpoyees.map((e) => e.firstName),
                                selectedOptions: team.employees
                                    .filter((e) => e.employee.employeeType === 'INTERNAL' && !e.employee.isDeleted)
                                    .map((e) =>
                                        activeInternalEmpoyees.findIndex(
                                            (internalEmployee) => internalEmployee.id === e.employee.id,
                                        ),
                                    ),
                                setSelectedOptions: (i) => {
                                    /*
                                     * Search the employee position in the team
                                     */
                                    const empIndex = team.employees.findIndex(
                                        (e) => e.employee.id === activeInternalEmpoyees[i].id,
                                    );

                                    if (empIndex > -1) {
                                        deleteEmployeeFromTeam({
                                            id:
                                                type === 'internal'
                                                    ? team.employees[empIndex].id
                                                    : team.employees[empIndex].employee.employeeTeamId,
                                        });
                                    } else {
                                        addEmployeeOnTeam({
                                            teamId: team.id,
                                            employeeIds: [activeInternalEmpoyees[i].id],
                                        });
                                    }
                                },
                            },
                            {
                                name: t('EXTERNAL'),
                                options: activeExternalEmpoyees.map((e) => e.firstName),
                                selectedOptions: team.employees
                                    .filter((e) => e.employee.employeeType === 'EXTERNAL' && !e.employee.isDeleted)
                                    .map((e) =>
                                        activeExternalEmpoyees.findIndex(
                                            (externalEmployee) => externalEmployee.id === e.employee.id,
                                        ),
                                    ),
                                setSelectedOptions: (i) => {
                                    /*
                                     * Search the employee position in the team
                                     */
                                    const empIndex = team.employees.findIndex(
                                        (e) => e.employee.id === activeExternalEmpoyees[i].id,
                                    );

                                    if (empIndex >= 0) {
                                        deleteEmployeeFromTeam({
                                            id:
                                                type === 'internal'
                                                    ? team.employees[empIndex].id
                                                    : team.employees[empIndex].employee.employeeTeamId,
                                        });
                                    } else {
                                        addEmployeeOnTeam({
                                            teamId: team.id,
                                            employeeIds: [activeExternalEmpoyees[i].id],
                                        });
                                    }
                                },
                            },
                        ]}
                    />
                ) : (
                    <div style={{ height: 33 }} />
                )}
                <div style={{ height: cellHeight }} />
            </div>
        </div>
    );
};

TeamInfo.propTypes = {
    team: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        employees: PropTypes.array,
        projectId: PropTypes.string,
    }).isRequired,
    teamInfo: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        hours: PropTypes.number,
    }).isRequired,
};

export default TeamInfo;

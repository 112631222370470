import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PersonIcon from '@material-ui/icons/Person';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, TableSeparator, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { linkStyle } from 'utils';
import API from 'utils/axios';

import UserActivity from '../../components/crm/users/user-activity';
import UserDetails from '../../components/crm/users/user-details';

export const UsersRow = ({ userInfo }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { user } = useContext(UserContext);

    const id = userInfo.id;

    const getUserType = (user) => {
        if (user.isAdmin) return t('Admin');
        if (user.isAffiliate) return t('Affiliate');
        if (user.isInvestor) return t('Investor');
        if (user.isAdviser) return t('Sales agent');
        return t('Normal');
    };

    return (
        <ExpandableRow
            colSpan={6}
            padding={false}
            displayDataOnOpen={true}
            row={
                <>
                    <TableCell>
                        <span
                            className={`font-semibold ${user.isAdmin && linkStyle}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!user.isAdmin) return;

                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        userInfo.id +
                                        '?tab=1',
                                );
                            }}
                        >
                            <div className="flex items-center">
                                <div
                                    className="mr-4 h-12 w-12 flex-shrink-0 rounded-full bg-cover bg-center"
                                    style={{
                                        backgroundImage: `url(${userInfo?.profileImageUrl})`,
                                    }}
                                />
                                {userInfo.profile?.name}
                            </div>
                        </span>
                    </TableCell>
                    <TableCell>{userInfo.profile?.role}</TableCell>
                    <TableCell>{getUserType(userInfo)}</TableCell>
                    <TableCell>{userInfo.isActive ? t('active') : t('inactive')}</TableCell>
                    <TableCell>{userInfo.profile?.phoneNo}</TableCell>
                    <TableCell>{userInfo.email || ''}</TableCell>
                    <TableCell>{userInfo.observation}</TableCell>
                </>
            }
            collapse={<CollapseData user={user} userInfo={userInfo} id={id} />}
        />
    );
};

UsersRow.propTypes = {
    userInfo: PropTypes.object,
    deleteFromPM: PropTypes.func,
};

UsersRow.defaultProps = {
    userInfo: null,
    deleteFromPM: null,
};

const CollapseData = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const tabs = [t('User activity'), t('User details')];
    const [userActions, setUserActions] = useState([]);
    const { user, userInfo, id } = props;
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get(`/user_actions/${id}`);
                setUserActions(response.data.userActivity);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        <>
            <div className="p-5">
                <div className="flex items-center gap-8">
                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>
                {activeTab === 0 ? (
                    userActions.length !== 0 ? (
                        <UserActivity userActions={userActions} />
                    ) : (
                        <h3 className="ml-2 mt-8 text-sm text-main-text">{t('The user has no activity yet!')}</h3>
                    )
                ) : (
                    <UserDetails editableField={true} userInfo={userInfo} noBtn={true} />
                )}
            </div>
            {user.isAdmin && (
                <div className="p-5">
                    <Button
                        startIcon={<PersonIcon />}
                        color="primary"
                        onClick={() =>
                            history.push(
                                configurator.base +
                                    configurator.users.base +
                                    configurator.users.update +
                                    '/' +
                                    userInfo.id +
                                    '?tab=1',
                            )
                        }
                    >
                        {t('Update profile')}
                    </Button>
                </div>
            )}
        </>
    );
};

CollapseData.propTypes = {
    user: PropTypes.object,
    userInfo: PropTypes.object,
    id: PropTypes.string,
};

CollapseData.defaultProps = {
    user: null,
    userInfo: null,
    id: null,
};

const Users = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '11',
            permissionType: 'VIEW',
        },
    ]);

    const getUsers = async (currentPage, perPage) => {
        try {
            const response = await API.get('/tenants', {
                params: {
                    currentPage,
                    perPage,
                },
            });

            setUsers(response.data.users);

            return response.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('ERP Configurator')} | {t('Users')}
                </title>
            </Helmet>

            {canView && (
                <Pagination loading={loading} setLoading={setLoading} getFunction={getUsers}>
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Users')}</TableCell>
                                        <TableCell>{t('Role')}</TableCell>
                                        <TableCell>{t('Type')}</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>{t('Phone')}</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{t('Observations')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {users.map((user) => (
                                        <UsersRow key={user.name} userInfo={user} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Pagination>
            )}
        </>
    );
};

export default Users;

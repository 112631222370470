import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    ArrowUpRight,
    BadgeCheck,
    Clipboard,
    ClipboardCheck,
    ClipboardList,
    ClipboardPenLine,
    FileText,
    FolderPlus,
    KanbanSquare,
    Loader,
    Plus,
    Rows3,
    ScanSearch,
    SquareArrowOutUpRight,
    User,
} from 'lucide-react';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import { ReactComponent as ClientIcon } from 'assets/pipelines/svgs/client-icon.svg';
import { ReactComponent as HighestIcon } from 'assets/pipelines/svgs/highest-icon.svg';
import { ReactComponent as LowestIcon } from 'assets/pipelines/svgs/lowest-icon.svg';
import { ReactComponent as RecentIcon } from 'assets/pipelines/svgs/recent-icon.svg';
import { ReactComponent as SpecificIcon } from 'assets/pipelines/svgs/specific-icon.svg';

import { Button } from '@material-ui/core';

import Filter from 'components/shared/filter/filter';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PipelineCompactContent from 'components/shared/pipelines/pipeline-compact-content';
import PipelineContent from 'components/shared/pipelines/pipeline-content';
import Sort from 'components/shared/sort/sort';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectInManagement, projectManagement } from 'routes';
import { errorHandling, formatDate } from 'utils';
import API from 'utils/axios';

const DEFAULT_PIPELINE_STATE = {
    length: 0,
    content: {
        NEW: {
            length: 0,
            content: {
                REST: [],
            },
        },
        IN_PROGRESS: {
            length: 0,
            content: {
                REST: [],
            },
        },
        REVIEW: {
            length: 0,
            content: {
                REST: [],
            },
        },
        DONE: {
            length: 0,
            content: {
                REST: [],
            },
        },
    },
};

const DEFAULT_COMPACT_PIPELINE_STATE = {
    length: 0,
    content: {
        REST: {
            length: 0,
            content: [],
        },
    },
};

const VIEW_TYPES = ['CLASSIC', 'COMPACT'];

const COLUMNS_BREAKPOINTS = {
    5: '2xl',
    4: 'xl',
    3: 'lg',
    2: 'md',
    1: 'sm',
};

const ProjectsPipeline = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('CLASSIC');

    const [clients, setClients] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [projects, setProjects] = useState([]);
    const [formattedProjects, setFormattedProjects] = useState({
        classic: DEFAULT_PIPELINE_STATE,
        compact: DEFAULT_COMPACT_PIPELINE_STATE,
    });

    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '19',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const statuses = [
        { id: 'NEW', label: t('New') },
        { id: 'IN_PROGRESS', label: t('In progress') },
        { id: 'REVIEW', label: t('Review') },
        { id: 'DONE', label: t('Done') },
    ];

    const [filter, setFilter] = useState([]);
    const filterOptions = [
        {
            id: 'statuses',
            icon: <Rows3 />,
            label: t('Statuses'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Statuses'),
                    render: (value) => {
                        let statusesString = '';
                        value.forEach((v, index) => {
                            const status = statuses.find((t) => t.id === v).label;
                            statusesString += `${status}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return statusesString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: statuses,
                        label: t('Select statuses'),
                        render: (item) => item.label,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'client-group',
            icon: <ClientIcon />,
            label: 'Client',
            data: null,
            list: [
                {
                    id: 'contactId',
                    icon: <RecentIcon />,
                    label: 'Recent',
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => clients.find((client) => client.id === value).data.standard.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'list',
                                options: clients.slice(0, 4),
                                label: t('Select client'),
                                render: (item) => ({ icon: <ClientIcon />, label: item.data.standard.name }),
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'contactId',
                    icon: <SpecificIcon />,
                    label: t('Specific client'),
                    data: {
                        defaultValue: null,
                        filterData: {
                            label: 'Client',
                            render: (value) => clients.find((client) => client.id === value).data.standard.name,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['id'],
                                type: 'select',
                                options: clients,
                                label: t('Select client'),
                                render: (item) => item.data.standard.name,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'contractId',
            icon: <FileText />,
            label: t('Contract'),
            data: {
                defaultValue: null,
                filterData: {
                    label: t('Contract'),
                    render: (value) => contracts.find((contract) => contract.id === value).data.standard.number,
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'select',
                        options: contracts,
                        label: t('Select contract'),
                        render: (item) => item.data.standard.number,
                    },
                ],
            },
            list: null,
        },
    ];

    const columns = useMemo(() => {
        const defCols = [
            {
                title: t('New'),
                key: 'NEW',
                color: 'var(--main-text)',
                backgroundColor: '#FB924B',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('In progress'),
                key: 'IN_PROGRESS',
                color: 'var(--main-text)',
                backgroundColor: '#083469',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('Review'),
                key: 'REVIEW',
                color: 'var(--main-text)',
                backgroundColor: '#3587A4',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('Done'),
                key: 'DONE',
                color: 'var(--main-text)',
                backgroundColor: '#3EC356',
                borderColor: 'var(--layout-transparent)',
            },
        ];

        const statusesFilter = filter.find((f) => f.key === 'statuses');
        if (statusesFilter) return defCols.filter((c) => statusesFilter.value.includes(c.key));

        return defCols;
    }, [filter]);

    const sortKeys = [
        {
            key: 'REST',
            title: t('All projects'),
            tags: [],
            render: true,
        },
    ];

    const [sort, setSort] = useState([
        {
            key: 'createAt',
            label: t('Creation date'),
            value: 'desc',
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
        {
            key: 'updateAt',
            label: t('Last update'),
            value: null,
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
    ]);

    const mappedStatusData = {
        NEW: {
            label: t('New'),
            icon: <FolderPlus />,
        },
        IN_PROGRESS: {
            label: t('In progress'),
            icon: <Loader />,
        },
        REVIEW: {
            label: t('Review'),
            icon: <ScanSearch />,
        },
        DONE: {
            label: t('Done'),
            icon: <BadgeCheck />,
        },
    };

    const itemProps = {
        header: {
            status: (item) => mappedStatusData[item.metadata.pipelineStatus],
            tags: () => [],
        },
        title: {
            icon: <User />,
            render: (item) => item.contact.data.standard.name,
        },
        head: {
            render: (item) => item.name,
        },
        body: {
            renderVisibleRows: (item) => [
                {
                    label: t('Contract'),
                    content: item.Contract ? item.Contract.data.standard.number : '-',
                    canRender: Boolean(item.contractId),
                },
                {
                    label: t('Last update'),
                    content: formatDate(item.updateAt),
                    canRender: true,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    label: t('Creation date'),
                    content: formatDate(item.createAt),
                    canRender: true,
                },
                {
                    label: t('No. of tasks'),
                    content: item.Activity.filter((activity) => activity.actionType === 'TASK').length,
                    canRender: true,
                },
                {
                    label: t('No. of completed tasks'),
                    content: item.Activity.filter(
                        (activity) => activity.actionType === 'TASK' && activity.status === 'DONE',
                    ).length,
                    canRender: true,
                },
                {
                    label: t('No. of contracts'),
                    content: item.contractsPM.length,
                    canRender: true,
                },
                {
                    label: t('No. of invoices'),
                    content: item.Invoice.length,
                    canRender: true,
                },
            ],
        },
        button: {
            render: () => t('Open project'),
            icon: <ArrowUpRight />,
            disabled: false,
            action: (item) =>
                history.push(
                    projectInManagement.base + '/' + item.id + projectInManagement.planingOverviewOnProject.base,
                ),
            canRender: () => true,
        },
    };

    const getClients = async () => {
        try {
            const response = await API.get('/contacts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    type: 'ALL',
                },
            });
            const fetchedClients = response.data.data.contacts.map((client) => ({
                ...client,
                data: typeof client.data === 'string' ? JSON.parse(client.data) : client.data,
            }));

            setClients(fetchedClients);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getContracts = async () => {
        try {
            const response = await API.get('/contracts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });
            const fetchedContracts = response.data.contracts.map((contract) => ({
                ...contract,
                data: typeof contract.data === 'string' ? JSON.parse(contract.data) : contract.data,
            }));

            setContracts(fetchedContracts);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleFormatProjects = (projects) => {
        const mappedProjects = projects.map((project) => ({
            ...project,
            contact:
                typeof project.contact.data === 'string'
                    ? { ...project.contact, data: JSON.parse(project.contact.data) }
                    : project.contact,
            Contract:
                project.Contract && typeof project.Contract.data === 'string'
                    ? { ...project.Contract, data: JSON.parse(project.Contract.data) }
                    : project.Contract,
        }));

        const { classic, compact } = mappedProjects.reduce(
            (acc, curr) => ({
                classic: {
                    ...acc.classic,
                    length: projects.length,
                    content: {
                        ...acc.classic.content,
                        [curr.statusHistory.at(-1).status]: {
                            ...acc.classic.content[curr.statusHistory.at(-1).status],
                            length: acc.classic.content[curr.statusHistory.at(-1).status].length + 1,
                            content: {
                                ...acc.classic.content[curr.statusHistory.at(-1).status].content,
                                ['REST']: [
                                    ...acc.classic.content[curr.statusHistory.at(-1).status].content['REST'],
                                    {
                                        ...curr,
                                        metadata: {
                                            ...curr.metadata,
                                            pipelineStatus: curr.statusHistory.at(-1).status,
                                            pipelineKey: 'REST',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                compact: {
                    ...acc.compact,
                    length: projects.length,
                    content: {
                        ...acc.compact.content,
                        ['REST']: {
                            length: acc.compact.content['REST'].length + 1,
                            content: [
                                ...acc.compact.content['REST'].content,
                                {
                                    ...curr,
                                    metadata: {
                                        ...curr.metadata,
                                        pipelineStatus: curr.statusHistory.at(-1).status,
                                        pipelineKey: 'REST',
                                    },
                                },
                            ],
                        },
                    },
                },
            }),
            { classic: DEFAULT_PIPELINE_STATE, compact: DEFAULT_COMPACT_PIPELINE_STATE },
        );

        return { classic, compact };
    };

    const getProjects = async (sort, filter) => {
        try {
            const querySort = sort
                .filter((element) => element.value !== null)
                .reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

            const queryFilter = filter.reduce(
                (acc, curr) => ({ ...acc, [curr.metadata.backendKey ?? curr.key]: curr.value }),
                {},
            );

            const response = await API.get('pm_projects_pipeline', {
                params: { ...querySort, ...queryFilter },
            });
            const formattedProjects = handleFormatProjects(response.data.projects, sort);
            setFormattedProjects(formattedProjects);
            setProjects(response.data.projects);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    useEffect(() => {
        if (!canView) history.push('/');

        (async () => {
            try {
                await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t(errorHandling(error)), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [canView]);

    const handleSort = async (sort, lastAppliedSort) => {
        try {
            setLoading(true);
            await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = async (filter) => {
        try {
            setLoading(true);
            await Promise.all([getProjects(sort, filter), getClients(), getContracts()]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Projects pipeline')}</title>
            </Helmet>

            <Header
                pageTitle={t('Projects pipeline')}
                pageIcon={<KanbanSquare />}
                action={
                    <>
                        {canAll && (
                            <Button
                                color="secondary"
                                startIcon={<Plus />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement.projectEntity.base +
                                            projectManagement.projectEntity.create,
                                    )
                                }
                            >
                                {t('Add new project')}
                            </Button>
                        )}
                    </>
                }
                toolbar={
                    <div className="flex w-full items-center justify-between gap-3">
                        <Tabs
                            tabs={VIEW_TYPES.map((type) => t(type))}
                            activeTab={VIEW_TYPES.indexOf(view)}
                            setActiveTab={(i) => setView(VIEW_TYPES[i])}
                        />
                        <Sort
                            disabled={loading || formattedProjects.length === 0}
                            sort={sort}
                            setSort={setSort}
                            onSort={handleSort}
                        />
                    </div>
                }
                toolbarSecondary={
                    <Filter
                        disabled={loading}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                        mobileBP="md"
                    />
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '70vh' }} />
                ) : projects.length ? (
                    <>
                        {view === 'CLASSIC' && (
                            <PipelineContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedProjects.classic}
                                mobileBP={COLUMNS_BREAKPOINTS[columns.length]}
                            />
                        )}
                        {view === 'COMPACT' && (
                            <PipelineCompactContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedProjects.compact}
                            />
                        )}
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </>
    );
};

export default ProjectsPipeline;

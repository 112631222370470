import React from 'react';

import PropTypes from 'prop-types';

import PipelineTag from './item/pipeline-tag';

const PipelineSortLabel = ({ sortKey }) => {
    const { title, tags } = sortKey;

    return (
        <div className="flex items-center gap-8 px-6 py-4">
            <p className="text-2xl font-medium uppercase leading-6">{title}</p>
            <div className="flex items-center gap-3">
                {tags.map((tag, index) => (
                    <PipelineTag key={index} tag={tag} />
                ))}
            </div>
        </div>
    );
};

PipelineSortLabel.propTypes = {
    sortKey: PropTypes.object,
};

PipelineSortLabel.defaultProps = {
    sortKey: {},
};

export default PipelineSortLabel;

import { puppeteer } from 'routes';
import API from 'utils/axios';

const exportImages = async (
    query,
    lang,
    download = true,
    fileName = `${new Date().toISOString().slice(0, 10)}.png`,
) => {
    const response = await API.get('puppeteer', {
        params: {
            url: `${location.protocol}/${location.host}${puppeteer.base}?components=${JSON.stringify(query)}`,
            lang,
        },
    });

    const urls = response.data.filesUrls;

    // ? We download the image only if we specify that
    if (download) {
        urls.forEach((url) => {
            const { fileUrl } = url;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    }

    return urls;
};

export default exportImages;

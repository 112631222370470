import { useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

const useQuery = () => {
    const history = useHistory();
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const updateQuery = (key, value) => {
        query.set(key, value);
        history.replace({ pathname: location.pathname, search: query.toString() });
    };

    const deleteQueryKey = (key) => {
        query.delete(key);
        history.replace({ pathname: location.pathname, search: query.toString() });
    };

    return [query, updateQuery, deleteQueryKey];
};

export default useQuery;

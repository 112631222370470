import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import PhotoIcon from '@material-ui/icons/Photo';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FileUploadContainer from 'components/shared/file-upload-container';
import PermissionContext from 'contexts/PermissionContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, Permission } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator } from 'routes';
import { errorHandling, formatDate, PHONE_NO_REGEX, uploadSingleFile } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const INVESTOR_PERMISSIONS = [{ permissionId: '19', permissionType: 'VIEW' }];
const ADVISER_PERMISSION = [
    {
        permissionId: '1',
        permissionType: 'VIEW',
    },
    {
        permissionId: '6',
        permissionType: 'ALL',
    },
    {
        permissionId: '9',
        permissionType: 'ALL',
    },
];

const UserDetails = ({ editableField, noBtn, userInfo, noDate }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const permissions = useContext(PermissionContext);

    const { user } = useContext(UserContext);
    /**
     * [IMPORTANT]
     * this bool refers to the user currently being created / edited
     * to check if the currently logged user is admin we have to access user.isAdmin
     */
    const [userPosition, setUserPosition] = useState({ isAdmin: false, isInvestor: false, isAdviser: false });
    const investorPermissions = INVESTOR_PERMISSIONS;
    const adviserPermissions = ADVISER_PERMISSION;

    const [selectedPosition, setSelectedPosition] = useState(0);
    const [userPermissions, setUserPermissions] = useState(
        user
            ? user.permissions?.map((p) => {
                  return { permissionId: p.permissionId, permissionType: p.permissionType };
              })
            : [],
    );

    const id = userInfo?.id;

    const [role, setRole] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [observations, setObservations] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const [imageBlob, setImageBlob] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleImageUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(t(message), { variant: 'error' });
                    return;
                }
                setImageBlob(blob);
            },
            'image',
        );
    };

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [emailContent, setEmailContent] = useState(
        htmlToDraftBlocks(
            `
            <p style="color: rgb(0,0,0)">They will give you real-time access to a variety of important documents and information as follows:</p>
            <ul>
                <li style="color: rgb(0,0,0)">Wiki Project: Here you will find a complete history and all relevant documents for the project. Whenever you need a property deed, a planning certificate, an opinion or a decision, etc. you can locate and download them directly from this platform.</li>
                <li style="color: rgb(0,0,0)">RIEM Report: This report will contain all identified risks and measures taken to mitigate/eliminate them.</li>
                <li style="color: rgb(0,0,0)">Pipeline Activity: A dashboard showing all actions and their current status throughout the project.</li>
                <li style="color: rgb(0,0,0)">Project Gantt Chart: Will give you a detailed view of the project's schedule and progress.</li>
                <li style="color: rgb(0,0,0)">Financial Tools: Project P&L and CF: Detailed financial information on profit and loss and project cash flow.</li>
                <li style="color: rgb(0,0,0)">Other relevant information and documents that may be of interest to you.</li>
            </ul>
            `,
        ),
    );

    let schema = yup.object().shape({
        phone: yup
            .string()
            .trim()
            .required(t('Phone number field required!'))
            .matches(PHONE_NO_REGEX, t('Invalid phone number!'))
            .min(10, t('Phone number must be at least 10 digits')),
        email: yup.string().trim().required(t('Email field is required!')).email(t('Email is invalid!')),
        userName: yup
            .string()
            .trim()
            .typeError(t('Username field is required!'))
            .min(3, t('User name must be at least 3 characters long!'))
            .max(30, t('User name is to long!'))
            .required(t('Username field is required!')),
        role: yup
            .string()
            .trim()
            .typeError(t('User role name is required!'))
            .required(t('User role name is required!'))
            .min(2, t('Role name must be at least 2 characters long!')),
    });

    const createUser = useCallback(async () => {
        try {
            await schema.validate({ role, userName, email, phone });

            if (
                userPermissions.length <= 0 &&
                !userPosition.isAdmin &&
                !userPosition.isInvestor &&
                !userPosition.isAdviser
            ) {
                enqueueSnackbar(t('User must have at least one permission!'), {
                    variant: 'error',
                });
                return;
            }

            const permissionsToBeSent = userPosition.isAdmin
                ? []
                : userPosition.isInvestor
                  ? investorPermissions
                  : userPosition.isAdviser
                    ? adviserPermissions
                    : userPermissions;

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    email: email,
                    observation: observations,
                    profile: {
                        name: userName,
                        role: role,
                        phoneNo: phone,
                    },
                    isAdmin: userPosition.isAdmin,
                    isInvestor: userPosition.isInvestor,
                    isAdviser: userPosition.isAdviser,
                    permissions: permissionsToBeSent,
                    emailContent: DOMPurify.sanitize(
                        draftToHtml(convertToRaw(emailContent.getCurrentContent())).replaceAll(
                            'color: currentcolor;',
                            '',
                        ),
                    ),
                }),
            );

            reqBody.append('profileImage', imageBlob);

            try {
                await API.post('/user', reqBody, {
                    'Content-Type': 'multipart/form-data',
                });
                enqueueSnackbar(t('User was successfully created!'), {
                    variant: 'success',
                });

                history.push(configurator.base + '?tab=users');
            } catch (err) {
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [
        userName,
        role,
        email,
        phone,
        observations,
        imageBlob,
        userPermissions,
        userPosition.isAdmin,
        userPosition.isInvestor,
        userPosition.isAdviser,
    ]);

    const updateUser = async () => {
        try {
            await schema.validate({ role, userName, email, phone });

            if (
                userPermissions.length <= 0 &&
                !userPosition.isAdmin &&
                !userPosition.isInvestor &&
                !userPosition.isAdviser
            ) {
                enqueueSnackbar(t('User must have at least one permission!'), {
                    variant: 'error',
                });
                return;
            }

            const permissionsToBeSent = userPosition.isAdmin
                ? []
                : userPosition.isInvestor
                  ? investorPermissions
                  : userPosition.isAdviser
                    ? adviserPermissions
                    : userPermissions;

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    data: {
                        email: email,
                        observation: observations,
                        profile: {
                            name: userName,
                            role: role,
                            phoneNo: phone,
                        },
                        isAdmin: userPosition.isAdmin,
                        isInvestor: userPosition.isInvestor,
                        isAdviser: userPosition.isAdviser,
                        permissions: permissionsToBeSent,
                    },
                    id: id,
                }),
            );

            reqBody.append('profileImage', imageBlob);

            try {
                await API.put('/user', reqBody, {
                    'Content-Type': 'multipart/form-data',
                });

                enqueueSnackbar(t('User was successfully updated'), {
                    variant: 'success',
                });

                history.push(configurator.base + '?tab=users');
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                    variant: 'error',
                });
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    /**
     * Setting user info
     */
    useEffect(() => {
        if (userInfo) {
            setUserName(userInfo?.profile?.name);
            setEmail(userInfo?.email);
            setPhone(userInfo?.profile?.phoneNo);
            setRole(userInfo?.profile?.role);
            setCreatedAt(userInfo?.profile?.createAt);
            setObservations(userInfo?.observation);
            setUserPermissions(userInfo?.permissions);
            setUserPosition({
                isAdmin: userInfo?.isAdmin,
                isInvestor: userInfo?.isInvestor,
                isAdviser: userInfo?.isAdviser,
            });
            setSelectedPosition(userInfo?.isAdmin ? 3 : userInfo?.isInvestor ? 2 : userInfo?.isAdviser ? 1 : 0);
        }
    }, [userInfo]);

    useEffect(() => {
        switch (selectedPosition) {
            case 0:
                setUserPosition({ isAdmin: false, isInvestor: false, isAdviser: false });
                break;
            case 1:
                setUserPosition({ isAdmin: false, isInvestor: false, isAdviser: true });
                break;
            case 2:
                setUserPosition({ isAdmin: false, isInvestor: true, isAdviser: false });
                break;
            case 3:
                setUserPosition({ isAdmin: true, isInvestor: false, isAdviser: false });
                break;
        }
    }, [selectedPosition]);

    const canEditUserType = !editableField && user.isAdmin && user?.id !== userInfo?.id;

    return (
        <>
            {loading ? (
                <div className="flex h-svh w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <Fragment>
                    <div className="mt-10 flex flex-col justify-between gap-10">
                        <div className="flex gap-10 xl:flex-col xl:gap-5">
                            <div className="w-full max-w-md flex-shrink-0">
                                <h4 className="mb-10 text-2xl text-dark-text">{t('User activity')}</h4>
                                <div className="relative mb-5">
                                    <TextField
                                        name={t('role-name')}
                                        label={t('Role name')}
                                        placeholder="Project Manager"
                                        value={role}
                                        disabled={editableField}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                </div>
                                <div className="relative mb-5">
                                    <TextField
                                        name={t('user-name')}
                                        label={t('User name')}
                                        placeholder={t('User one name')}
                                        value={userName}
                                        disabled={editableField}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                                <div className={`relative mb-5 ${id ? 'cursor-not-allowed' : ''}`}>
                                    <TextField
                                        className={`${id ? 'pointer-events-none' : ''}`}
                                        name={t('email')}
                                        type="email"
                                        label="Email"
                                        placeholder={t('name@email.com')}
                                        value={email}
                                        disabled={editableField}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="relative mb-5">
                                    <TextField
                                        name={t('Phone')}
                                        label={t('Phone')}
                                        placeholder="0752681439"
                                        value={phone}
                                        disabled={editableField}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>

                                <div className="relative mb-5">
                                    <TextField
                                        name={'observations'}
                                        label={t('Observations')}
                                        placeholder={t('Observations...')}
                                        value={observations}
                                        disabled={editableField}
                                        multiline
                                        rows={3}
                                        onChange={(e) => setObservations(e.target.value)}
                                    />
                                </div>

                                {!editableField && (
                                    <div className={`mb-5 ${noDate ? 'xl:mb-0' : ''}`}>
                                        <LabelWrapper label={t('Profile image')}>
                                            <FileUploadContainer onUpload={handleImageUpload}>
                                                <Button fullWidth startIcon={<PhotoIcon />}>
                                                    {(() => {
                                                        if (userInfo == undefined && imageBlob === null) {
                                                            return t('Add profile image');
                                                        } else if (userInfo == undefined && imageBlob) {
                                                            return imageBlob.name.substr(0, 10) + '...';
                                                        } else if (userInfo && imageBlob === null) {
                                                            return t('Change profile image');
                                                        } else {
                                                            return imageBlob.name.substr(0, 10) + '...';
                                                        }
                                                    })()}
                                                    {/* {userInfo == undefined ? t('Add profile image') : t('Change profile image')} */}
                                                </Button>
                                            </FileUploadContainer>
                                        </LabelWrapper>
                                    </div>
                                )}

                                {!noDate ? (
                                    <div className="relative mb-5 xl:mb-0">
                                        <TextField
                                            name={'registration date'}
                                            disabled="true"
                                            label={t('Registration date')}
                                            value={formatDate(createdAt)}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            {!id && (
                                <div className="relative mb-5 h-full max-w-7xl bg-layout-light">
                                    <LabelWrapper label={t('Additional email content')}>
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            editorState={emailContent}
                                            onEditorStateChange={setEmailContent}
                                            toolbarStyle={{
                                                margin: 2,
                                                padding: 2,
                                                borderRadius: 0,
                                                color: 'black',
                                                border: 'none',
                                                backgroundColor: 'inherit',
                                            }}
                                            wrapperStyle={{
                                                width: '100%',
                                            }}
                                            editorStyle={{
                                                borderRadius: 0,
                                                backgroundColor: 'white',
                                                padding: '0px 8px',
                                                color: 'black',
                                                minHeight: '20rem',
                                                lineHeight: 1.2,
                                            }}
                                            toolbar={{
                                                options: [
                                                    'inline',
                                                    'blockType',
                                                    'fontSize',
                                                    'list',
                                                    'textAlign',
                                                    'colorPicker',
                                                    'link',
                                                    'remove',
                                                    'history',
                                                ],
                                            }}
                                        />
                                    </LabelWrapper>
                                </div>
                            )}
                        </div>

                        <div className="w-full">
                            {/* 
                            Is admin toggle 
                            Is affiliate toggle

                            1.
                            If is affiliate -> can't be admin
                            If is affiliate -> can't choose permissions

                            2.
                            If is admin -> can't be affiliate
                            If is admin -> can't choose permissions

                            Only show this if the current logged in user is admin
                        */}
                            {/* {user.isAdmin && (
                            <React.Fragment>
                                <div className="inline-flex">
                                    <LabelWrapper label={'Admin'}>
                                        <div className={`${canEditToggles ? '' : 'cursor-not-allowed'}`}>
                                            <div className={canEditToggles ? '' : 'pointer-events-none'}>
                                                <CheckItem
                                                    medium
                                                    name={isUserAdmin ? t('Yes') : t('No')}
                                                    checked={isUserAdmin}
                                                    setChecked={
                                                        canEditToggles
                                                            ? (val) => {
                                                                  setIsUserAdmin(val);
                                                              }
                                                            : () => null
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </LabelWrapper>
                                </div>

                                <div className="h-8" />
                            </React.Fragment>
                        )} */}

                            {user.isAdmin && (
                                <div className="mb-10 inline-block">
                                    <LabelWrapper label={t('Choose user type')}>
                                        <Dropdown
                                            options={['Normal', t('Sales agent'), t('Investor'), t('Admin')]}
                                            selectedOption={selectedPosition}
                                            setSelectedOption={setSelectedPosition}
                                            disabled={!canEditUserType}
                                        />
                                    </LabelWrapper>
                                </div>
                            )}

                            {!userPosition.isAdmin && !userPosition.isInvestor && !userPosition.isAdviser && (
                                <React.Fragment>
                                    {user.isAdmin && user?.id !== userInfo?.id && (
                                        <React.Fragment>
                                            <h4 className="mb-10 text-2xl text-dark-text">{t('Access areas')}</h4>

                                            <div className="grid w-full grid-cols-3 gap-6 xl:grid-cols-2 sm:grid-cols-1">
                                                {permissions
                                                    ?.filter((pg) => pg.Permissions.length > 0)
                                                    .map((pg) => (
                                                        <div key={pg.id}>
                                                            <Permission
                                                                enabled={!editableField}
                                                                permissionGroup={pg}
                                                                userPermissions={userPermissions}
                                                                setUserPermissions={setUserPermissions}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>

                                            {!editableField && (
                                                <div className="p-6">
                                                    <p className="mb-2 text-sm text-dark-text">
                                                        {t('* Only the ADMIN user can create and edit other users!')}
                                                    </p>
                                                    <p className="mb-2 text-sm text-dark-text">
                                                        {t(
                                                            '* If you give the user EDIT or VIEW permission on the Zones, you must also give EDIT or VIEW permission on the Projects',
                                                        )}
                                                    </p>
                                                    <p className="text-sm text-dark-text">
                                                        {t(
                                                            '* If you give the user EDIT permission on the Offers, you must also give EDIT permission for Contracts',
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>

                    <div className="mt-10">
                        {!noBtn && id ? (
                            <Button
                                type="submit"
                                startIcon={<CheckIcon />}
                                color="primary"
                                onClick={() => {
                                    updateUser();
                                    setLoading(true);
                                }}
                            >
                                {t('Update profile info')}
                            </Button>
                        ) : (
                            <Button
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() => {
                                    createUser();
                                    setLoading(true);
                                }}
                            >
                                {t('Add user')}
                            </Button>
                        )}
                    </div>
                </Fragment>
            )}
        </>
    );
};

UserDetails.propTypes = {
    editableField: PropTypes.bool,
    submit: PropTypes.element,
    id: PropTypes.object,
    noBtn: PropTypes.bool,
    userInfo: PropTypes.object,
    noDate: PropTypes.bool,
    /**
     * Trebuie sa specificam tipul userului
     * De exemplu in cazul in care cream un user afiliat din modulul PM, sau PerProject, unde stim sigur ca e afiliat
     */
};

UserDetails.defaultProps = {
    editableField: true,
    submit: null,
    id: null,
    noBtn: null,
    userInfo: null,
    noDate: null,
};

export default UserDetails;

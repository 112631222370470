import React, { cloneElement } from 'react';

import { Box, LayoutDashboard } from 'lucide-react';

import useNavProps from 'hooks/useNavProps';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const handleLargeElement = (items, divider) => {
    if (!items.length > 1) return null;

    const rows = Math.ceil(items.length / divider);
    let offset = rows * divider - items.length;

    if (offset > items.length) return null;

    const sample = [];
    const arr = Array.from({ length: items.length }, (_, i) => i);

    while (offset) {
        const candidate = arr[Math.floor(Math.random() * arr.length)];

        if (sample.every((item) => Math.abs(item - candidate) > 1)) {
            sample.push(candidate);
            --offset;
        }

        arr.splice(arr.indexOf(candidate), 1);
    }

    return sample;
};

const Dashboard = () => {
    const { t } = useTranslation();

    const { dashboardProps } = useNavProps();

    const vsmModulesLargeElement = dashboardProps.modules.map((item) => handleLargeElement(item.subItems, 2));
    const smModuleLargeElements = dashboardProps.modules.map((item) => handleLargeElement(item.subItems, 3));
    const vsmOtherLargeElement = handleLargeElement(dashboardProps.others, 2);

    return (
        <>
            <Helmet>
                <title>Dashboard ERP</title>
            </Helmet>
            <Header pageTitle="Dashboard ERP" pageIcon={<LayoutDashboard />} />

            <div className="page-container">
                <div className="flex flex-col gap-16">
                    {dashboardProps.modules.map((item, idx) => (
                        <section
                            className="grid grid-cols-dashboard-desktop gap-5 sm:grid-cols-dashboard-mobile-modules-sm sm:place-content-center vsm:grid-cols-dashboard-mobile-modules-vsm"
                            key={item.name}
                        >
                            <div className="col-span-full flex items-center gap-1.5">
                                {cloneElement(item.icon, {
                                    className: 'h-9 w-9 flex-shrink-0',
                                })}
                                <h2 className="text-2xl font-medium leading-6">{item.name}</h2>
                            </div>

                            <div
                                className="col-span-full grid gap-5 rounded-md"
                                style={{
                                    gridTemplateColumns: 'subgrid',
                                    gridAutoFlow: 'dense',
                                }}
                            >
                                {item.subItems.map((subItem, index) => {
                                    return (
                                        <div
                                            key={subItem.name}
                                            onClick={() => subItem.onRoute()}
                                            className={`group relative flex h-36 w-full cursor-pointer flex-col justify-between overflow-hidden rounded-3xl border border-layout-transparent bg-gradient-to-tr from-gradient-primary-dark to-gradient-primary-light p-3 shadow ${vsmModulesLargeElement[idx] === null ? 'vsm:col-span-3' : vsmModulesLargeElement[idx].includes(index) ? 'vsm:col-span-2' : 'vsm:col-span-1'} ${smModuleLargeElements[idx] === null ? 'sm:col-span-3' : smModuleLargeElements[idx].includes(index) ? 'sm:col-span-2' : 'sm:col-span-1'}`}
                                        >
                                            {cloneElement(subItem.icon, {
                                                className:
                                                    'z-10 h-10 w-10 flex-shrink-0 text-gradient-text group-hover:text-gradient-primary-light transition-colors duration-300',
                                            })}
                                            <h3 className="z-10 break-words text-xl font-medium leading-6 text-gradient-text transition-colors duration-300 group-hover:text-gradient-primary-light">
                                                {subItem.name}
                                            </h3>
                                            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-tr from-gradient-text via-gradient-text to-gradient-primary-light opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                                        </div>
                                    );
                                })}
                            </div>
                        </section>
                    ))}
                    {dashboardProps.others.length > 0 && (
                        <section className="grid grid-cols-dashboard-desktop gap-5 sm:grid-cols-dashboard-mobile-others sm:place-content-center vsm:grid-cols-dashboard-mobile-modules-vsm">
                            <div className="col-span-full flex items-center gap-1.5">
                                <Box className="h-10 w-10 flex-shrink-0" />
                                <h2 className="text-2xl font-medium leading-6">{t('Other modules')}</h2>
                            </div>

                            <div
                                className="col-span-full grid gap-5 rounded-md"
                                style={{
                                    gridTemplateColumns: 'subgrid',
                                    gridAutoFlow: 'dense',
                                }}
                            >
                                {dashboardProps.others.map((item, index) => {
                                    return (
                                        <div
                                            key={item.name}
                                            onClick={() => item.onRoute()}
                                            className={`group relative flex h-36 w-full cursor-pointer flex-col justify-between overflow-hidden rounded-3xl border border-layout-transparent bg-gradient-to-tr from-gradient-primary-dark to-gradient-primary-light p-3 shadow ${vsmOtherLargeElement === null ? 'sm:col-span-2 vsm:col-span-2' : vsmOtherLargeElement.includes(index) ? 'sm:col-span-2 vsm:col-span-2' : ''}`}
                                        >
                                            {cloneElement(item.icon, {
                                                className:
                                                    'z-10 h-10 w-10 flex-shrink-0 text-gradient-text group-hover:text-gradient-primary-light transition-colors duration-300',
                                            })}
                                            <h3 className="z-10 break-words text-xl font-medium leading-6 text-gradient-text transition-colors duration-300 group-hover:text-gradient-primary-light">
                                                {item.name}
                                            </h3>
                                            <div className="absolute left-0 top-0 z-0 h-full w-full bg-gradient-to-tr from-gradient-text via-gradient-text to-gradient-primary-light opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                                        </div>
                                    );
                                })}
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
